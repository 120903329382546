import create from 'zustand';
import { API, graphqlOperation } from 'aws-amplify';
import { createList } from '../custom-graphql/customMutations';
import { createWordList } from '../custom-graphql/customMutations';
import { Auth } from 'aws-amplify';

// Define useStudentStore first so we can reference it in useConceptStore
export const useStudentStore = create((set) => ({
  students: [],
  setStudents: (list) => set({ students: list }),
  selectedStudent: null,
  setSelectedStudent: (student) => set({ selectedStudent: student }),
}));

export const useListStore = create((set, get) => ({
  lists: [],
  selectedLists: [],
  nextToken: null,
  setLists: (lists) => set({ lists }),
  downloadManyUrl: '',
  knownWords: [],
  knownConcepts: [],
  setDownloadManyUrl: (url) => set({ downloadManyUrl: url }),
  setSelectedLists: (selectedLists) => set({ selectedLists }),
  setNextToken: (nextToken) => set({ nextToken }),
  addList: (newList) => {
    console.log('Adding new list:', newList); // Debugging line
    set((state) => ({ lists: [...state.lists, newList] }));
  },
  updateList: (updatedList) => {
    if (!updatedList) {
      console.error('updatedList is undefined');
      return;
    }
    set((state) => ({
      lists: state.lists.map((list) =>
        list.id === updatedList.id ? updatedList : list
      ),
    }));
  },

  deleteList: (deletedListId) =>
    set((state) => ({
      lists: state.lists.filter((list) => list.id !== deletedListId),
    })),
  resetState: () =>
    set({
      lists: [],
      selectedLists: [],
      nextToken: null,
      downloadManyUrl: '',
    }),
  setKnownWords: () => {
    const { lists } = get();

    const allWordsFromLists = lists.flatMap((list) =>
      list.words.items.map((item) => ({
        id: item.word.id,
        word: item.word.word,
      }))
    );

    const wordCount = allWordsFromLists.reduce((acc, cur) => {
      acc[cur.word] = (acc[cur.word] || 0) + 1;
      return acc;
    }, {});

    const uniqueWordsWithCount = Object.keys(wordCount).map((word) => {
      const wordItem = allWordsFromLists.find((w) => w.word === word);

      if (wordItem) {
        return {
          id: wordItem.id,
          word: word,
          wordCount: wordCount[word],
        };
      } else {
        return {
          id: 'Unknown',
          word: word,
          wordCount: wordCount[word],
        };
      }
    });

    set({ knownWords: uniqueWordsWithCount });
  },

  setKnownConcepts: () => {
    const { lists } = get();

    const allConceptsFromLists = lists.map((list) => ({
      concept: list.concept,
      conceptID: list.conceptID,
    }));

    const conceptCount = allConceptsFromLists.reduce((acc, cur) => {
      acc[cur.conceptID] = (acc[cur.conceptID] || 0) + 1;
      return acc;
    }, {});

    // This part is where the changes are
    const uniqueConceptsWithCount = Object.keys(conceptCount).map(
      (conceptID) => {
        const conceptItem = allConceptsFromLists.find(
          (c) => c.conceptID === conceptID
        );

        if (conceptItem) {
          return {
            concept: conceptItem.concept, // add the human-readable "concept" field here
            conceptID: conceptID,
            count: conceptCount[conceptID],
          };
        } else {
          return {
            concept: 'Unknown',
            conceptID: conceptID,
            count: conceptCount[conceptID],
          };
        }
      }
    );

    set({ knownConcepts: uniqueConceptsWithCount });
  },
}));

// Debugging component
export const DebugStateTable = () => {
  const studentState = useStudentStore.getState();
  const listState = useListStore.getState();
  const conceptState = useConceptStore.getState();

  return (
    <div>
      <h2>Debug State Table</h2>
      <table border='1'>
        <thead>
          <tr>
            <th>Store</th>
            <th>Key</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries({ studentState, listState, conceptState }).map(
            ([storeName, state]) => {
              return Object.keys(state)
                .filter((key) => key !== 'concepts') // Exclude "concepts" key
                .map((key, index) => (
                  <tr key={`${storeName}-${key}`}>
                    {index === 0 && (
                      <td
                        rowSpan={
                          Object.keys(state).filter((k) => k !== 'concepts')
                            .length
                        }
                      >
                        {storeName}
                      </td>
                    )}
                    <td>{key}</td>
                    <td>{JSON.stringify(state[key])}</td>
                  </tr>
                ));
            }
          )}
        </tbody>
      </table>
    </div>
  );
};

export const useConceptStore = create((set, get) => ({
  concepts: [],
  wordsFromConcept: [],
  listName: '',
  selectedConcept: [],
  selectedWords: [],
  showCreateAnother: false,
  setShowCreateAnother: (flag) => set({ showCreateAnother: flag }),
  setSelectedWords: (words) => set((state) => ({ selectedWords: words })),
  setConcepts: (list) => set((state) => ({ concepts: list })),
  setWordsFromConcept: (words) => set((state) => ({ wordsFromConcept: words })),
  setSelectedConcept: (concept) =>
    set((state) => ({ selectedConcept: concept })),
  setListName: (name) => set({ listName: name }),
  resetState: () =>
    set({
      // concepts: [],
      wordsFromConcept: [],
      listName: '',
      selectedConcept: '',
      selectedWords: [],
    }),
  percentageInScopeCriteria: 0, // Initialize the percentageInScopeCriteria
  filteredWordsLength: 0, // Initialize the filteredWordsLength
  wordsListLength: 0, // Initialize the wordsListLength
  setMetrics: (percentage, filteredLength, wordsLength) =>
    set({
      percentageInScopeCriteria: percentage,
      filteredWordsLength: filteredLength,
      wordsListLength: wordsLength,
    }),
  // Function to retrieve concept by ID
  getConceptById: (conceptId) => {
    const { concepts } = get();
    // console.log('Concepts:', concepts);

    const concept = concepts.find((c) => c.id === conceptId);
    // console.log('Found Concept:', concept);

    return concept ? concept.concept : 'Unknown';
  },

  createList: async () => {
    const { listName, selectedConcept, selectedWords } = get();
    const { selectedStudent } = useStudentStore.getState(); // Get selectedStudent from useStudentStore

    if (
      listName.trim().length === 0 ||
      !selectedStudent ||
      !selectedStudent.id
    ) {
      alert('List name and student ID cannot be empty');
      return;
    }
    // Get the current user
    const user = await Auth.currentAuthenticatedUser();
    const username = user.username;

    const inputForNewList = {
      name: listName,
      conceptID: selectedConcept.id,
      studentID: selectedStudent.id, // Include studentID here
      owner: username, // Include the owner username
      // listData: {}, // Initialize with an empty object or other default value
    };

    try {
      const newList = await API.graphql(
        graphqlOperation(createList, { input: inputForNewList })
      );
      
      
 
      
      const newListID = newList.data.createList.id;

      const wordInputs = selectedWords.map((word) => ({
        listId: newListID,
        wordId: word.id,
      }));

      var wordData ={
        items: []
      }

      const wordPromises = wordInputs.map((wordInput) => {
        const listWord =  API.graphql(
          graphqlOperation(createWordList, { input: wordInput })
        );
        return listWord
      });


      await Promise.all(wordPromises)
        .then((results) => {
          for(var word in results){
            const result = results[word].data.createWordList
            const wordDatum = {word: {
              word: result.word.word,
              id: result.wordId,
              wordData: result.word.wordData
            }}

            wordData.items.push(wordDatum)
          }
        })
        .catch((e) => {
          console.log('Error in one of the word association promises:', e);
          if (e.errors && Array.isArray(e.errors)) {
            e.errors.forEach((error) => {
              console.log('Individual error:', error);
            });
          }
        });

  
      newList.data.createList.words = wordData
  
      return newList.data.createList
    } catch (error) {
      console.log('Error creating list or associating words:', error);
    }
  },
}));
