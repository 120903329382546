import React, { useState, useEffect } from 'react';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';

export default function PassagesModal(props) {
  const [isPassagesModalOpen, setIsPassagesModalOpen] = useState(
    props.isPassagesModalOpen
  );

  useEffect(() => {
    // Update the local state when the props.isPassagesModalOpen changes
    setIsPassagesModalOpen(props.isPassagesModalOpen);
  }, [props.isPassagesModalOpen]);

  const handleModalClose = () => {
    // Inform the parent component about the modal closure
    props.onClose();
  };

  return (
    <Modal
      aria-labelledby='modal-title'
      aria-describedby='modal-desc'
      open={isPassagesModalOpen}
      onClose={handleModalClose}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Sheet
        variant='outlined'
        sx={{
          maxWidth: 1000,
          borderRadius: 'md',
          p: 3,
          boxShadow: 'lg',
        }}
      >
        {/* ModalClose button */}
        <ModalClose
          variant='outlined'
          sx={{
            top: 'calc(-1/4 * var(--IconButton-size))',
            right: 'calc(-1/4 * var(--IconButton-size))',
            boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
            borderRadius: '50%',
            bgcolor: 'background.surface',
          }}
          onClick={handleModalClose}
        >
          Close
        </ModalClose>

        <Typography
          component='h2'
          id='modal-title'
          level='h4'
          textColor='inherit'
          fontWeight='lg'
          mb={1}
        >
          Select Passages for Lesson
        </Typography>
        <p>TABLE HERE</p>
      </Sheet>
    </Modal>
  );
}
