import * as React from 'react';
import Box from '@mui/joy/Box';
import IconButton from '@mui/joy/IconButton';
import List from '@mui/joy/List';
import ListSubheader from '@mui/joy/ListSubheader';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListItemContent from '@mui/joy/ListItemContent';
// import ManageStudentsModal from './modals/ManageStudentsModal';
import ManageAccountModal from './modals/ManageAccountModal';
import LessonTemplatesModal from './modals/LessonTemplatesModal';
// Icons import
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import { useState, useEffect } from 'react';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import AssignmentIndRoundedIcon from '@mui/icons-material/AssignmentIndRounded';
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import FormatAlignJustifyRoundedIcon from '@mui/icons-material/FormatAlignJustifyRounded';
import HorizontalRuleRoundedIcon from '@mui/icons-material/HorizontalRuleRounded';
import AutoStoriesRoundedIcon from '@mui/icons-material/AutoStoriesRounded';
import SpaceDashboardRoundedIcon from '@mui/icons-material/SpaceDashboardRounded';
import AccountTreeRoundedIcon from '@mui/icons-material/AccountTreeRounded';
import AssessmentRoundedIcon from '@mui/icons-material/AssessmentRounded';
import usePullStudents from '../hooks/StudentsHook';
import { useStudentStore } from '../zustand/store';
import { Chip, Tooltip, Typography } from '@mui/joy';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import AddStudentModal from './modals/AddStudentModal';
import CreateStudentForm from './forms/CreateStudent';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditStudentModal from './modals/EditStudentModal';

export default function EmailNav({ selectedItem, onSelectItem }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [expandedIndices, setExpandedIndices] = useState([]);
  const [isStudentGroupsExpanded, setIsStudentGroupsExpanded] = useState(false);
  const studentsFromStore = useStudentStore((state) => state.students);
  const [isMyStudentsExpanded, setIsMyStudentsExpanded] = useState(true);
  const [activeItem, setActiveItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const setZustandSelectedStudent = useStudentStore(
    (state) => state.setSelectedStudent
  );

  const [isAdministrationExpanded, setIsAdministrationExpanded] =
    useState(true);

  const handleSelectItem = (item, student) => {
    const newItem = `${item}-${student.id}`;
    if (newItem !== activeItem) {
      setActiveItem(newItem);
      onSelectItem(item); // Update the selected item in the parent component
      setZustandSelectedStudent(student);
    }
  };

  const handleOpenEditModal = (e, student) => {
    e.stopPropagation();
    setSelectedStudent(student);
    setIsModalOpen(true);
  };

  const pullStudents = usePullStudents();

  const [openModal, setOpenModal] = useState(null);

  const handleOpenModal = (modalType) => {
    setOpenModal(modalType);
  };

  const handleCloseModal = () => {
    setOpenModal(null);
  };
  useEffect(() => {
    pullStudents(); // This will fetch student data when the component mounts.
  }, []); // The empty dependency array ensures it only runs once, similar to componentDidMount.

  const handleExpandClick = (index) => {
    setExpandedIndices((prevIndices) => {
      if (prevIndices.includes(index)) {
        // Remove the index if it's already included
        return prevIndices.filter((i) => i !== index);
      } else {
        // Add the index if it's not already included
        return [...prevIndices, index];
      }
    });
  };

  const handleMyStudentsExpandClick = () => {
    setIsMyStudentsExpanded((prevIsExpanded) => !prevIsExpanded);
  };

  const handleStudentGroupsExpandClick = () => {
    setIsStudentGroupsExpanded((prevIsExpanded) => !prevIsExpanded);
  };

  const handleAdministrationExpandClick = () => {
    setIsAdministrationExpanded((prevIsExpanded) => !prevIsExpanded);
  };

  const sortedStudents = (
    Array.isArray(studentsFromStore) ? studentsFromStore : []
  )
    .filter((student) => !student._deleted)
    .sort((a, b) => a.lastName.localeCompare(b.lastName));

  return (
    <>
      <List size='sm'>
        <ListItem nested sx={{ mt: 2 }}>
          <ListSubheader
            component='div'
            // onClick={handleMyStudentsExpandClick}
            sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          >
            My Students
            {/* <IconButton
              size='sm'
              variant='plain'
              color='primary'
              sx={{ '--IconButton-size': '24px', ml: 'auto', p: 0 }}
              onClick={(e) => {
                e.stopPropagation();
                handleMyStudentsExpandClick();
              }}
            >
              <KeyboardArrowDownRoundedIcon fontSize='small' color='primary' />
            </IconButton> */}
          </ListSubheader>
          <EditStudentModal
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            student={selectedStudent}
          />

          {isMyStudentsExpanded &&
            sortedStudents.map((student, index) => (
              <ListItem nested key={student.id}>
                <ListItemButton
                  onClick={() => handleExpandClick(index)}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <ListItemDecorator sx={{ color: 'neutral.500' }}>
                    <PersonRoundedIcon
                      size='sm'
                      sx={{ marginRight: '12px' }}
                      onClick={(e) => handleOpenEditModal(e, student)}
                    />
                  </ListItemDecorator>

                  {`${student.lastName}, ${student.firstName}`}

                  <IconButton
                    size='sm'
                    variant='plain'
                    color='primary'
                    sx={{
                      '--IconButton-size': '24px',
                      ml: 'auto',
                      p: 0,
                      marginRight: '20px',
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleExpandClick(index); // pass the index to the handler
                    }}
                  >
                    <KeyboardArrowDownRoundedIcon
                      fontSize='small'
                      color='primary'
                    />
                  </IconButton>
                  {/* <IconButton size='sm' variant='outlined'>
                    <MoreHorizIcon size='sm' />
                  </IconButton> */}
                </ListItemButton>
                {expandedIndices.includes(index) && (
                  <List
                    aria-labelledby='nav-list-tags'
                    size='sm'
                    sx={{
                      '--ListItemDecorator-size': '32px',
                      '& .JoyListItemButton-root': { p: '8px' },
                    }}
                  >
                    <ListItem>
                      <ListItemButton
                        onClick={() => {
                          setActiveItem(`Word Lists-${student.id}`);
                          handleSelectItem('Word Lists', student);
                        }}
                        variant={
                          activeItem === `Word Lists-${student.id}`
                            ? 'soft'
                            : 'default'
                        }
                      >
                        <ListItemDecorator sx={{ color: 'inherit' }}>
                          <HorizontalRuleRoundedIcon
                            fontSize='small'
                            sx={{
                              marginLeft: '22px',
                              marginRight: '12px',
                              color: 'neutral.500',
                            }}
                          />
                        </ListItemDecorator>
                        <ListItemContent>Word Lists</ListItemContent>
                      </ListItemButton>
                    </ListItem>

                    <ListItem>
                      <ListItemButton
                        variant={
                          activeItem === `Sentences-${student.id}`
                            ? 'soft'
                            : 'default'
                        }
                        disabled
                        onClick={() => {
                          setActiveItem(`Sentences-${student.id}`);
                          handleSelectItem('Sentences', student);
                        }}
                      >
                        <ListItemDecorator sx={{ color: 'inherit' }}>
                          <FormatAlignJustifyRoundedIcon
                            fontSize='small'
                            sx={{
                              marginLeft: '22px',
                              marginRight: '12px',
                              color: 'neutral.500',
                            }}
                          />
                        </ListItemDecorator>
                        <ListItemContent>Sentences</ListItemContent>
                        <Chip
                          variant='soft'
                          color='neutral'
                          size='sm'
                          sx={{ borderRadius: 'sm' }}
                        >
                          Coming Soon
                        </Chip>
                      </ListItemButton>
                    </ListItem>

                    <ListItem>
                      <ListItemButton
                        variant={
                          activeItem === `Passages-${student.id}`
                            ? 'soft'
                            : 'default'
                        }
                        disabled
                        onClick={() => {
                          setActiveItem(`Passages-${student.id}`);
                          handleSelectItem('Passages', student);
                        }}
                      >
                        <ListItemDecorator sx={{ color: 'inherit' }}>
                          <AutoStoriesRoundedIcon
                            fontSize='small'
                            sx={{
                              marginLeft: '22px',
                              marginRight: '12px',
                              color: 'neutral.500',
                            }}
                          />
                        </ListItemDecorator>
                        <ListItemContent>Passages</ListItemContent>
                        <Chip
                          variant='soft'
                          color='neutral'
                          size='sm'
                          sx={{ borderRadius: 'sm' }}
                        >
                          Coming Soon
                        </Chip>
                      </ListItemButton>
                    </ListItem>

                    <ListItem>
                      <ListItemButton
                        variant={
                          activeItem === `Lessons-${student.id}`
                            ? 'soft'
                            : 'default'
                        }
                        disabled
                        onClick={() => {
                          setActiveItem(`Lessons-${student.id}`);
                          handleSelectItem('Lessons', student);
                        }}
                      >
                        <ListItemDecorator sx={{ color: 'inherit' }}>
                          <AccountTreeRoundedIcon
                            fontSize='small'
                            sx={{
                              marginLeft: '22px',
                              marginRight: '12px',
                              color: 'neutral.500',
                            }}
                          />
                        </ListItemDecorator>
                        <ListItemContent>Lessons</ListItemContent>
                        <Chip
                          variant='soft'
                          color='neutral'
                          size='sm'
                          sx={{ borderRadius: 'sm' }}
                        >
                          Coming Soon
                        </Chip>
                      </ListItemButton>
                    </ListItem>

                    <ListItem>
                      <ListItemButton
                        variant={
                          activeItem === `Student Data-${student.id}`
                            ? 'soft'
                            : 'default'
                        }
                        disabled
                        onClick={() => {
                          setActiveItem(`Student Data-${student.id}`);
                          handleSelectItem('Student Data', student);
                        }}
                      >
                        <ListItemDecorator sx={{ color: 'inherit' }}>
                          <AssessmentRoundedIcon
                            fontSize='small'
                            sx={{
                              marginLeft: '22px',
                              marginRight: '12px',
                              color: 'neutral.500',
                            }}
                          />
                        </ListItemDecorator>
                        <ListItemContent>Student Data</ListItemContent>
                        <Chip
                          variant='soft'
                          color='neutral'
                          size='sm'
                          sx={{ borderRadius: 'sm' }}
                        >
                          Coming Soon
                        </Chip>
                      </ListItemButton>
                    </ListItem>
                  </List>
                )}
              </ListItem>
            ))}
        </ListItem>
        <ListItem nested>
          <ListItemButton
            fontSize='small'
            size='sm'
            onClick={() => handleOpenModal('AddStudent')}
          >
            <ListItemDecorator sx={{ color: 'neutral.500' }}>
              <AddRoundedIcon
                size='sm'
                fontSize='small'
                color='primary'
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  marginLeft: '12px',
                  marginRight: '12px',
                  color: 'neutral.500',
                }}
              />
            </ListItemDecorator>

            <Typography
              component='h4'
              id='modal-title'
              level='body-xs'
              textColor='inherit'
              fontWeight='sm'
            >
              Add Student
            </Typography>
          </ListItemButton>
        </ListItem>
        <ListItem nested sx={{ mt: 2 }}>
          <ListSubheader
            component='div'
            onClick={handleAdministrationExpandClick}
            sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          >
            Administration
            <IconButton
              size='sm'
              variant='plain'
              color='primary'
              sx={{ '--IconButton-size': '24px', ml: 'auto', p: 0 }}
              onClick={(e) => {
                e.stopPropagation();
                handleAdministrationExpandClick();
              }}
            >
              <KeyboardArrowDownRoundedIcon fontSize='small' color='primary' />
            </IconButton>
          </ListSubheader>
          {isAdministrationExpanded && (
            // Nested Content
            <List
              aria-labelledby='nav-list-browse'
              size='sm'
              sx={{
                '& .JoyListItemButton-root': { p: '8px' },
              }}
            >
              <ListItem>
                <ListItemButton
                  onClick={() => handleOpenModal('ManageAccount')}
                >
                  <ListItemDecorator sx={{ color: 'neutral.500' }}>
                    <AssignmentIndRoundedIcon fontSize='small' />
                  </ListItemDecorator>
                  <ListItemContent>Manage Account</ListItemContent>
                </ListItemButton>
              </ListItem>
              {/* <ListItem>
                <ListItemButton
                  onClick={() => handleOpenModal('LessonTemplates')}
                >
                  <ListItemDecorator sx={{ color: 'neutral.500' }}>
                    <SpaceDashboardRoundedIcon fontSize='small' />
                  </ListItemDecorator>
                  <ListItemContent>Lesson Templates</ListItemContent>
                  <Chip
                    variant='soft'
                    color='neutral'
                    size='sm'
                    sx={{ borderRadius: 'sm' }}
                  >
                    Coming Soon
                  </Chip>
                </ListItemButton>
              </ListItem> */}
            </List>
          )}
        </ListItem>
      </List>
      {/* <ManageStudentsModal
        open={openModal === 'ManageStudents'}
        onClose={handleCloseModal}
      /> */}

      <ManageAccountModal
        open={openModal === 'ManageAccount'}
        onClose={handleCloseModal}
      />

      <LessonTemplatesModal
        open={openModal === 'LessonTemplates'}
        onClose={handleCloseModal}
      />

      <AddStudentModal
        open={openModal === 'AddStudent'}
        onClose={handleCloseModal}
      />
    </>
  );
}
