/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateGenerateDocument = /* GraphQL */ `
  subscription OnCreateGenerateDocument(
    $filter: ModelSubscriptionGenerateDocumentFilterInput
    $owner: String
  ) {
    onCreateGenerateDocument(filter: $filter, owner: $owner) {
      id
      listObject
      docParameters
      downloadDocumentLink
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateGenerateDocument = /* GraphQL */ `
  subscription OnUpdateGenerateDocument(
    $filter: ModelSubscriptionGenerateDocumentFilterInput
    $owner: String
  ) {
    onUpdateGenerateDocument(filter: $filter, owner: $owner) {
      id
      listObject
      docParameters
      downloadDocumentLink
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteGenerateDocument = /* GraphQL */ `
  subscription OnDeleteGenerateDocument(
    $filter: ModelSubscriptionGenerateDocumentFilterInput
    $owner: String
  ) {
    onDeleteGenerateDocument(filter: $filter, owner: $owner) {
      id
      listObject
      docParameters
      downloadDocumentLink
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateStudent = /* GraphQL */ `
  subscription OnCreateStudent(
    $filter: ModelSubscriptionStudentFilterInput
    $owner: String
  ) {
    onCreateStudent(filter: $filter, owner: $owner) {
      id
      lastName
      firstName
      dob
      comments
      customID
      studentData
      Lessons {
        items {
          id
          date
          minutes
          rating
          studentID
          concepts
          lessonNumber
          lessonData
          comments
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Concepts {
        items {
          id
          studentId
          conceptId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Lists {
        items {
          id
          name
          conceptID
          listData
          studentID
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateStudent = /* GraphQL */ `
  subscription OnUpdateStudent(
    $filter: ModelSubscriptionStudentFilterInput
    $owner: String
  ) {
    onUpdateStudent(filter: $filter, owner: $owner) {
      id
      lastName
      firstName
      dob
      comments
      customID
      studentData
      Lessons {
        items {
          id
          date
          minutes
          rating
          studentID
          concepts
          lessonNumber
          lessonData
          comments
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Concepts {
        items {
          id
          studentId
          conceptId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Lists {
        items {
          id
          name
          conceptID
          listData
          studentID
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteStudent = /* GraphQL */ `
  subscription OnDeleteStudent(
    $filter: ModelSubscriptionStudentFilterInput
    $owner: String
  ) {
    onDeleteStudent(filter: $filter, owner: $owner) {
      id
      lastName
      firstName
      dob
      comments
      customID
      studentData
      Lessons {
        items {
          id
          date
          minutes
          rating
          studentID
          concepts
          lessonNumber
          lessonData
          comments
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Concepts {
        items {
          id
          studentId
          conceptId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Lists {
        items {
          id
          name
          conceptID
          listData
          studentID
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreatePassage = /* GraphQL */ `
  subscription OnCreatePassage($filter: ModelSubscriptionPassageFilterInput) {
    onCreatePassage(filter: $filter) {
      id
      title
      text
      wordCount
      author
      downvotes
      upvotes
      gptPrompt
      conceptID
      Lessons {
        items {
          id
          passageId
          lessonId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      passageData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdatePassage = /* GraphQL */ `
  subscription OnUpdatePassage($filter: ModelSubscriptionPassageFilterInput) {
    onUpdatePassage(filter: $filter) {
      id
      title
      text
      wordCount
      author
      downvotes
      upvotes
      gptPrompt
      conceptID
      Lessons {
        items {
          id
          passageId
          lessonId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      passageData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeletePassage = /* GraphQL */ `
  subscription OnDeletePassage($filter: ModelSubscriptionPassageFilterInput) {
    onDeletePassage(filter: $filter) {
      id
      title
      text
      wordCount
      author
      downvotes
      upvotes
      gptPrompt
      conceptID
      Lessons {
        items {
          id
          passageId
          lessonId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      passageData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateSentence = /* GraphQL */ `
  subscription OnCreateSentence($filter: ModelSubscriptionSentenceFilterInput) {
    onCreateSentence(filter: $filter) {
      id
      text
      wordCount
      author
      upvotes
      downvotes
      gptPrompt
      Lessons {
        items {
          id
          sentenceId
          lessonId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Words {
        items {
          id
          sentenceId
          wordId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Concepts {
        items {
          id
          sentenceId
          conceptId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sentenceData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSentence = /* GraphQL */ `
  subscription OnUpdateSentence($filter: ModelSubscriptionSentenceFilterInput) {
    onUpdateSentence(filter: $filter) {
      id
      text
      wordCount
      author
      upvotes
      downvotes
      gptPrompt
      Lessons {
        items {
          id
          sentenceId
          lessonId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Words {
        items {
          id
          sentenceId
          wordId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Concepts {
        items {
          id
          sentenceId
          conceptId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sentenceData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSentence = /* GraphQL */ `
  subscription OnDeleteSentence($filter: ModelSubscriptionSentenceFilterInput) {
    onDeleteSentence(filter: $filter) {
      id
      text
      wordCount
      author
      upvotes
      downvotes
      gptPrompt
      Lessons {
        items {
          id
          sentenceId
          lessonId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Words {
        items {
          id
          sentenceId
          wordId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Concepts {
        items {
          id
          sentenceId
          conceptId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sentenceData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateLesson = /* GraphQL */ `
  subscription OnCreateLesson(
    $filter: ModelSubscriptionLessonFilterInput
    $owner: String
  ) {
    onCreateLesson(filter: $filter, owner: $owner) {
      id
      date
      minutes
      rating
      studentID
      conceptID {
        items {
          id
          lessonId
          conceptId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      concepts
      passages {
        items {
          id
          passageId
          lessonId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      sentences {
        items {
          id
          sentenceId
          lessonId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      lists {
        items {
          id
          lessonId
          listId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      lessonNumber
      lessonData
      comments
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateLesson = /* GraphQL */ `
  subscription OnUpdateLesson(
    $filter: ModelSubscriptionLessonFilterInput
    $owner: String
  ) {
    onUpdateLesson(filter: $filter, owner: $owner) {
      id
      date
      minutes
      rating
      studentID
      conceptID {
        items {
          id
          lessonId
          conceptId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      concepts
      passages {
        items {
          id
          passageId
          lessonId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      sentences {
        items {
          id
          sentenceId
          lessonId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      lists {
        items {
          id
          lessonId
          listId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      lessonNumber
      lessonData
      comments
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteLesson = /* GraphQL */ `
  subscription OnDeleteLesson(
    $filter: ModelSubscriptionLessonFilterInput
    $owner: String
  ) {
    onDeleteLesson(filter: $filter, owner: $owner) {
      id
      date
      minutes
      rating
      studentID
      conceptID {
        items {
          id
          lessonId
          conceptId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      concepts
      passages {
        items {
          id
          passageId
          lessonId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      sentences {
        items {
          id
          sentenceId
          lessonId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      lists {
        items {
          id
          lessonId
          listId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      lessonNumber
      lessonData
      comments
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateWord = /* GraphQL */ `
  subscription OnCreateWord($filter: ModelSubscriptionWordFilterInput) {
    onCreateWord(filter: $filter) {
      id
      word
      isNonsenseWord
      concepts {
        items {
          id
          wordId
          conceptId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sentences {
        items {
          id
          sentenceId
          wordId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Lists {
        items {
          id
          wordId
          listId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      wordData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateWord = /* GraphQL */ `
  subscription OnUpdateWord($filter: ModelSubscriptionWordFilterInput) {
    onUpdateWord(filter: $filter) {
      id
      word
      isNonsenseWord
      concepts {
        items {
          id
          wordId
          conceptId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sentences {
        items {
          id
          sentenceId
          wordId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Lists {
        items {
          id
          wordId
          listId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      wordData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteWord = /* GraphQL */ `
  subscription OnDeleteWord($filter: ModelSubscriptionWordFilterInput) {
    onDeleteWord(filter: $filter) {
      id
      word
      isNonsenseWord
      concepts {
        items {
          id
          wordId
          conceptId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sentences {
        items {
          id
          sentenceId
          wordId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Lists {
        items {
          id
          wordId
          listId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      wordData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateConcept = /* GraphQL */ `
  subscription OnCreateConcept($filter: ModelSubscriptionConceptFilterInput) {
    onCreateConcept(filter: $filter) {
      id
      concept
      subcategory
      category
      level
      definition
      trainingResources
      NewLessons {
        items {
          id
          date
          minutes
          rating
          studentID
          concepts
          lessonNumber
          lessonData
          comments
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Lists {
        items {
          id
          name
          conceptID
          listData
          studentID
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Passages {
        items {
          id
          title
          text
          wordCount
          author
          downvotes
          upvotes
          gptPrompt
          conceptID
          passageData
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      students {
        items {
          id
          studentId
          conceptId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Words {
        items {
          id
          wordId
          conceptId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      ReviewLessons {
        items {
          id
          lessonId
          conceptId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      sentences {
        items {
          id
          sentenceId
          conceptId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateConcept = /* GraphQL */ `
  subscription OnUpdateConcept($filter: ModelSubscriptionConceptFilterInput) {
    onUpdateConcept(filter: $filter) {
      id
      concept
      subcategory
      category
      level
      definition
      trainingResources
      NewLessons {
        items {
          id
          date
          minutes
          rating
          studentID
          concepts
          lessonNumber
          lessonData
          comments
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Lists {
        items {
          id
          name
          conceptID
          listData
          studentID
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Passages {
        items {
          id
          title
          text
          wordCount
          author
          downvotes
          upvotes
          gptPrompt
          conceptID
          passageData
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      students {
        items {
          id
          studentId
          conceptId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Words {
        items {
          id
          wordId
          conceptId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      ReviewLessons {
        items {
          id
          lessonId
          conceptId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      sentences {
        items {
          id
          sentenceId
          conceptId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteConcept = /* GraphQL */ `
  subscription OnDeleteConcept($filter: ModelSubscriptionConceptFilterInput) {
    onDeleteConcept(filter: $filter) {
      id
      concept
      subcategory
      category
      level
      definition
      trainingResources
      NewLessons {
        items {
          id
          date
          minutes
          rating
          studentID
          concepts
          lessonNumber
          lessonData
          comments
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Lists {
        items {
          id
          name
          conceptID
          listData
          studentID
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Passages {
        items {
          id
          title
          text
          wordCount
          author
          downvotes
          upvotes
          gptPrompt
          conceptID
          passageData
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      students {
        items {
          id
          studentId
          conceptId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Words {
        items {
          id
          wordId
          conceptId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      ReviewLessons {
        items {
          id
          lessonId
          conceptId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      sentences {
        items {
          id
          sentenceId
          conceptId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateList = /* GraphQL */ `
  subscription OnCreateList(
    $filter: ModelSubscriptionListFilterInput
    $owner: String
  ) {
    onCreateList(filter: $filter, owner: $owner) {
      id
      name
      conceptID
      words {
        items {
          id
          wordId
          listId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Lessons {
        items {
          id
          lessonId
          listId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      listData
      studentID
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateList = /* GraphQL */ `
  subscription OnUpdateList(
    $filter: ModelSubscriptionListFilterInput
    $owner: String
  ) {
    onUpdateList(filter: $filter, owner: $owner) {
      id
      name
      conceptID
      words {
        items {
          id
          wordId
          listId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Lessons {
        items {
          id
          lessonId
          listId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      listData
      studentID
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteList = /* GraphQL */ `
  subscription OnDeleteList(
    $filter: ModelSubscriptionListFilterInput
    $owner: String
  ) {
    onDeleteList(filter: $filter, owner: $owner) {
      id
      name
      conceptID
      words {
        items {
          id
          wordId
          listId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Lessons {
        items {
          id
          lessonId
          listId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      listData
      studentID
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateStudentConcept = /* GraphQL */ `
  subscription OnCreateStudentConcept(
    $filter: ModelSubscriptionStudentConceptFilterInput
    $owner: String
  ) {
    onCreateStudentConcept(filter: $filter, owner: $owner) {
      id
      studentId
      conceptId
      student {
        id
        lastName
        firstName
        dob
        comments
        customID
        studentData
        Lessons {
          nextToken
          __typename
        }
        Concepts {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        owner
        createdAt
        updatedAt
        __typename
      }
      concept {
        id
        concept
        subcategory
        category
        level
        definition
        trainingResources
        NewLessons {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        Passages {
          nextToken
          __typename
        }
        students {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        ReviewLessons {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateStudentConcept = /* GraphQL */ `
  subscription OnUpdateStudentConcept(
    $filter: ModelSubscriptionStudentConceptFilterInput
    $owner: String
  ) {
    onUpdateStudentConcept(filter: $filter, owner: $owner) {
      id
      studentId
      conceptId
      student {
        id
        lastName
        firstName
        dob
        comments
        customID
        studentData
        Lessons {
          nextToken
          __typename
        }
        Concepts {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        owner
        createdAt
        updatedAt
        __typename
      }
      concept {
        id
        concept
        subcategory
        category
        level
        definition
        trainingResources
        NewLessons {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        Passages {
          nextToken
          __typename
        }
        students {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        ReviewLessons {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteStudentConcept = /* GraphQL */ `
  subscription OnDeleteStudentConcept(
    $filter: ModelSubscriptionStudentConceptFilterInput
    $owner: String
  ) {
    onDeleteStudentConcept(filter: $filter, owner: $owner) {
      id
      studentId
      conceptId
      student {
        id
        lastName
        firstName
        dob
        comments
        customID
        studentData
        Lessons {
          nextToken
          __typename
        }
        Concepts {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        owner
        createdAt
        updatedAt
        __typename
      }
      concept {
        id
        concept
        subcategory
        category
        level
        definition
        trainingResources
        NewLessons {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        Passages {
          nextToken
          __typename
        }
        students {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        ReviewLessons {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreatePassageLesson = /* GraphQL */ `
  subscription OnCreatePassageLesson(
    $filter: ModelSubscriptionPassageLessonFilterInput
    $owner: String
  ) {
    onCreatePassageLesson(filter: $filter, owner: $owner) {
      id
      passageId
      lessonId
      passage {
        id
        title
        text
        wordCount
        author
        downvotes
        upvotes
        gptPrompt
        conceptID
        Lessons {
          nextToken
          __typename
        }
        passageData
        createdAt
        updatedAt
        __typename
      }
      lesson {
        id
        date
        minutes
        rating
        studentID
        conceptID {
          nextToken
          __typename
        }
        concepts
        passages {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        lists {
          nextToken
          __typename
        }
        lessonNumber
        lessonData
        comments
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdatePassageLesson = /* GraphQL */ `
  subscription OnUpdatePassageLesson(
    $filter: ModelSubscriptionPassageLessonFilterInput
    $owner: String
  ) {
    onUpdatePassageLesson(filter: $filter, owner: $owner) {
      id
      passageId
      lessonId
      passage {
        id
        title
        text
        wordCount
        author
        downvotes
        upvotes
        gptPrompt
        conceptID
        Lessons {
          nextToken
          __typename
        }
        passageData
        createdAt
        updatedAt
        __typename
      }
      lesson {
        id
        date
        minutes
        rating
        studentID
        conceptID {
          nextToken
          __typename
        }
        concepts
        passages {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        lists {
          nextToken
          __typename
        }
        lessonNumber
        lessonData
        comments
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeletePassageLesson = /* GraphQL */ `
  subscription OnDeletePassageLesson(
    $filter: ModelSubscriptionPassageLessonFilterInput
    $owner: String
  ) {
    onDeletePassageLesson(filter: $filter, owner: $owner) {
      id
      passageId
      lessonId
      passage {
        id
        title
        text
        wordCount
        author
        downvotes
        upvotes
        gptPrompt
        conceptID
        Lessons {
          nextToken
          __typename
        }
        passageData
        createdAt
        updatedAt
        __typename
      }
      lesson {
        id
        date
        minutes
        rating
        studentID
        conceptID {
          nextToken
          __typename
        }
        concepts
        passages {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        lists {
          nextToken
          __typename
        }
        lessonNumber
        lessonData
        comments
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateSentenceLesson = /* GraphQL */ `
  subscription OnCreateSentenceLesson(
    $filter: ModelSubscriptionSentenceLessonFilterInput
    $owner: String
  ) {
    onCreateSentenceLesson(filter: $filter, owner: $owner) {
      id
      sentenceId
      lessonId
      sentence {
        id
        text
        wordCount
        author
        upvotes
        downvotes
        gptPrompt
        Lessons {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        Concepts {
          nextToken
          __typename
        }
        sentenceData
        createdAt
        updatedAt
        __typename
      }
      lesson {
        id
        date
        minutes
        rating
        studentID
        conceptID {
          nextToken
          __typename
        }
        concepts
        passages {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        lists {
          nextToken
          __typename
        }
        lessonNumber
        lessonData
        comments
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateSentenceLesson = /* GraphQL */ `
  subscription OnUpdateSentenceLesson(
    $filter: ModelSubscriptionSentenceLessonFilterInput
    $owner: String
  ) {
    onUpdateSentenceLesson(filter: $filter, owner: $owner) {
      id
      sentenceId
      lessonId
      sentence {
        id
        text
        wordCount
        author
        upvotes
        downvotes
        gptPrompt
        Lessons {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        Concepts {
          nextToken
          __typename
        }
        sentenceData
        createdAt
        updatedAt
        __typename
      }
      lesson {
        id
        date
        minutes
        rating
        studentID
        conceptID {
          nextToken
          __typename
        }
        concepts
        passages {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        lists {
          nextToken
          __typename
        }
        lessonNumber
        lessonData
        comments
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteSentenceLesson = /* GraphQL */ `
  subscription OnDeleteSentenceLesson(
    $filter: ModelSubscriptionSentenceLessonFilterInput
    $owner: String
  ) {
    onDeleteSentenceLesson(filter: $filter, owner: $owner) {
      id
      sentenceId
      lessonId
      sentence {
        id
        text
        wordCount
        author
        upvotes
        downvotes
        gptPrompt
        Lessons {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        Concepts {
          nextToken
          __typename
        }
        sentenceData
        createdAt
        updatedAt
        __typename
      }
      lesson {
        id
        date
        minutes
        rating
        studentID
        conceptID {
          nextToken
          __typename
        }
        concepts
        passages {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        lists {
          nextToken
          __typename
        }
        lessonNumber
        lessonData
        comments
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateSentenceWord = /* GraphQL */ `
  subscription OnCreateSentenceWord(
    $filter: ModelSubscriptionSentenceWordFilterInput
  ) {
    onCreateSentenceWord(filter: $filter) {
      id
      sentenceId
      wordId
      sentence {
        id
        text
        wordCount
        author
        upvotes
        downvotes
        gptPrompt
        Lessons {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        Concepts {
          nextToken
          __typename
        }
        sentenceData
        createdAt
        updatedAt
        __typename
      }
      word {
        id
        word
        isNonsenseWord
        concepts {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        wordData
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSentenceWord = /* GraphQL */ `
  subscription OnUpdateSentenceWord(
    $filter: ModelSubscriptionSentenceWordFilterInput
  ) {
    onUpdateSentenceWord(filter: $filter) {
      id
      sentenceId
      wordId
      sentence {
        id
        text
        wordCount
        author
        upvotes
        downvotes
        gptPrompt
        Lessons {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        Concepts {
          nextToken
          __typename
        }
        sentenceData
        createdAt
        updatedAt
        __typename
      }
      word {
        id
        word
        isNonsenseWord
        concepts {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        wordData
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSentenceWord = /* GraphQL */ `
  subscription OnDeleteSentenceWord(
    $filter: ModelSubscriptionSentenceWordFilterInput
  ) {
    onDeleteSentenceWord(filter: $filter) {
      id
      sentenceId
      wordId
      sentence {
        id
        text
        wordCount
        author
        upvotes
        downvotes
        gptPrompt
        Lessons {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        Concepts {
          nextToken
          __typename
        }
        sentenceData
        createdAt
        updatedAt
        __typename
      }
      word {
        id
        word
        isNonsenseWord
        concepts {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        wordData
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateSentenceConcept = /* GraphQL */ `
  subscription OnCreateSentenceConcept(
    $filter: ModelSubscriptionSentenceConceptFilterInput
  ) {
    onCreateSentenceConcept(filter: $filter) {
      id
      sentenceId
      conceptId
      sentence {
        id
        text
        wordCount
        author
        upvotes
        downvotes
        gptPrompt
        Lessons {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        Concepts {
          nextToken
          __typename
        }
        sentenceData
        createdAt
        updatedAt
        __typename
      }
      concept {
        id
        concept
        subcategory
        category
        level
        definition
        trainingResources
        NewLessons {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        Passages {
          nextToken
          __typename
        }
        students {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        ReviewLessons {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSentenceConcept = /* GraphQL */ `
  subscription OnUpdateSentenceConcept(
    $filter: ModelSubscriptionSentenceConceptFilterInput
  ) {
    onUpdateSentenceConcept(filter: $filter) {
      id
      sentenceId
      conceptId
      sentence {
        id
        text
        wordCount
        author
        upvotes
        downvotes
        gptPrompt
        Lessons {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        Concepts {
          nextToken
          __typename
        }
        sentenceData
        createdAt
        updatedAt
        __typename
      }
      concept {
        id
        concept
        subcategory
        category
        level
        definition
        trainingResources
        NewLessons {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        Passages {
          nextToken
          __typename
        }
        students {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        ReviewLessons {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSentenceConcept = /* GraphQL */ `
  subscription OnDeleteSentenceConcept(
    $filter: ModelSubscriptionSentenceConceptFilterInput
  ) {
    onDeleteSentenceConcept(filter: $filter) {
      id
      sentenceId
      conceptId
      sentence {
        id
        text
        wordCount
        author
        upvotes
        downvotes
        gptPrompt
        Lessons {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        Concepts {
          nextToken
          __typename
        }
        sentenceData
        createdAt
        updatedAt
        __typename
      }
      concept {
        id
        concept
        subcategory
        category
        level
        definition
        trainingResources
        NewLessons {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        Passages {
          nextToken
          __typename
        }
        students {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        ReviewLessons {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateConceptLesson = /* GraphQL */ `
  subscription OnCreateConceptLesson(
    $filter: ModelSubscriptionConceptLessonFilterInput
    $owner: String
  ) {
    onCreateConceptLesson(filter: $filter, owner: $owner) {
      id
      lessonId
      conceptId
      lesson {
        id
        date
        minutes
        rating
        studentID
        conceptID {
          nextToken
          __typename
        }
        concepts
        passages {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        lists {
          nextToken
          __typename
        }
        lessonNumber
        lessonData
        comments
        createdAt
        updatedAt
        owner
        __typename
      }
      concept {
        id
        concept
        subcategory
        category
        level
        definition
        trainingResources
        NewLessons {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        Passages {
          nextToken
          __typename
        }
        students {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        ReviewLessons {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateConceptLesson = /* GraphQL */ `
  subscription OnUpdateConceptLesson(
    $filter: ModelSubscriptionConceptLessonFilterInput
    $owner: String
  ) {
    onUpdateConceptLesson(filter: $filter, owner: $owner) {
      id
      lessonId
      conceptId
      lesson {
        id
        date
        minutes
        rating
        studentID
        conceptID {
          nextToken
          __typename
        }
        concepts
        passages {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        lists {
          nextToken
          __typename
        }
        lessonNumber
        lessonData
        comments
        createdAt
        updatedAt
        owner
        __typename
      }
      concept {
        id
        concept
        subcategory
        category
        level
        definition
        trainingResources
        NewLessons {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        Passages {
          nextToken
          __typename
        }
        students {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        ReviewLessons {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteConceptLesson = /* GraphQL */ `
  subscription OnDeleteConceptLesson(
    $filter: ModelSubscriptionConceptLessonFilterInput
    $owner: String
  ) {
    onDeleteConceptLesson(filter: $filter, owner: $owner) {
      id
      lessonId
      conceptId
      lesson {
        id
        date
        minutes
        rating
        studentID
        conceptID {
          nextToken
          __typename
        }
        concepts
        passages {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        lists {
          nextToken
          __typename
        }
        lessonNumber
        lessonData
        comments
        createdAt
        updatedAt
        owner
        __typename
      }
      concept {
        id
        concept
        subcategory
        category
        level
        definition
        trainingResources
        NewLessons {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        Passages {
          nextToken
          __typename
        }
        students {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        ReviewLessons {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateListLesson = /* GraphQL */ `
  subscription OnCreateListLesson(
    $filter: ModelSubscriptionListLessonFilterInput
    $owner: String
  ) {
    onCreateListLesson(filter: $filter, owner: $owner) {
      id
      lessonId
      listId
      lesson {
        id
        date
        minutes
        rating
        studentID
        conceptID {
          nextToken
          __typename
        }
        concepts
        passages {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        lists {
          nextToken
          __typename
        }
        lessonNumber
        lessonData
        comments
        createdAt
        updatedAt
        owner
        __typename
      }
      list {
        id
        name
        conceptID
        words {
          nextToken
          __typename
        }
        Lessons {
          nextToken
          __typename
        }
        listData
        studentID
        owner
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateListLesson = /* GraphQL */ `
  subscription OnUpdateListLesson(
    $filter: ModelSubscriptionListLessonFilterInput
    $owner: String
  ) {
    onUpdateListLesson(filter: $filter, owner: $owner) {
      id
      lessonId
      listId
      lesson {
        id
        date
        minutes
        rating
        studentID
        conceptID {
          nextToken
          __typename
        }
        concepts
        passages {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        lists {
          nextToken
          __typename
        }
        lessonNumber
        lessonData
        comments
        createdAt
        updatedAt
        owner
        __typename
      }
      list {
        id
        name
        conceptID
        words {
          nextToken
          __typename
        }
        Lessons {
          nextToken
          __typename
        }
        listData
        studentID
        owner
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteListLesson = /* GraphQL */ `
  subscription OnDeleteListLesson(
    $filter: ModelSubscriptionListLessonFilterInput
    $owner: String
  ) {
    onDeleteListLesson(filter: $filter, owner: $owner) {
      id
      lessonId
      listId
      lesson {
        id
        date
        minutes
        rating
        studentID
        conceptID {
          nextToken
          __typename
        }
        concepts
        passages {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        lists {
          nextToken
          __typename
        }
        lessonNumber
        lessonData
        comments
        createdAt
        updatedAt
        owner
        __typename
      }
      list {
        id
        name
        conceptID
        words {
          nextToken
          __typename
        }
        Lessons {
          nextToken
          __typename
        }
        listData
        studentID
        owner
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateConceptWord = /* GraphQL */ `
  subscription OnCreateConceptWord(
    $filter: ModelSubscriptionConceptWordFilterInput
  ) {
    onCreateConceptWord(filter: $filter) {
      id
      wordId
      conceptId
      word {
        id
        word
        isNonsenseWord
        concepts {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        wordData
        createdAt
        updatedAt
        __typename
      }
      concept {
        id
        concept
        subcategory
        category
        level
        definition
        trainingResources
        NewLessons {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        Passages {
          nextToken
          __typename
        }
        students {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        ReviewLessons {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateConceptWord = /* GraphQL */ `
  subscription OnUpdateConceptWord(
    $filter: ModelSubscriptionConceptWordFilterInput
  ) {
    onUpdateConceptWord(filter: $filter) {
      id
      wordId
      conceptId
      word {
        id
        word
        isNonsenseWord
        concepts {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        wordData
        createdAt
        updatedAt
        __typename
      }
      concept {
        id
        concept
        subcategory
        category
        level
        definition
        trainingResources
        NewLessons {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        Passages {
          nextToken
          __typename
        }
        students {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        ReviewLessons {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteConceptWord = /* GraphQL */ `
  subscription OnDeleteConceptWord(
    $filter: ModelSubscriptionConceptWordFilterInput
  ) {
    onDeleteConceptWord(filter: $filter) {
      id
      wordId
      conceptId
      word {
        id
        word
        isNonsenseWord
        concepts {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        wordData
        createdAt
        updatedAt
        __typename
      }
      concept {
        id
        concept
        subcategory
        category
        level
        definition
        trainingResources
        NewLessons {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        Passages {
          nextToken
          __typename
        }
        students {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        ReviewLessons {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateWordList = /* GraphQL */ `
  subscription OnCreateWordList(
    $filter: ModelSubscriptionWordListFilterInput
    $owner: String
  ) {
    onCreateWordList(filter: $filter, owner: $owner) {
      id
      wordId
      listId
      word {
        id
        word
        isNonsenseWord
        concepts {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        wordData
        createdAt
        updatedAt
        __typename
      }
      list {
        id
        name
        conceptID
        words {
          nextToken
          __typename
        }
        Lessons {
          nextToken
          __typename
        }
        listData
        studentID
        owner
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateWordList = /* GraphQL */ `
  subscription OnUpdateWordList(
    $filter: ModelSubscriptionWordListFilterInput
    $owner: String
  ) {
    onUpdateWordList(filter: $filter, owner: $owner) {
      id
      wordId
      listId
      word {
        id
        word
        isNonsenseWord
        concepts {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        wordData
        createdAt
        updatedAt
        __typename
      }
      list {
        id
        name
        conceptID
        words {
          nextToken
          __typename
        }
        Lessons {
          nextToken
          __typename
        }
        listData
        studentID
        owner
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteWordList = /* GraphQL */ `
  subscription OnDeleteWordList(
    $filter: ModelSubscriptionWordListFilterInput
    $owner: String
  ) {
    onDeleteWordList(filter: $filter, owner: $owner) {
      id
      wordId
      listId
      word {
        id
        word
        isNonsenseWord
        concepts {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        wordData
        createdAt
        updatedAt
        __typename
      }
      list {
        id
        name
        conceptID
        words {
          nextToken
          __typename
        }
        Lessons {
          nextToken
          __typename
        }
        listData
        studentID
        owner
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
