import React from 'react';
import Typography from '@mui/joy/Typography';
import Input from '@mui/joy/Input';
import DatePicker from 'react-datepicker';
import { Button } from '@mui/joy';

function EditOrDeleteStudentForm({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  dob,
  setDob,
  comments,
  setComments,
  customID,
  setCustomID,
  handleEdit,
  handleDelete,
  error,
}) {
  return (
    <>
      <Typography>First Name:</Typography>
      <Input value={firstName} onChange={(e) => setFirstName(e.target.value)} />
      <Typography>Last Name:</Typography>
      <Input value={lastName} onChange={(e) => setLastName(e.target.value)} />
      {/* <Typography>Date of Birth:</Typography>
      <DatePicker selected={dob} onChange={(date) => setDob(date)} /> */}
      <Typography>Comments:</Typography>
      <Input value={comments} onChange={(e) => setComments(e.target.value)} />
      <Typography>Custom ID:</Typography>
      <Input value={customID} onChange={(e) => setCustomID(e.target.value)} />
      <Button variant='outlined' onClick={handleEdit}>
        Edit Student
      </Button>
      <Button variant='outlined' onClick={handleDelete}>
        Delete Student
      </Button>
      {error && <Typography textColor='error'>{error}</Typography>}
    </>
  );
}

export default EditOrDeleteStudentForm;
