import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { API } from 'aws-amplify';
import { createStudent } from '../../custom-graphql/customMutations';
import { Button, Input, Typography } from '@mui/joy';

function CreateStudentForm({ onClose }) {
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [dob, setDob] = useState(new Date());
  const [comments, setComments] = useState('');
  const [customID, setCustomID] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSuccess(false); // Reset success state on new submission
    setError(''); // Reset error state on new submission
    // Check if the required fields are filled
    if (!firstName || !lastName) {
      console.error('First Name and Last Name are required!');
      return;
    }

    const formattedDOB = `${dob.getFullYear()}-${String(
      dob.getMonth() + 1
    ).padStart(2, '0')}-${String(dob.getDate()).padStart(2, '0')}`;

    const studentInput = {
      lastName,
      firstName,
      dob: formattedDOB,
      comments,
      customID,
    };

    try {
      await API.graphql({
        query: createStudent,
        variables: { input: studentInput },
      });
      console.log('Student created successfully');
      setSuccess(true);

      // Close the modal after success
      if (onClose) {
        onClose();
      }
    } catch (error) {
      console.error('Error creating student:', error);
      setError('Error creating student. Please try again.');
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ padding: '20px' }}>
      {success && (
        <Typography textColor='success' style={{ marginBottom: '10px' }}>
          Student created successfully!
        </Typography>
      )}
      {error && (
        <Typography textColor='error' style={{ marginBottom: '10px' }}>
          {error}
        </Typography>
      )}

      <div style={{ marginBottom: '15px' }}>
        <Typography style={{ paddingRight: '10px' }}>First Name:</Typography>
        <Input
          type='text'
          required
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </div>
      <div style={{ marginBottom: '15px' }}>
        <Typography style={{ paddingRight: '10px' }}>Last Name:</Typography>
        <Input
          type='text'
          required
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </div>
      <div style={{ marginBottom: '15px' }}>
        {/* <Typography style={{ paddingRight: '10px' }}>Date of Birth:</Typography> */}
        {/* <DatePicker selected={dob} onChange={(date) => setDob(date)} /> */}
      </div>
      <div style={{ marginBottom: '15px' }}>
        <Typography style={{ paddingRight: '10px' }}>Comments:</Typography>
        <Input value={comments} onChange={(e) => setComments(e.target.value)} />
      </div>
      <div style={{ marginBottom: '15px' }}>
        <Typography style={{ paddingRight: '10px' }}>Custom ID:</Typography>
        <Input
          type='text'
          value={customID}
          onChange={(e) => setCustomID(e.target.value)}
        />
      </div>
      <Button variant='outlined' type='submit'>
        Create Student
      </Button>
    </form>
  );
}

export default CreateStudentForm;
