import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import BookmarkAdd from '@mui/icons-material/BookmarkAddOutlined';
import ThumbDownAltRoundedIcon from '@mui/icons-material/ThumbDownAltRounded';
import { ThumbUpAltSharp } from '@mui/icons-material';
import { Stack } from '@mui/joy';

export default function BasicCard() {
  return (
    <Card variant='outlined' sx={{ width: 320 }}>
      <div>
        <Typography level='h2' fontSize='md' sx={{ mb: 0.5 }}>
          Jacob goes camping
        </Typography>
        <br />
        <Typography level='body2'>by Celeste</Typography>
        <IconButton
          aria-label='bookmark Bahamas Islands'
          variant='plain'
          color='neutral'
          size='sm'
          sx={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }}
        >
          <BookmarkAdd />
        </IconButton>
      </div>
      <AspectRatio minHeight='120px' maxHeight='200px'>
        <img
          src='https://images.unsplash.com/photo-1527549993586-dff825b37782?auto=format&fit=crop&w=286'
          srcSet='https://images.unsplash.com/photo-1527549993586-dff825b37782?auto=format&fit=crop&w=286&dpr=2 2x'
          loading='lazy'
          alt=''
        />
      </AspectRatio>
      <CardContent orientation='horizontal'>
        <div>
          <Stack direction='row' spacing={1}>
            <IconButton
              variant='plain'
              color='primary'
              sx={{ '--IconButton-size': '32px', ml: 'auto', p: 1 }}
            >
              <ThumbUpAltSharp fontSize='small' color='primary' />
            </IconButton>
            <IconButton
              variant='plain'
              color='primary'
              sx={{ '--IconButton-size': '32px', ml: 'auto', p: 1 }}
            >
              <ThumbDownAltRoundedIcon fontSize='small' color='primary' />
            </IconButton>
            <Typography level='body2' sx={{ color: 'text.secondary' }}>
              VoteCount
            </Typography>
          </Stack>
        </div>
        <Button
          variant='solid'
          size='sm'
          color='primary'
          aria-label='Explore Bahamas Islands'
          sx={{ ml: 'auto', fontWeight: 600 }}
        >
          Download
        </Button>
      </CardContent>
    </Card>
  );
}
