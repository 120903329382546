import * as React from 'react';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import CreateStudentForm from '../forms/CreateStudent';
import { API } from 'aws-amplify';
import { deleteList as deleteListQuery } from '../../custom-graphql/customMutations';
import { Stack } from '@mui/joy';
import { useListStore } from '../../zustand/store';



const DeleteWordListModal = ({ open, onClose, myid }) => {
  const {lists, setLists} = useListStore()

  const deleteListFunc = async () => {
    try {
      await API.graphql({
        query: deleteListQuery,
        variables: { input: { id: myid } },
      });
      // Close the modal upon successful deletion
      onClose();
      var newLists = []
      for (var list in lists) {
        if (lists[list].id !== myid){
          newLists.push(lists[list]) 
        }
      }
      setLists(newLists)
    } catch (error) {
      console.error('Error while deleting:', error);
    }
  };
  return (
    <React.Fragment>
      <Modal
        aria-labelledby='modal-title'
        aria-describedby='modal-desc'
        open={open}
        onClose={onClose}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Sheet
          variant='outlined'
          sx={{
            maxWidth: 500,
            borderRadius: 'md',
            p: 5,
            boxShadow: 'lg',
          }}
        >
          <ModalClose variant='outlined' />
          <Typography level='h4'>Delete the list permanently?</Typography>{' '}
          <br />
          <Stack direction='row' spacing={3}>
            <Button spacing={3} onClick={onClose}>
              Cancel
            </Button>
            <Button color='danger' spacing={3} onClick={deleteListFunc}>
              Delete
            </Button>
          </Stack>
        </Sheet>
      </Modal>
    </React.Fragment>
  );
};

export default DeleteWordListModal;
