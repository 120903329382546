export const listLists = /* GraphQL */ `
  query ListLists(
    $filter: ModelListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        conceptID
        listData
        studentID
        owner
        createdAt
        updatedAt
        words {
          items {
            word {
              word
              wordData
              id
            }
          }
        }
        Lessons {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const WordsFromConcept = /* GraphQL */ `
  query WordsFromConcept($targetConceptId: ID!, $limit: Int) {
    listConcepts(
      filter: { id: { contains: $targetConceptId } }
      limit: $limit
    ) {
      items {
        id
        concept
        category
        level
        subcategory
        Words(limit: 1000) {
          items {
            id
            conceptId
            wordId
            word {
              word
              id
              isNonsenseWord
              concepts {
                items {
                  id
                  conceptId
                }
              }
            }
          }
        }
      }
    }
  }
`;
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getGenerateDocument = /* GraphQL */ `
  query GetGenerateDocument($id: ID!) {
    getGenerateDocument(id: $id) {
      id
      listObject
      docParameters
      downloadDocumentLink
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listGenerateDocuments = /* GraphQL */ `
  query ListGenerateDocuments(
    $filter: ModelGenerateDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGenerateDocuments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        listObject
        docParameters
        downloadDocumentLink
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStudent = /* GraphQL */ `
  query GetStudent($id: ID!) {
    getStudent(id: $id) {
      id
      lastName
      firstName
      dob
      comments
      customID
      studentData
      Lessons {
        items {
          id
          date
          minutes
          rating
          studentID
          concepts
          lessonNumber
          lessonData
          comments
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Concepts {
        items {
          id
          studentId
          conceptId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Lists {
        items {
          id
          name
          conceptID
          listData
          studentID
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listStudents = /* GraphQL */ `
  query ListStudents(
    $filter: ModelStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        lastName
        firstName
        dob
        comments
        customID
        studentData
        Lessons {
          nextToken
          __typename
        }
        Concepts {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPassage = /* GraphQL */ `
  query GetPassage($id: ID!) {
    getPassage(id: $id) {
      id
      title
      text
      wordCount
      author
      downvotes
      upvotes
      gptPrompt
      conceptID
      Lessons {
        items {
          id
          passageId
          lessonId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      passageData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPassages = /* GraphQL */ `
  query ListPassages(
    $filter: ModelPassageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPassages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        text
        wordCount
        author
        downvotes
        upvotes
        gptPrompt
        conceptID
        Lessons {
          nextToken
          __typename
        }
        passageData
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const passagesByConceptID = /* GraphQL */ `
  query PassagesByConceptID(
    $conceptID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPassageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    passagesByConceptID(
      conceptID: $conceptID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        text
        wordCount
        author
        downvotes
        upvotes
        gptPrompt
        conceptID
        Lessons {
          nextToken
          __typename
        }
        passageData
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSentence = /* GraphQL */ `
  query GetSentence($id: ID!) {
    getSentence(id: $id) {
      id
      text
      wordCount
      author
      upvotes
      downvotes
      gptPrompt
      Lessons {
        items {
          id
          sentenceId
          lessonId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Words {
        items {
          id
          sentenceId
          wordId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Concepts {
        items {
          id
          sentenceId
          conceptId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sentenceData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSentences = /* GraphQL */ `
  query ListSentences(
    $filter: ModelSentenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSentences(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        text
        wordCount
        author
        upvotes
        downvotes
        gptPrompt
        Lessons {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        Concepts {
          nextToken
          __typename
        }
        sentenceData
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLesson = /* GraphQL */ `
  query GetLesson($id: ID!) {
    getLesson(id: $id) {
      id
      date
      minutes
      rating
      studentID
      conceptID {
        items {
          id
          lessonId
          conceptId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      concepts
      passages {
        items {
          id
          passageId
          lessonId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      sentences {
        items {
          id
          sentenceId
          lessonId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      lists {
        items {
          id
          lessonId
          listId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      lessonNumber
      lessonData
      comments
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listLessons = /* GraphQL */ `
  query ListLessons(
    $filter: ModelLessonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLessons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        date
        minutes
        rating
        studentID
        conceptID {
          nextToken
          __typename
        }
        concepts
        passages {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        lists {
          nextToken
          __typename
        }
        lessonNumber
        lessonData
        comments
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const lessonsByStudentID = /* GraphQL */ `
  query LessonsByStudentID(
    $studentID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLessonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    lessonsByStudentID(
      studentID: $studentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        minutes
        rating
        studentID
        conceptID {
          nextToken
          __typename
        }
        concepts
        passages {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        lists {
          nextToken
          __typename
        }
        lessonNumber
        lessonData
        comments
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const lessonsByConcepts = /* GraphQL */ `
  query LessonsByConcepts(
    $concepts: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLessonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    lessonsByConcepts(
      concepts: $concepts
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        minutes
        rating
        studentID
        conceptID {
          nextToken
          __typename
        }
        concepts
        passages {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        lists {
          nextToken
          __typename
        }
        lessonNumber
        lessonData
        comments
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWord = /* GraphQL */ `
  query GetWord($id: ID!) {
    getWord(id: $id) {
      id
      word
      isNonsenseWord
      concepts {
        items {
          id
          wordId
          conceptId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sentences {
        items {
          id
          sentenceId
          wordId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Lists {
        items {
          id
          wordId
          listId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      wordData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listWords = /* GraphQL */ `
  query ListWords(
    $filter: ModelWordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWords(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        word
        isNonsenseWord
        concepts {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        wordData
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getConcept = /* GraphQL */ `
  query GetConcept($id: ID!) {
    getConcept(id: $id) {
      id
      concept
      subcategory
      category
      level
      definition
      trainingResources
      NewLessons {
        items {
          id
          date
          minutes
          rating
          studentID
          concepts
          lessonNumber
          lessonData
          comments
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Lists {
        items {
          id
          name
          conceptID
          listData
          studentID
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Passages {
        items {
          id
          title
          text
          wordCount
          author
          downvotes
          upvotes
          gptPrompt
          conceptID
          passageData
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      students {
        items {
          id
          studentId
          conceptId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Words {
        items {
          id
          wordId
          conceptId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      ReviewLessons {
        items {
          id
          lessonId
          conceptId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      sentences {
        items {
          id
          sentenceId
          conceptId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listConcepts = /* GraphQL */ `
  query ListConcepts(
    $filter: ModelConceptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConcepts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        concept
        subcategory
        category
        level
        definition
        trainingResources
        NewLessons {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        Passages {
          nextToken
          __typename
        }
        students {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        ReviewLessons {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getList = /* GraphQL */ `
  query GetList($id: ID!) {
    getList(id: $id) {
      id
      name
      conceptID
      words {
        items {
          id
          wordId
          listId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Lessons {
        items {
          id
          lessonId
          listId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      listData
      studentID
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const listsByConceptID = /* GraphQL */ `
  query ListsByConceptID(
    $conceptID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listsByConceptID(
      conceptID: $conceptID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        conceptID
        words {
          nextToken
          __typename
        }
        Lessons {
          nextToken
          __typename
        }
        listData
        studentID
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listsByStudentID = /* GraphQL */ `
  query ListsByStudentID(
    $studentID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listsByStudentID(
      studentID: $studentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        conceptID
        words {
          nextToken
          __typename
        }
        Lessons {
          nextToken
          __typename
        }
        listData
        studentID
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStudentConcept = /* GraphQL */ `
  query GetStudentConcept($id: ID!) {
    getStudentConcept(id: $id) {
      id
      studentId
      conceptId
      student {
        id
        lastName
        firstName
        dob
        comments
        customID
        studentData
        Lessons {
          nextToken
          __typename
        }
        Concepts {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        owner
        createdAt
        updatedAt
        __typename
      }
      concept {
        id
        concept
        subcategory
        category
        level
        definition
        trainingResources
        NewLessons {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        Passages {
          nextToken
          __typename
        }
        students {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        ReviewLessons {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listStudentConcepts = /* GraphQL */ `
  query ListStudentConcepts(
    $filter: ModelStudentConceptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudentConcepts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        studentId
        conceptId
        student {
          id
          lastName
          firstName
          dob
          comments
          customID
          studentData
          owner
          createdAt
          updatedAt
          __typename
        }
        concept {
          id
          concept
          subcategory
          category
          level
          definition
          trainingResources
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const studentConceptsByStudentId = /* GraphQL */ `
  query StudentConceptsByStudentId(
    $studentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelStudentConceptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studentConceptsByStudentId(
      studentId: $studentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studentId
        conceptId
        student {
          id
          lastName
          firstName
          dob
          comments
          customID
          studentData
          owner
          createdAt
          updatedAt
          __typename
        }
        concept {
          id
          concept
          subcategory
          category
          level
          definition
          trainingResources
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const studentConceptsByConceptId = /* GraphQL */ `
  query StudentConceptsByConceptId(
    $conceptId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelStudentConceptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studentConceptsByConceptId(
      conceptId: $conceptId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studentId
        conceptId
        student {
          id
          lastName
          firstName
          dob
          comments
          customID
          studentData
          owner
          createdAt
          updatedAt
          __typename
        }
        concept {
          id
          concept
          subcategory
          category
          level
          definition
          trainingResources
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPassageLesson = /* GraphQL */ `
  query GetPassageLesson($id: ID!) {
    getPassageLesson(id: $id) {
      id
      passageId
      lessonId
      passage {
        id
        title
        text
        wordCount
        author
        downvotes
        upvotes
        gptPrompt
        conceptID
        Lessons {
          nextToken
          __typename
        }
        passageData
        createdAt
        updatedAt
        __typename
      }
      lesson {
        id
        date
        minutes
        rating
        studentID
        conceptID {
          nextToken
          __typename
        }
        concepts
        passages {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        lists {
          nextToken
          __typename
        }
        lessonNumber
        lessonData
        comments
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listPassageLessons = /* GraphQL */ `
  query ListPassageLessons(
    $filter: ModelPassageLessonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPassageLessons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        passageId
        lessonId
        passage {
          id
          title
          text
          wordCount
          author
          downvotes
          upvotes
          gptPrompt
          conceptID
          passageData
          createdAt
          updatedAt
          __typename
        }
        lesson {
          id
          date
          minutes
          rating
          studentID
          concepts
          lessonNumber
          lessonData
          comments
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const passageLessonsByPassageId = /* GraphQL */ `
  query PassageLessonsByPassageId(
    $passageId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPassageLessonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    passageLessonsByPassageId(
      passageId: $passageId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        passageId
        lessonId
        passage {
          id
          title
          text
          wordCount
          author
          downvotes
          upvotes
          gptPrompt
          conceptID
          passageData
          createdAt
          updatedAt
          __typename
        }
        lesson {
          id
          date
          minutes
          rating
          studentID
          concepts
          lessonNumber
          lessonData
          comments
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const passageLessonsByLessonId = /* GraphQL */ `
  query PassageLessonsByLessonId(
    $lessonId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPassageLessonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    passageLessonsByLessonId(
      lessonId: $lessonId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        passageId
        lessonId
        passage {
          id
          title
          text
          wordCount
          author
          downvotes
          upvotes
          gptPrompt
          conceptID
          passageData
          createdAt
          updatedAt
          __typename
        }
        lesson {
          id
          date
          minutes
          rating
          studentID
          concepts
          lessonNumber
          lessonData
          comments
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSentenceLesson = /* GraphQL */ `
  query GetSentenceLesson($id: ID!) {
    getSentenceLesson(id: $id) {
      id
      sentenceId
      lessonId
      sentence {
        id
        text
        wordCount
        author
        upvotes
        downvotes
        gptPrompt
        Lessons {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        Concepts {
          nextToken
          __typename
        }
        sentenceData
        createdAt
        updatedAt
        __typename
      }
      lesson {
        id
        date
        minutes
        rating
        studentID
        conceptID {
          nextToken
          __typename
        }
        concepts
        passages {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        lists {
          nextToken
          __typename
        }
        lessonNumber
        lessonData
        comments
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listSentenceLessons = /* GraphQL */ `
  query ListSentenceLessons(
    $filter: ModelSentenceLessonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSentenceLessons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sentenceId
        lessonId
        sentence {
          id
          text
          wordCount
          author
          upvotes
          downvotes
          gptPrompt
          sentenceData
          createdAt
          updatedAt
          __typename
        }
        lesson {
          id
          date
          minutes
          rating
          studentID
          concepts
          lessonNumber
          lessonData
          comments
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const sentenceLessonsBySentenceId = /* GraphQL */ `
  query SentenceLessonsBySentenceId(
    $sentenceId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSentenceLessonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sentenceLessonsBySentenceId(
      sentenceId: $sentenceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sentenceId
        lessonId
        sentence {
          id
          text
          wordCount
          author
          upvotes
          downvotes
          gptPrompt
          sentenceData
          createdAt
          updatedAt
          __typename
        }
        lesson {
          id
          date
          minutes
          rating
          studentID
          concepts
          lessonNumber
          lessonData
          comments
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const sentenceLessonsByLessonId = /* GraphQL */ `
  query SentenceLessonsByLessonId(
    $lessonId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSentenceLessonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sentenceLessonsByLessonId(
      lessonId: $lessonId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sentenceId
        lessonId
        sentence {
          id
          text
          wordCount
          author
          upvotes
          downvotes
          gptPrompt
          sentenceData
          createdAt
          updatedAt
          __typename
        }
        lesson {
          id
          date
          minutes
          rating
          studentID
          concepts
          lessonNumber
          lessonData
          comments
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSentenceWord = /* GraphQL */ `
  query GetSentenceWord($id: ID!) {
    getSentenceWord(id: $id) {
      id
      sentenceId
      wordId
      sentence {
        id
        text
        wordCount
        author
        upvotes
        downvotes
        gptPrompt
        Lessons {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        Concepts {
          nextToken
          __typename
        }
        sentenceData
        createdAt
        updatedAt
        __typename
      }
      word {
        id
        word
        isNonsenseWord
        concepts {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        wordData
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSentenceWords = /* GraphQL */ `
  query ListSentenceWords(
    $filter: ModelSentenceWordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSentenceWords(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sentenceId
        wordId
        sentence {
          id
          text
          wordCount
          author
          upvotes
          downvotes
          gptPrompt
          sentenceData
          createdAt
          updatedAt
          __typename
        }
        word {
          id
          word
          isNonsenseWord
          wordData
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const sentenceWordsBySentenceId = /* GraphQL */ `
  query SentenceWordsBySentenceId(
    $sentenceId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSentenceWordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sentenceWordsBySentenceId(
      sentenceId: $sentenceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sentenceId
        wordId
        sentence {
          id
          text
          wordCount
          author
          upvotes
          downvotes
          gptPrompt
          sentenceData
          createdAt
          updatedAt
          __typename
        }
        word {
          id
          word
          isNonsenseWord
          wordData
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const sentenceWordsByWordId = /* GraphQL */ `
  query SentenceWordsByWordId(
    $wordId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSentenceWordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sentenceWordsByWordId(
      wordId: $wordId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sentenceId
        wordId
        sentence {
          id
          text
          wordCount
          author
          upvotes
          downvotes
          gptPrompt
          sentenceData
          createdAt
          updatedAt
          __typename
        }
        word {
          id
          word
          isNonsenseWord
          wordData
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSentenceConcept = /* GraphQL */ `
  query GetSentenceConcept($id: ID!) {
    getSentenceConcept(id: $id) {
      id
      sentenceId
      conceptId
      sentence {
        id
        text
        wordCount
        author
        upvotes
        downvotes
        gptPrompt
        Lessons {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        Concepts {
          nextToken
          __typename
        }
        sentenceData
        createdAt
        updatedAt
        __typename
      }
      concept {
        id
        concept
        subcategory
        category
        level
        definition
        trainingResources
        NewLessons {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        Passages {
          nextToken
          __typename
        }
        students {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        ReviewLessons {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSentenceConcepts = /* GraphQL */ `
  query ListSentenceConcepts(
    $filter: ModelSentenceConceptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSentenceConcepts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sentenceId
        conceptId
        sentence {
          id
          text
          wordCount
          author
          upvotes
          downvotes
          gptPrompt
          sentenceData
          createdAt
          updatedAt
          __typename
        }
        concept {
          id
          concept
          subcategory
          category
          level
          definition
          trainingResources
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const sentenceConceptsBySentenceId = /* GraphQL */ `
  query SentenceConceptsBySentenceId(
    $sentenceId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSentenceConceptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sentenceConceptsBySentenceId(
      sentenceId: $sentenceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sentenceId
        conceptId
        sentence {
          id
          text
          wordCount
          author
          upvotes
          downvotes
          gptPrompt
          sentenceData
          createdAt
          updatedAt
          __typename
        }
        concept {
          id
          concept
          subcategory
          category
          level
          definition
          trainingResources
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const sentenceConceptsByConceptId = /* GraphQL */ `
  query SentenceConceptsByConceptId(
    $conceptId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSentenceConceptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sentenceConceptsByConceptId(
      conceptId: $conceptId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sentenceId
        conceptId
        sentence {
          id
          text
          wordCount
          author
          upvotes
          downvotes
          gptPrompt
          sentenceData
          createdAt
          updatedAt
          __typename
        }
        concept {
          id
          concept
          subcategory
          category
          level
          definition
          trainingResources
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getConceptLesson = /* GraphQL */ `
  query GetConceptLesson($id: ID!) {
    getConceptLesson(id: $id) {
      id
      lessonId
      conceptId
      lesson {
        id
        date
        minutes
        rating
        studentID
        conceptID {
          nextToken
          __typename
        }
        concepts
        passages {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        lists {
          nextToken
          __typename
        }
        lessonNumber
        lessonData
        comments
        createdAt
        updatedAt
        owner
        __typename
      }
      concept {
        id
        concept
        subcategory
        category
        level
        definition
        trainingResources
        NewLessons {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        Passages {
          nextToken
          __typename
        }
        students {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        ReviewLessons {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listConceptLessons = /* GraphQL */ `
  query ListConceptLessons(
    $filter: ModelConceptLessonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConceptLessons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        lessonId
        conceptId
        lesson {
          id
          date
          minutes
          rating
          studentID
          concepts
          lessonNumber
          lessonData
          comments
          createdAt
          updatedAt
          owner
          __typename
        }
        concept {
          id
          concept
          subcategory
          category
          level
          definition
          trainingResources
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const conceptLessonsByLessonId = /* GraphQL */ `
  query ConceptLessonsByLessonId(
    $lessonId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelConceptLessonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    conceptLessonsByLessonId(
      lessonId: $lessonId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lessonId
        conceptId
        lesson {
          id
          date
          minutes
          rating
          studentID
          concepts
          lessonNumber
          lessonData
          comments
          createdAt
          updatedAt
          owner
          __typename
        }
        concept {
          id
          concept
          subcategory
          category
          level
          definition
          trainingResources
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const conceptLessonsByConceptId = /* GraphQL */ `
  query ConceptLessonsByConceptId(
    $conceptId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelConceptLessonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    conceptLessonsByConceptId(
      conceptId: $conceptId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lessonId
        conceptId
        lesson {
          id
          date
          minutes
          rating
          studentID
          concepts
          lessonNumber
          lessonData
          comments
          createdAt
          updatedAt
          owner
          __typename
        }
        concept {
          id
          concept
          subcategory
          category
          level
          definition
          trainingResources
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getListLesson = /* GraphQL */ `
  query GetListLesson($id: ID!) {
    getListLesson(id: $id) {
      id
      lessonId
      listId
      lesson {
        id
        date
        minutes
        rating
        studentID
        conceptID {
          nextToken
          __typename
        }
        concepts
        passages {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        lists {
          nextToken
          __typename
        }
        lessonNumber
        lessonData
        comments
        createdAt
        updatedAt
        owner
        __typename
      }
      list {
        id
        name
        conceptID
        words {
          nextToken
          __typename
        }
        Lessons {
          nextToken
          __typename
        }
        listData
        studentID
        owner
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listListLessons = /* GraphQL */ `
  query ListListLessons(
    $filter: ModelListLessonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listListLessons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        lessonId
        listId
        lesson {
          id
          date
          minutes
          rating
          studentID
          concepts
          lessonNumber
          lessonData
          comments
          createdAt
          updatedAt
          owner
          __typename
        }
        list {
          id
          name
          conceptID
          listData
          studentID
          owner
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listLessonsByLessonId = /* GraphQL */ `
  query ListLessonsByLessonId(
    $lessonId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelListLessonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLessonsByLessonId(
      lessonId: $lessonId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lessonId
        listId
        lesson {
          id
          date
          minutes
          rating
          studentID
          concepts
          lessonNumber
          lessonData
          comments
          createdAt
          updatedAt
          owner
          __typename
        }
        list {
          id
          name
          conceptID
          listData
          studentID
          owner
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listLessonsByListId = /* GraphQL */ `
  query ListLessonsByListId(
    $listId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelListLessonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLessonsByListId(
      listId: $listId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lessonId
        listId
        lesson {
          id
          date
          minutes
          rating
          studentID
          concepts
          lessonNumber
          lessonData
          comments
          createdAt
          updatedAt
          owner
          __typename
        }
        list {
          id
          name
          conceptID
          listData
          studentID
          owner
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getConceptWord = /* GraphQL */ `
  query GetConceptWord($id: ID!) {
    getConceptWord(id: $id) {
      id
      wordId
      conceptId
      word {
        id
        word
        isNonsenseWord
        concepts {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        wordData
        createdAt
        updatedAt
        __typename
      }
      concept {
        id
        concept
        subcategory
        category
        level
        definition
        trainingResources
        NewLessons {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        Passages {
          nextToken
          __typename
        }
        students {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        ReviewLessons {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listConceptWords = /* GraphQL */ `
  query ListConceptWords(
    $filter: ModelConceptWordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConceptWords(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        wordId
        conceptId
        word {
          id
          word
          isNonsenseWord
          wordData
          createdAt
          updatedAt
          __typename
        }
        concept {
          id
          concept
          subcategory
          category
          level
          definition
          trainingResources
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const conceptWordsByWordId = /* GraphQL */ `
  query ConceptWordsByWordId(
    $wordId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelConceptWordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    conceptWordsByWordId(
      wordId: $wordId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        wordId
        conceptId
        word {
          id
          word
          isNonsenseWord
          wordData
          createdAt
          updatedAt
          __typename
        }
        concept {
          id
          concept
          subcategory
          category
          level
          definition
          trainingResources
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const conceptWordsByConceptId = /* GraphQL */ `
  query ConceptWordsByConceptId(
    $conceptId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelConceptWordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    conceptWordsByConceptId(
      conceptId: $conceptId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        wordId
        conceptId
        word {
          id
          word
          isNonsenseWord
          wordData
          createdAt
          updatedAt
          __typename
        }
        concept {
          id
          concept
          subcategory
          category
          level
          definition
          trainingResources
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWordList = /* GraphQL */ `
  query GetWordList($id: ID!) {
    getWordList(id: $id) {
      id
      wordId
      listId
      word {
        id
        word
        isNonsenseWord
        concepts {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        wordData
        createdAt
        updatedAt
        __typename
      }
      list {
        id
        name
        conceptID
        words {
          nextToken
          __typename
        }
        Lessons {
          nextToken
          __typename
        }
        listData
        studentID
        owner
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listWordLists = /* GraphQL */ `
  query ListWordLists(
    $filter: ModelWordListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWordLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        wordId
        listId
        word {
          id
          word
          isNonsenseWord
          wordData
          createdAt
          updatedAt
          __typename
        }
        list {
          id
          name
          conceptID
          listData
          studentID
          owner
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const wordListsByWordId = /* GraphQL */ `
  query WordListsByWordId(
    $wordId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelWordListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    wordListsByWordId(
      wordId: $wordId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        wordId
        listId
        word {
          id
          word
          isNonsenseWord
          wordData
          createdAt
          updatedAt
          __typename
        }
        list {
          id
          name
          conceptID
          listData
          studentID
          owner
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const wordListsByListId = /* GraphQL */ `
  query WordListsByListId(
    $listId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelWordListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    wordListsByListId(
      listId: $listId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        wordId
        listId
        word {
          id
          word
          isNonsenseWord
          wordData
          createdAt
          updatedAt
          __typename
        }
        list {
          id
          name
          conceptID
          listData
          studentID
          owner
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
