import PropTypes from 'prop-types';
import IconButton from '@mui/joy/IconButton';
import Table from '@mui/joy/Table';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import React, { useState } from 'react';
import { Chip } from '@mui/joy';
import Link from '@mui/joy/Link';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Box from '@mui/joy/Box';
import { visuallyHidden } from '@mui/utils';
import { useConceptStore } from '../zustand/store';
import Tooltip from '@mui/joy/Tooltip';
import ConceptModal from './modals/ConceptDetailModal'; // Import the modal component
import { useListStore } from '../zustand/store';
////  Create Data //////////////////////////////////////////////////////////////////////////////////////////
function createData(id, conceptCount, listCount, wordCount, conceptArray) {
  return {
    id,
    conceptCount,
    listCount,
    wordCount,
    conceptArray,
  };
}
////   Rows function //////////////////////////////////////////////////////////////////////////////////////////
function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(props.initialOpen || false);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('subcategory');
  const setSelectedConcept = useConceptStore(
    (state) => state.setSelectedConcept
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const knownConcepts = useListStore((state) => state.knownConcepts);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleLinkClick = (concept) => {

    setSelectedConcept(''); // Clear selectedConcept when the Link is clicked
    setSelectedConcept(concept); // Update selectedConcept when the Link is clicked

    props.resetWordList()

    // Scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional: define smooth scroll
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const headCells = [
    {
      id: 'concept',
      numeric: false,
      disablePadding: false,
      label: 'Concept',
    },
    {
      id: 'subcategory',
      numeric: false,
      disablePadding: false,
      label: 'Subcategory',
    },
    {
      id: 'review',
      numeric: false,
      disablePadding: false,
      label: 'Review',
    },
  ];
  function EnhancedTableHead(props) {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      onRequestSort,
    } = props;

    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <thead>
        <tr>
          {headCells.map((headCell) => {
            const active = orderBy === headCell.id;
            let width = ''; // Initialize an empty width

            // Set the width based on the column
            switch (headCell.id) {
              case 'concept':
                width = '40%';
                break;
              case 'subcategory':
                width = '40%';
                break;
              case 'review':
                width = '20%';
                break;
              default:
                break;
            }

            return (
              <th
                key={headCell.id}
                style={{ width: width }} // Apply the width here
                aria-sort={
                  active
                    ? { asc: 'ascending', desc: 'descending' }[order]
                    : undefined
                }
              >
                <Link
                  underline='none'
                  color='neutral'
                  textColor={active ? 'primary.plainColor' : undefined}
                  component='button'
                  onClick={createSortHandler(headCell.id)}
                  fontWeight='lg'
                  startDecorator={
                    headCell.numeric ? (
                      <ArrowDownwardIcon sx={{ opacity: active ? 1 : 0 }} />
                    ) : null
                  }
                  endDecorator={
                    !headCell.numeric ? (
                      <ArrowDownwardIcon sx={{ opacity: active ? 1 : 0 }} />
                    ) : null
                  }
                  sx={{
                    '& svg': {
                      transition: '0.2s',
                      transform:
                        active && order === 'desc'
                          ? 'rotate(0deg)'
                          : 'rotate(180deg)',
                    },
                    '&:hover': { '& svg': { opacity: 1 } },
                  }}
                >
                  {headCell.label}
                  {active ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </Box>
                  ) : null}
                </Link>
              </th>
            );
          })}
        </tr>
      </thead>
    );
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,

    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  return (
    <React.Fragment>
      <tr onClick={() => setOpen(!open)} aria-label='expand row'>
        <td>
          <IconButton
            aria-label='expand row'
            variant='plain'
            color='neutral'
            size='sm'
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </td>
        <th scope='row'>{row.id}</th>
        <td>{row.conceptCount}</td>
        <td>{row.listCount}</td>
        <td>{row.wordCount}</td>
      </tr>
      <tr>
        <td style={{ height: 0, padding: 0 }} colSpan={6}>
          {open && (
            <Sheet
              variant='soft'
              sx={{
                p: 1,
                pl: 6,
                boxShadow: 'inset 0 3px 6px 0 rgba(0 0 0 / 0.08)',
              }}
            >
              <Table
                borderAxis='bothBetween'
                size='sm'
                stripe={'odd'}
                aria-label='purchases'
                hoverRow='true'
                sx={{
                  '& > thead > tr > th:nth-child(n + 3), & > tbody > tr > td:nth-child(n + 3)':
                    { textAlign: 'right' },
                  '--TableCell-paddingX': '0.5rem',
                }}
              >
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <tbody>
                  {stableSort(
                    row.conceptArray,
                    getComparator(order, orderBy)
                  ).map((row, index) => {
                    return (
                      <tr key={index} onClick={() => handleLinkClick(row)}>
                        <td>
                          <Link size='sm'>
                            {row.concept}
                            {/* {console.log('Row:', row)} */}
                          </Link>
                        </td>
                        <td>{row.subcategory}</td>
                        <td>
                          <Tooltip
                            title={
                              knownConcepts.some(
                                (concept) => concept.conceptID === row.id
                              )
                                ? 'previously seen by student'
                                : 'unknown to student'
                            }
                          >
                            <Chip
                              size='s'
                              variant={
                                knownConcepts.some(
                                  (concept) => concept.conceptID === row.id
                                )
                                  ? 'outlined'
                                  : 'solid'
                              }
                              color={
                                knownConcepts.some(
                                  (concept) => concept.conceptID === row.id
                                )
                                  ? 'neutral'
                                  : 'success'
                              }
                            >
                              {
                                // Determine the label of the chip based on knownConcepts
                                (() => {
                                  const foundConcept = knownConcepts.find(
                                    (concept) => concept.conceptID === row.id
                                  );

                                  if (foundConcept) {
                                    return `Review (${foundConcept.count})`; // If found, show "Known" with the count
                                  } else {
                                    return '  New  '; // If not found, show "Unknown"
                                  }
                                })()
                              }
                            </Chip>
                          </Tooltip>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Sheet>
          )}
        </td>
      </tr>
      <ConceptModal
        open={isModalOpen}
        onClose={closeModal}
        concept={row.concept}
      />
    </React.Fragment>
  );
}

////  Main function //////////////////////////////////////////////////////////////////////////////////////////
export default function TableCollapsibleRow(props) {
  const concepts = props.concepts; // Correct way to access the concepts prop

  // Filter and count concepts for each category
  const spellingGeneralizationsConcepts = concepts.filter(
    (concept) => concept.category === 'SPELLING GENERALIZATIONS'
  );
  const spellingGeneralizationsCount = spellingGeneralizationsConcepts.length;

  const syllableTypesConcepts = concepts.filter(
    (concept) => concept.category === 'SYLLABLE TYPES'
  );
  const syllableTypesCount = syllableTypesConcepts.length;

  const morphemesConcepts = concepts.filter(
    (concept) => concept.category === 'MORPHEMES'
  );
  const morphemesCount = morphemesConcepts.length;

  const phonogramsConcepts = concepts.filter(
    (concept) => concept.category === 'PHONOGRAMS'
  );
  const phonogramsCount = phonogramsConcepts.length;

  const syllableDivisionPatternsConcepts = concepts.filter(
    (concept) => concept.category === 'SYLLABLE DIVISION PATTERNS'
  );
  const syllableDivisionPatternsCount = syllableDivisionPatternsConcepts.length;

  const learnedWordsConcepts = concepts.filter(
    (concept) => concept.category === 'LEARNED WORDS/ RED WORDS'
  );
  const learnedWordsConceptsCount = learnedWordsConcepts.length;

  // Rows constant
  const rows = [
    createData(
      'Spelling Generalizations',
      0,
      0,
      spellingGeneralizationsCount,
      spellingGeneralizationsConcepts
    ),
    createData(
      'Syllable Types',
      0,
      0,
      syllableTypesCount,
      syllableTypesConcepts
    ),
    createData('Morphemes', 0, 0, morphemesCount, morphemesConcepts),
    createData('Phonograms', 0, 0, phonogramsCount, phonogramsConcepts),
    createData(
      'Syllable Division Patterns',
      0,
      0,
      syllableDivisionPatternsCount,
      syllableDivisionPatternsConcepts
    ),
    createData(
      'Learned Words/ Red Words',
      0,
      0,
      learnedWordsConceptsCount,
      learnedWordsConcepts
    ),
  ];

  return (
    <Sheet>
      {/* {console.log('Props received:', concepts)} */}
      <Table
        aria-label='collapsible table'
        sx={{
          '& > thead > tr > th:nth-child(n + 3), & > tbody > tr > td:nth-child(n + 3)':
            { textAlign: 'right' },
          '& > tbody > tr:nth-child(odd) > td, & > tbody > tr:nth-child(odd) > th[scope="row"]':
            {
              borderBottom: 0,
            },
        }}
      >
        <thead>
          <tr>
            <th style={{ width: 40 }} aria-label='empty' />
            <th style={{ width: '40%' }}></th>
            <th style={{ width: '15%' }}>Known</th>
            <th style={{ width: '15%' }}>%</th>
            <th style={{ width: '30%' }}>Concepts ({concepts.length})</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <Row key={row.id} row={row} resetWordList={props.resetWordList} />
          ))}
        </tbody>
      </Table>
    </Sheet>
  );
}
