/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createGenerateDocument = /* GraphQL */ `
  mutation CreateGenerateDocument(
    $input: CreateGenerateDocumentInput!
    $condition: ModelGenerateDocumentConditionInput
  ) {
    createGenerateDocument(input: $input, condition: $condition) {
      id
      listObject
      docParameters
      downloadDocumentLink
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateGenerateDocument = /* GraphQL */ `
  mutation UpdateGenerateDocument(
    $input: UpdateGenerateDocumentInput!
    $condition: ModelGenerateDocumentConditionInput
  ) {
    updateGenerateDocument(input: $input, condition: $condition) {
      id
      listObject
      docParameters
      downloadDocumentLink
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteGenerateDocument = /* GraphQL */ `
  mutation DeleteGenerateDocument(
    $input: DeleteGenerateDocumentInput!
    $condition: ModelGenerateDocumentConditionInput
  ) {
    deleteGenerateDocument(input: $input, condition: $condition) {
      id
      listObject
      docParameters
      downloadDocumentLink
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createStudent = /* GraphQL */ `
  mutation CreateStudent(
    $input: CreateStudentInput!
    $condition: ModelStudentConditionInput
  ) {
    createStudent(input: $input, condition: $condition) {
      id
      lastName
      firstName
      dob
      comments
      customID
      studentData
      Lessons {
        items {
          id
          date
          minutes
          rating
          studentID
          concepts
          lessonNumber
          lessonData
          comments
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Concepts {
        items {
          id
          studentId
          conceptId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Lists {
        items {
          id
          name
          conceptID
          listData
          studentID
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateStudent = /* GraphQL */ `
  mutation UpdateStudent(
    $input: UpdateStudentInput!
    $condition: ModelStudentConditionInput
  ) {
    updateStudent(input: $input, condition: $condition) {
      id
      lastName
      firstName
      dob
      comments
      customID
      studentData
      Lessons {
        items {
          id
          date
          minutes
          rating
          studentID
          concepts
          lessonNumber
          lessonData
          comments
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Concepts {
        items {
          id
          studentId
          conceptId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Lists {
        items {
          id
          name
          conceptID
          listData
          studentID
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteStudent = /* GraphQL */ `
  mutation DeleteStudent(
    $input: DeleteStudentInput!
    $condition: ModelStudentConditionInput
  ) {
    deleteStudent(input: $input, condition: $condition) {
      id
      lastName
      firstName
      dob
      comments
      customID
      studentData
      Lessons {
        items {
          id
          date
          minutes
          rating
          studentID
          concepts
          lessonNumber
          lessonData
          comments
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Concepts {
        items {
          id
          studentId
          conceptId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Lists {
        items {
          id
          name
          conceptID
          listData
          studentID
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPassage = /* GraphQL */ `
  mutation CreatePassage(
    $input: CreatePassageInput!
    $condition: ModelPassageConditionInput
  ) {
    createPassage(input: $input, condition: $condition) {
      id
      title
      text
      wordCount
      author
      downvotes
      upvotes
      gptPrompt
      conceptID
      Lessons {
        items {
          id
          passageId
          lessonId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      passageData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePassage = /* GraphQL */ `
  mutation UpdatePassage(
    $input: UpdatePassageInput!
    $condition: ModelPassageConditionInput
  ) {
    updatePassage(input: $input, condition: $condition) {
      id
      title
      text
      wordCount
      author
      downvotes
      upvotes
      gptPrompt
      conceptID
      Lessons {
        items {
          id
          passageId
          lessonId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      passageData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePassage = /* GraphQL */ `
  mutation DeletePassage(
    $input: DeletePassageInput!
    $condition: ModelPassageConditionInput
  ) {
    deletePassage(input: $input, condition: $condition) {
      id
      title
      text
      wordCount
      author
      downvotes
      upvotes
      gptPrompt
      conceptID
      Lessons {
        items {
          id
          passageId
          lessonId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      passageData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSentence = /* GraphQL */ `
  mutation CreateSentence(
    $input: CreateSentenceInput!
    $condition: ModelSentenceConditionInput
  ) {
    createSentence(input: $input, condition: $condition) {
      id
      text
      wordCount
      author
      upvotes
      downvotes
      gptPrompt
      Lessons {
        items {
          id
          sentenceId
          lessonId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Words {
        items {
          id
          sentenceId
          wordId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Concepts {
        items {
          id
          sentenceId
          conceptId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sentenceData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSentence = /* GraphQL */ `
  mutation UpdateSentence(
    $input: UpdateSentenceInput!
    $condition: ModelSentenceConditionInput
  ) {
    updateSentence(input: $input, condition: $condition) {
      id
      text
      wordCount
      author
      upvotes
      downvotes
      gptPrompt
      Lessons {
        items {
          id
          sentenceId
          lessonId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Words {
        items {
          id
          sentenceId
          wordId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Concepts {
        items {
          id
          sentenceId
          conceptId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sentenceData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSentence = /* GraphQL */ `
  mutation DeleteSentence(
    $input: DeleteSentenceInput!
    $condition: ModelSentenceConditionInput
  ) {
    deleteSentence(input: $input, condition: $condition) {
      id
      text
      wordCount
      author
      upvotes
      downvotes
      gptPrompt
      Lessons {
        items {
          id
          sentenceId
          lessonId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Words {
        items {
          id
          sentenceId
          wordId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Concepts {
        items {
          id
          sentenceId
          conceptId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sentenceData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLesson = /* GraphQL */ `
  mutation CreateLesson(
    $input: CreateLessonInput!
    $condition: ModelLessonConditionInput
  ) {
    createLesson(input: $input, condition: $condition) {
      id
      date
      minutes
      rating
      studentID
      conceptID {
        items {
          id
          lessonId
          conceptId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      concepts
      passages {
        items {
          id
          passageId
          lessonId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      sentences {
        items {
          id
          sentenceId
          lessonId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      lists {
        items {
          id
          lessonId
          listId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      lessonNumber
      lessonData
      comments
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateLesson = /* GraphQL */ `
  mutation UpdateLesson(
    $input: UpdateLessonInput!
    $condition: ModelLessonConditionInput
  ) {
    updateLesson(input: $input, condition: $condition) {
      id
      date
      minutes
      rating
      studentID
      conceptID {
        items {
          id
          lessonId
          conceptId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      concepts
      passages {
        items {
          id
          passageId
          lessonId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      sentences {
        items {
          id
          sentenceId
          lessonId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      lists {
        items {
          id
          lessonId
          listId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      lessonNumber
      lessonData
      comments
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteLesson = /* GraphQL */ `
  mutation DeleteLesson(
    $input: DeleteLessonInput!
    $condition: ModelLessonConditionInput
  ) {
    deleteLesson(input: $input, condition: $condition) {
      id
      date
      minutes
      rating
      studentID
      conceptID {
        items {
          id
          lessonId
          conceptId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      concepts
      passages {
        items {
          id
          passageId
          lessonId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      sentences {
        items {
          id
          sentenceId
          lessonId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      lists {
        items {
          id
          lessonId
          listId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      lessonNumber
      lessonData
      comments
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createWord = /* GraphQL */ `
  mutation CreateWord(
    $input: CreateWordInput!
    $condition: ModelWordConditionInput
  ) {
    createWord(input: $input, condition: $condition) {
      id
      word
      isNonsenseWord
      concepts {
        items {
          id
          wordId
          conceptId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sentences {
        items {
          id
          sentenceId
          wordId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Lists {
        items {
          id
          wordId
          listId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      wordData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateWord = /* GraphQL */ `
  mutation UpdateWord(
    $input: UpdateWordInput!
    $condition: ModelWordConditionInput
  ) {
    updateWord(input: $input, condition: $condition) {
      id
      word
      isNonsenseWord
      concepts {
        items {
          id
          wordId
          conceptId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sentences {
        items {
          id
          sentenceId
          wordId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Lists {
        items {
          id
          wordId
          listId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      wordData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteWord = /* GraphQL */ `
  mutation DeleteWord(
    $input: DeleteWordInput!
    $condition: ModelWordConditionInput
  ) {
    deleteWord(input: $input, condition: $condition) {
      id
      word
      isNonsenseWord
      concepts {
        items {
          id
          wordId
          conceptId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sentences {
        items {
          id
          sentenceId
          wordId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Lists {
        items {
          id
          wordId
          listId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      wordData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createConcept = /* GraphQL */ `
  mutation CreateConcept(
    $input: CreateConceptInput!
    $condition: ModelConceptConditionInput
  ) {
    createConcept(input: $input, condition: $condition) {
      id
      concept
      subcategory
      category
      level
      definition
      trainingResources
      NewLessons {
        items {
          id
          date
          minutes
          rating
          studentID
          concepts
          lessonNumber
          lessonData
          comments
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Lists {
        items {
          id
          name
          conceptID
          listData
          studentID
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Passages {
        items {
          id
          title
          text
          wordCount
          author
          downvotes
          upvotes
          gptPrompt
          conceptID
          passageData
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      students {
        items {
          id
          studentId
          conceptId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Words {
        items {
          id
          wordId
          conceptId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      ReviewLessons {
        items {
          id
          lessonId
          conceptId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      sentences {
        items {
          id
          sentenceId
          conceptId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateConcept = /* GraphQL */ `
  mutation UpdateConcept(
    $input: UpdateConceptInput!
    $condition: ModelConceptConditionInput
  ) {
    updateConcept(input: $input, condition: $condition) {
      id
      concept
      subcategory
      category
      level
      definition
      trainingResources
      NewLessons {
        items {
          id
          date
          minutes
          rating
          studentID
          concepts
          lessonNumber
          lessonData
          comments
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Lists {
        items {
          id
          name
          conceptID
          listData
          studentID
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Passages {
        items {
          id
          title
          text
          wordCount
          author
          downvotes
          upvotes
          gptPrompt
          conceptID
          passageData
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      students {
        items {
          id
          studentId
          conceptId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Words {
        items {
          id
          wordId
          conceptId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      ReviewLessons {
        items {
          id
          lessonId
          conceptId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      sentences {
        items {
          id
          sentenceId
          conceptId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteConcept = /* GraphQL */ `
  mutation DeleteConcept(
    $input: DeleteConceptInput!
    $condition: ModelConceptConditionInput
  ) {
    deleteConcept(input: $input, condition: $condition) {
      id
      concept
      subcategory
      category
      level
      definition
      trainingResources
      NewLessons {
        items {
          id
          date
          minutes
          rating
          studentID
          concepts
          lessonNumber
          lessonData
          comments
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Lists {
        items {
          id
          name
          conceptID
          listData
          studentID
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Passages {
        items {
          id
          title
          text
          wordCount
          author
          downvotes
          upvotes
          gptPrompt
          conceptID
          passageData
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      students {
        items {
          id
          studentId
          conceptId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Words {
        items {
          id
          wordId
          conceptId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      ReviewLessons {
        items {
          id
          lessonId
          conceptId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      sentences {
        items {
          id
          sentenceId
          conceptId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createList = /* GraphQL */ `
  mutation CreateList(
    $input: CreateListInput!
    $condition: ModelListConditionInput
  ) {
    createList(input: $input, condition: $condition) {
      id
      name
      conceptID
      words {
        items {
          id
          wordId
          listId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Lessons {
        items {
          id
          lessonId
          listId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      listData
      studentID
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateList = /* GraphQL */ `
  mutation UpdateList(
    $input: UpdateListInput!
    $condition: ModelListConditionInput
  ) {
    updateList(input: $input, condition: $condition) {
      id
      name
      conceptID
      words {
        items {
          id
          wordId
          listId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Lessons {
        items {
          id
          lessonId
          listId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      listData
      studentID
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteList = /* GraphQL */ `
  mutation DeleteList(
    $input: DeleteListInput!
    $condition: ModelListConditionInput
  ) {
    deleteList(input: $input, condition: $condition) {
      id
      name
      conceptID
      words {
        items {
          id
          wordId
          listId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Lessons {
        items {
          id
          lessonId
          listId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      listData
      studentID
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createStudentConcept = /* GraphQL */ `
  mutation CreateStudentConcept(
    $input: CreateStudentConceptInput!
    $condition: ModelStudentConceptConditionInput
  ) {
    createStudentConcept(input: $input, condition: $condition) {
      id
      studentId
      conceptId
      student {
        id
        lastName
        firstName
        dob
        comments
        customID
        studentData
        Lessons {
          nextToken
          __typename
        }
        Concepts {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        owner
        createdAt
        updatedAt
        __typename
      }
      concept {
        id
        concept
        subcategory
        category
        level
        definition
        trainingResources
        NewLessons {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        Passages {
          nextToken
          __typename
        }
        students {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        ReviewLessons {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateStudentConcept = /* GraphQL */ `
  mutation UpdateStudentConcept(
    $input: UpdateStudentConceptInput!
    $condition: ModelStudentConceptConditionInput
  ) {
    updateStudentConcept(input: $input, condition: $condition) {
      id
      studentId
      conceptId
      student {
        id
        lastName
        firstName
        dob
        comments
        customID
        studentData
        Lessons {
          nextToken
          __typename
        }
        Concepts {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        owner
        createdAt
        updatedAt
        __typename
      }
      concept {
        id
        concept
        subcategory
        category
        level
        definition
        trainingResources
        NewLessons {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        Passages {
          nextToken
          __typename
        }
        students {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        ReviewLessons {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteStudentConcept = /* GraphQL */ `
  mutation DeleteStudentConcept(
    $input: DeleteStudentConceptInput!
    $condition: ModelStudentConceptConditionInput
  ) {
    deleteStudentConcept(input: $input, condition: $condition) {
      id
      studentId
      conceptId
      student {
        id
        lastName
        firstName
        dob
        comments
        customID
        studentData
        Lessons {
          nextToken
          __typename
        }
        Concepts {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        owner
        createdAt
        updatedAt
        __typename
      }
      concept {
        id
        concept
        subcategory
        category
        level
        definition
        trainingResources
        NewLessons {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        Passages {
          nextToken
          __typename
        }
        students {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        ReviewLessons {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createPassageLesson = /* GraphQL */ `
  mutation CreatePassageLesson(
    $input: CreatePassageLessonInput!
    $condition: ModelPassageLessonConditionInput
  ) {
    createPassageLesson(input: $input, condition: $condition) {
      id
      passageId
      lessonId
      passage {
        id
        title
        text
        wordCount
        author
        downvotes
        upvotes
        gptPrompt
        conceptID
        Lessons {
          nextToken
          __typename
        }
        passageData
        createdAt
        updatedAt
        __typename
      }
      lesson {
        id
        date
        minutes
        rating
        studentID
        conceptID {
          nextToken
          __typename
        }
        concepts
        passages {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        lists {
          nextToken
          __typename
        }
        lessonNumber
        lessonData
        comments
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updatePassageLesson = /* GraphQL */ `
  mutation UpdatePassageLesson(
    $input: UpdatePassageLessonInput!
    $condition: ModelPassageLessonConditionInput
  ) {
    updatePassageLesson(input: $input, condition: $condition) {
      id
      passageId
      lessonId
      passage {
        id
        title
        text
        wordCount
        author
        downvotes
        upvotes
        gptPrompt
        conceptID
        Lessons {
          nextToken
          __typename
        }
        passageData
        createdAt
        updatedAt
        __typename
      }
      lesson {
        id
        date
        minutes
        rating
        studentID
        conceptID {
          nextToken
          __typename
        }
        concepts
        passages {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        lists {
          nextToken
          __typename
        }
        lessonNumber
        lessonData
        comments
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deletePassageLesson = /* GraphQL */ `
  mutation DeletePassageLesson(
    $input: DeletePassageLessonInput!
    $condition: ModelPassageLessonConditionInput
  ) {
    deletePassageLesson(input: $input, condition: $condition) {
      id
      passageId
      lessonId
      passage {
        id
        title
        text
        wordCount
        author
        downvotes
        upvotes
        gptPrompt
        conceptID
        Lessons {
          nextToken
          __typename
        }
        passageData
        createdAt
        updatedAt
        __typename
      }
      lesson {
        id
        date
        minutes
        rating
        studentID
        conceptID {
          nextToken
          __typename
        }
        concepts
        passages {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        lists {
          nextToken
          __typename
        }
        lessonNumber
        lessonData
        comments
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createSentenceLesson = /* GraphQL */ `
  mutation CreateSentenceLesson(
    $input: CreateSentenceLessonInput!
    $condition: ModelSentenceLessonConditionInput
  ) {
    createSentenceLesson(input: $input, condition: $condition) {
      id
      sentenceId
      lessonId
      sentence {
        id
        text
        wordCount
        author
        upvotes
        downvotes
        gptPrompt
        Lessons {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        Concepts {
          nextToken
          __typename
        }
        sentenceData
        createdAt
        updatedAt
        __typename
      }
      lesson {
        id
        date
        minutes
        rating
        studentID
        conceptID {
          nextToken
          __typename
        }
        concepts
        passages {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        lists {
          nextToken
          __typename
        }
        lessonNumber
        lessonData
        comments
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateSentenceLesson = /* GraphQL */ `
  mutation UpdateSentenceLesson(
    $input: UpdateSentenceLessonInput!
    $condition: ModelSentenceLessonConditionInput
  ) {
    updateSentenceLesson(input: $input, condition: $condition) {
      id
      sentenceId
      lessonId
      sentence {
        id
        text
        wordCount
        author
        upvotes
        downvotes
        gptPrompt
        Lessons {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        Concepts {
          nextToken
          __typename
        }
        sentenceData
        createdAt
        updatedAt
        __typename
      }
      lesson {
        id
        date
        minutes
        rating
        studentID
        conceptID {
          nextToken
          __typename
        }
        concepts
        passages {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        lists {
          nextToken
          __typename
        }
        lessonNumber
        lessonData
        comments
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteSentenceLesson = /* GraphQL */ `
  mutation DeleteSentenceLesson(
    $input: DeleteSentenceLessonInput!
    $condition: ModelSentenceLessonConditionInput
  ) {
    deleteSentenceLesson(input: $input, condition: $condition) {
      id
      sentenceId
      lessonId
      sentence {
        id
        text
        wordCount
        author
        upvotes
        downvotes
        gptPrompt
        Lessons {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        Concepts {
          nextToken
          __typename
        }
        sentenceData
        createdAt
        updatedAt
        __typename
      }
      lesson {
        id
        date
        minutes
        rating
        studentID
        conceptID {
          nextToken
          __typename
        }
        concepts
        passages {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        lists {
          nextToken
          __typename
        }
        lessonNumber
        lessonData
        comments
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createSentenceWord = /* GraphQL */ `
  mutation CreateSentenceWord(
    $input: CreateSentenceWordInput!
    $condition: ModelSentenceWordConditionInput
  ) {
    createSentenceWord(input: $input, condition: $condition) {
      id
      sentenceId
      wordId
      sentence {
        id
        text
        wordCount
        author
        upvotes
        downvotes
        gptPrompt
        Lessons {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        Concepts {
          nextToken
          __typename
        }
        sentenceData
        createdAt
        updatedAt
        __typename
      }
      word {
        id
        word
        isNonsenseWord
        concepts {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        wordData
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSentenceWord = /* GraphQL */ `
  mutation UpdateSentenceWord(
    $input: UpdateSentenceWordInput!
    $condition: ModelSentenceWordConditionInput
  ) {
    updateSentenceWord(input: $input, condition: $condition) {
      id
      sentenceId
      wordId
      sentence {
        id
        text
        wordCount
        author
        upvotes
        downvotes
        gptPrompt
        Lessons {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        Concepts {
          nextToken
          __typename
        }
        sentenceData
        createdAt
        updatedAt
        __typename
      }
      word {
        id
        word
        isNonsenseWord
        concepts {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        wordData
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSentenceWord = /* GraphQL */ `
  mutation DeleteSentenceWord(
    $input: DeleteSentenceWordInput!
    $condition: ModelSentenceWordConditionInput
  ) {
    deleteSentenceWord(input: $input, condition: $condition) {
      id
      sentenceId
      wordId
      sentence {
        id
        text
        wordCount
        author
        upvotes
        downvotes
        gptPrompt
        Lessons {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        Concepts {
          nextToken
          __typename
        }
        sentenceData
        createdAt
        updatedAt
        __typename
      }
      word {
        id
        word
        isNonsenseWord
        concepts {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        wordData
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSentenceConcept = /* GraphQL */ `
  mutation CreateSentenceConcept(
    $input: CreateSentenceConceptInput!
    $condition: ModelSentenceConceptConditionInput
  ) {
    createSentenceConcept(input: $input, condition: $condition) {
      id
      sentenceId
      conceptId
      sentence {
        id
        text
        wordCount
        author
        upvotes
        downvotes
        gptPrompt
        Lessons {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        Concepts {
          nextToken
          __typename
        }
        sentenceData
        createdAt
        updatedAt
        __typename
      }
      concept {
        id
        concept
        subcategory
        category
        level
        definition
        trainingResources
        NewLessons {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        Passages {
          nextToken
          __typename
        }
        students {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        ReviewLessons {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSentenceConcept = /* GraphQL */ `
  mutation UpdateSentenceConcept(
    $input: UpdateSentenceConceptInput!
    $condition: ModelSentenceConceptConditionInput
  ) {
    updateSentenceConcept(input: $input, condition: $condition) {
      id
      sentenceId
      conceptId
      sentence {
        id
        text
        wordCount
        author
        upvotes
        downvotes
        gptPrompt
        Lessons {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        Concepts {
          nextToken
          __typename
        }
        sentenceData
        createdAt
        updatedAt
        __typename
      }
      concept {
        id
        concept
        subcategory
        category
        level
        definition
        trainingResources
        NewLessons {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        Passages {
          nextToken
          __typename
        }
        students {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        ReviewLessons {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSentenceConcept = /* GraphQL */ `
  mutation DeleteSentenceConcept(
    $input: DeleteSentenceConceptInput!
    $condition: ModelSentenceConceptConditionInput
  ) {
    deleteSentenceConcept(input: $input, condition: $condition) {
      id
      sentenceId
      conceptId
      sentence {
        id
        text
        wordCount
        author
        upvotes
        downvotes
        gptPrompt
        Lessons {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        Concepts {
          nextToken
          __typename
        }
        sentenceData
        createdAt
        updatedAt
        __typename
      }
      concept {
        id
        concept
        subcategory
        category
        level
        definition
        trainingResources
        NewLessons {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        Passages {
          nextToken
          __typename
        }
        students {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        ReviewLessons {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createConceptLesson = /* GraphQL */ `
  mutation CreateConceptLesson(
    $input: CreateConceptLessonInput!
    $condition: ModelConceptLessonConditionInput
  ) {
    createConceptLesson(input: $input, condition: $condition) {
      id
      lessonId
      conceptId
      lesson {
        id
        date
        minutes
        rating
        studentID
        conceptID {
          nextToken
          __typename
        }
        concepts
        passages {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        lists {
          nextToken
          __typename
        }
        lessonNumber
        lessonData
        comments
        createdAt
        updatedAt
        owner
        __typename
      }
      concept {
        id
        concept
        subcategory
        category
        level
        definition
        trainingResources
        NewLessons {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        Passages {
          nextToken
          __typename
        }
        students {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        ReviewLessons {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateConceptLesson = /* GraphQL */ `
  mutation UpdateConceptLesson(
    $input: UpdateConceptLessonInput!
    $condition: ModelConceptLessonConditionInput
  ) {
    updateConceptLesson(input: $input, condition: $condition) {
      id
      lessonId
      conceptId
      lesson {
        id
        date
        minutes
        rating
        studentID
        conceptID {
          nextToken
          __typename
        }
        concepts
        passages {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        lists {
          nextToken
          __typename
        }
        lessonNumber
        lessonData
        comments
        createdAt
        updatedAt
        owner
        __typename
      }
      concept {
        id
        concept
        subcategory
        category
        level
        definition
        trainingResources
        NewLessons {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        Passages {
          nextToken
          __typename
        }
        students {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        ReviewLessons {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteConceptLesson = /* GraphQL */ `
  mutation DeleteConceptLesson(
    $input: DeleteConceptLessonInput!
    $condition: ModelConceptLessonConditionInput
  ) {
    deleteConceptLesson(input: $input, condition: $condition) {
      id
      lessonId
      conceptId
      lesson {
        id
        date
        minutes
        rating
        studentID
        conceptID {
          nextToken
          __typename
        }
        concepts
        passages {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        lists {
          nextToken
          __typename
        }
        lessonNumber
        lessonData
        comments
        createdAt
        updatedAt
        owner
        __typename
      }
      concept {
        id
        concept
        subcategory
        category
        level
        definition
        trainingResources
        NewLessons {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        Passages {
          nextToken
          __typename
        }
        students {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        ReviewLessons {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createListLesson = /* GraphQL */ `
  mutation CreateListLesson(
    $input: CreateListLessonInput!
    $condition: ModelListLessonConditionInput
  ) {
    createListLesson(input: $input, condition: $condition) {
      id
      lessonId
      listId
      lesson {
        id
        date
        minutes
        rating
        studentID
        conceptID {
          nextToken
          __typename
        }
        concepts
        passages {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        lists {
          nextToken
          __typename
        }
        lessonNumber
        lessonData
        comments
        createdAt
        updatedAt
        owner
        __typename
      }
      list {
        id
        name
        conceptID
        words {
          nextToken
          __typename
        }
        Lessons {
          nextToken
          __typename
        }
        listData
        studentID
        owner
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateListLesson = /* GraphQL */ `
  mutation UpdateListLesson(
    $input: UpdateListLessonInput!
    $condition: ModelListLessonConditionInput
  ) {
    updateListLesson(input: $input, condition: $condition) {
      id
      lessonId
      listId
      lesson {
        id
        date
        minutes
        rating
        studentID
        conceptID {
          nextToken
          __typename
        }
        concepts
        passages {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        lists {
          nextToken
          __typename
        }
        lessonNumber
        lessonData
        comments
        createdAt
        updatedAt
        owner
        __typename
      }
      list {
        id
        name
        conceptID
        words {
          nextToken
          __typename
        }
        Lessons {
          nextToken
          __typename
        }
        listData
        studentID
        owner
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteListLesson = /* GraphQL */ `
  mutation DeleteListLesson(
    $input: DeleteListLessonInput!
    $condition: ModelListLessonConditionInput
  ) {
    deleteListLesson(input: $input, condition: $condition) {
      id
      lessonId
      listId
      lesson {
        id
        date
        minutes
        rating
        studentID
        conceptID {
          nextToken
          __typename
        }
        concepts
        passages {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        lists {
          nextToken
          __typename
        }
        lessonNumber
        lessonData
        comments
        createdAt
        updatedAt
        owner
        __typename
      }
      list {
        id
        name
        conceptID
        words {
          nextToken
          __typename
        }
        Lessons {
          nextToken
          __typename
        }
        listData
        studentID
        owner
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createConceptWord = /* GraphQL */ `
  mutation CreateConceptWord(
    $input: CreateConceptWordInput!
    $condition: ModelConceptWordConditionInput
  ) {
    createConceptWord(input: $input, condition: $condition) {
      id
      wordId
      conceptId
      word {
        id
        word
        isNonsenseWord
        concepts {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        wordData
        createdAt
        updatedAt
        __typename
      }
      concept {
        id
        concept
        subcategory
        category
        level
        definition
        trainingResources
        NewLessons {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        Passages {
          nextToken
          __typename
        }
        students {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        ReviewLessons {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateConceptWord = /* GraphQL */ `
  mutation UpdateConceptWord(
    $input: UpdateConceptWordInput!
    $condition: ModelConceptWordConditionInput
  ) {
    updateConceptWord(input: $input, condition: $condition) {
      id
      wordId
      conceptId
      word {
        id
        word
        isNonsenseWord
        concepts {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        wordData
        createdAt
        updatedAt
        __typename
      }
      concept {
        id
        concept
        subcategory
        category
        level
        definition
        trainingResources
        NewLessons {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        Passages {
          nextToken
          __typename
        }
        students {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        ReviewLessons {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteConceptWord = /* GraphQL */ `
  mutation DeleteConceptWord(
    $input: DeleteConceptWordInput!
    $condition: ModelConceptWordConditionInput
  ) {
    deleteConceptWord(input: $input, condition: $condition) {
      id
      wordId
      conceptId
      word {
        id
        word
        isNonsenseWord
        concepts {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        wordData
        createdAt
        updatedAt
        __typename
      }
      concept {
        id
        concept
        subcategory
        category
        level
        definition
        trainingResources
        NewLessons {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        Passages {
          nextToken
          __typename
        }
        students {
          nextToken
          __typename
        }
        Words {
          nextToken
          __typename
        }
        ReviewLessons {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createWordList = /* GraphQL */ `
  mutation CreateWordList(
    $input: CreateWordListInput!
    $condition: ModelWordListConditionInput
  ) {
    createWordList(input: $input, condition: $condition) {
      id
      wordId
      listId
      word {
        id
        word
        isNonsenseWord
        concepts {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        wordData
        createdAt
        updatedAt
        __typename
      }
      list {
        id
        name
        conceptID
        words {
          nextToken
          __typename
        }
        Lessons {
          nextToken
          __typename
        }
        listData
        studentID
        owner
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateWordList = /* GraphQL */ `
  mutation UpdateWordList(
    $input: UpdateWordListInput!
    $condition: ModelWordListConditionInput
  ) {
    updateWordList(input: $input, condition: $condition) {
      id
      wordId
      listId
      word {
        id
        word
        isNonsenseWord
        concepts {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        wordData
        createdAt
        updatedAt
        __typename
      }
      list {
        id
        name
        conceptID
        words {
          nextToken
          __typename
        }
        Lessons {
          nextToken
          __typename
        }
        listData
        studentID
        owner
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteWordList = /* GraphQL */ `
  mutation DeleteWordList(
    $input: DeleteWordListInput!
    $condition: ModelWordListConditionInput
  ) {
    deleteWordList(input: $input, condition: $condition) {
      id
      wordId
      listId
      word {
        id
        word
        isNonsenseWord
        concepts {
          nextToken
          __typename
        }
        sentences {
          nextToken
          __typename
        }
        Lists {
          nextToken
          __typename
        }
        wordData
        createdAt
        updatedAt
        __typename
      }
      list {
        id
        name
        conceptID
        words {
          nextToken
          __typename
        }
        Lessons {
          nextToken
          __typename
        }
        listData
        studentID
        owner
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
