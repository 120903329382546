import React, { useEffect } from 'react';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import { API } from 'aws-amplify';
import { deleteList as deleteListQuery } from '../../custom-graphql/customMutations';
import {
  Checkbox,
  Divider,
  FormControl,
  Grid,
  Stack,
  Table,
  Textarea,
} from '@mui/joy';
import { useConceptStore, useListStore } from '../../zustand/store';
import { updateList } from '../../custom-graphql/customMutations';
import { onUpdateList } from '../../custom-graphql/customSubscriptions';
import { Auth } from 'aws-amplify';

const EditListModal = ({ open, onClose, myid, list }) => {
  const [checkedWords, setCheckedWords] = React.useState({});
  const [totalScore, setTotalScore] = React.useState(0); // Initialize totalScore state
  const listLength = list.words.items.length;
  var myListData = {};
  if (list.listData) {
    myListData = JSON.parse(list.listData);
  }

  // Initialize checkedWords from list.listData when component mounts
  React.useEffect(() => {
    if (myListData) {
      var initialCheckedWords = {};
      var initialTotalScore = 0.0;
      for (let score in myListData.scores) {
        const myScore = myListData.scores[score];
        initialCheckedWords[myScore.wordId] = myScore.correct;

        if (myScore.correct == 1) {
          initialTotalScore += 1.0;
        }
      }
   
      setCheckedWords(initialCheckedWords);
      setTotalScore(initialTotalScore);
    }
  }, [list]);

  const [partitionDate, setPartitionDate] = React.useState(null);
  const { concepts } = useConceptStore();
  const { lists, setLists} = useListStore();
  const totalChecked = Object.values(checkedWords).filter(Boolean).length;



  const getConceptNameById = (id) => {
    const conceptObject = concepts.find((concept) => concept.id === id);
    return conceptObject ? conceptObject.concept : 'N/A';
  };

  const toggleAll = () => {
    if (
      Object.values(checkedWords).filter(Boolean).length ===
      list.words.items.length
    ) {
      setCheckedWords({});
      setTotalScore(0); // reset the total score
    } else {
      const allWords = {};
      list.words.items.forEach((item) => {
        allWords[item.word.id] = true;
      });
      setCheckedWords(allWords);
      setTotalScore(list.words.items.length); // set the total score to the list length
    }
  };

  useEffect(() => {
 
  }, [totalScore, listLength]);

  const handleCheckboxChange = (wordId, event) => {
    const newCheckedValue = event.target.checked ? 1 : 0;
    const updatedCheckedWords = {
      ...checkedWords,
      [wordId]: newCheckedValue,
    };

    

    // Calculate new totalScore
    var newTotalScore;
    if (event.target.checked) {
      newTotalScore = totalScore + 1;
    } else {
      newTotalScore = totalScore - 1;
    }
    setTotalScore(newTotalScore);

    setCheckedWords(updatedCheckedWords);
  };

  const updateListData = (listId, partition) => {
    var newLists = lists
    for (let listIndex in lists){
      
      var currentList = lists[listIndex]
      if (listId == currentList.id){ 
        newLists[listIndex].listData = partition
      }
    }

 
    //setLists(newLists)
  }

  const savePartition = async () => {

    const newDate = new Date();
    const formattedDate = newDate.toISOString();
    setPartitionDate(formattedDate);

    const newPartition = {
      date: formattedDate,
      scores: Object.keys(checkedWords).map((wordId) => {
        return {
          wordId,
          correct: checkedWords[wordId],
        };
      }),
      totalScore, // Add totalScore to the JSON
    };

    try {
      const updateListInput = {
        id: list.id,
        listData: JSON.stringify(newPartition),
      };

      const result = await API.graphql({
        query: updateList,
        variables: {
          input: updateListInput,
        },
      });
     
      updateListData(list.id, JSON.stringify(newPartition))
      onClose();
    } catch (error) {
      console.error('Error updating list:', error);
    }
  };

  React.useEffect(() => {
    (async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const username = user.username;

        const subscription = API.graphql({
          query: onUpdateList,
          variables: {
            filter: { id: { eq: list.id } },
            owner: username,
          },
        }).subscribe({
          next: ({ value: { data } }) => {
            const updatedList = data.onUpdateList;
            if (updatedList.listData) {
              const updatedListData = JSON.parse(updatedList.listData); // Parse the stringified JSON
              if (
                updatedListData.scores &&
                Array.isArray(updatedListData.scores)
              ) {
                // Convert the scores array into checkedWords object
                const updatedCheckedWords = updatedListData.scores.reduce(
                  (acc, { wordId, correct }) => {
                    acc[wordId] = correct;
                    return acc;
                  },
                  {}
                );
                setCheckedWords(updatedCheckedWords); // Update checkedWords state
              }
            }
          },
        });

        return () => {
          subscription.unsubscribe();
        };
      } catch (error) {
        console.error('Error in useEffect:', error);
      }
    })();
  }, [list.id]); // Keep existing dependency on list.id

  return (
    <Modal
      aria-labelledby='modal-title'
      aria-describedby='modal-desc'
      open={open}
      onClose={onClose}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Sheet
        variant='outlined'
        sx={{
          minWidth: '450px', // Minimum width
          minHeight: '300px', // Minimum height
          maxWidth: 600,
          borderRadius: 'md',
          p: 5,
          boxShadow: 'lg',
          position: 'relative',
        }}
      >
        <ModalClose variant='outlined' />
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Table>
              <thead>
                <tr>
                  <th style={{ minWidth: '50px', justifyContent: 'center' }}>
                    <Typography level='h3'>Word</Typography>
                  </th>
                  <th>
                    <Button variant='outlined' onClick={toggleAll} size='sm'>
                      {totalChecked === list.words.items.length
                        ? 'Clear All'
                        : 'All Correct'}
                    </Button>
                  </th>
                </tr>
              </thead>
              <tbody>
                {list.words.items.map((item, index) => (
                  <tr key={index}>
                    <td
                      style={{
                        minWidth: '100px',
                        paddingLeft: '3px',
                      }}
                    >
                      <Typography level='h3'>{item.word.word}</Typography>
                    </td>
                    <td
                      onClick={(event) =>
                        handleCheckboxChange(item.word.id, {
                          target: { checked: !checkedWords[item.word.id] },
                        })
                      }
                      style={{
                        cursor: 'pointer',
                        width: '100%',
                        height: '100%',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '100%',
                        }}
                      >
                        <Checkbox
                          size='md'
                          color='success'
                          checked={checkedWords[item.word.id] || false} // Now checks checkedWords
                          onChange={(event) =>
                            handleCheckboxChange(item.word.id, event)
                          }
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant='outlined'
                color='primary'
                onClick={savePartition}
              >
                Save
              </Button>
            </div>
          </Grid>
          <Grid item xs={6}>
            <Typography level='h2'>Fluency Tracking</Typography>

            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <tbody>
                <tr>
                  <td>
                    <Typography level='h4'>List Name:</Typography>
                  </td>
                  <td>{list.name}</td>
                </tr>
                <tr>
                  <td>
                    <Typography level='h4'>Focus Concept:</Typography>
                  </td>

                  <td>{getConceptNameById(list.conceptID)}</td>
                </tr>
                <tr>
                  <td>
                    <Typography level='h4'>Total Score:</Typography>
                  </td>
                  <td>{`${((100.0 * totalScore) / listLength).toFixed(
                    0
                  )}%`}</td>
                </tr>
              </tbody>
            </table>
            <FormControl>
              <Textarea
                size='lg'
                minRows={8}
                name='Size'
                placeholder='Add notes here'
              />
            </FormControl>
          </Grid>
        </Grid>
      </Sheet>
    </Modal>
  );
};

export default EditListModal;
