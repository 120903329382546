import * as React from 'react';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';

const ConceptModal = ({ open, onClose, concept }) => {
  return (
    <Modal
      aria-labelledby='modal-title'
      aria-describedby='modal-desc'
      open={open}
      onClose={onClose}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Sheet
        variant='outlined'
        sx={{
          maxWidth: 500,
          borderRadius: 'md',
          p: 3,
          boxShadow: 'lg',
        }}
      >
        <ModalClose
          variant='outlined'
          sx={{
            top: 'calc(-1/4 * var(--IconButton-size))',
            right: 'calc(-1/4 * var(--IconButton-size))',
            boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
            borderRadius: '50%',
            bgcolor: 'background.surface',
          }}
        />
        <Typography
          component='h2'
          id='modal-title'
          level='h4'
          textColor='inherit'
          fontWeight='lg'
          mb={1}
        >
          {concept}
        </Typography>
        <Typography id='modal-desc' textColor='text.tertiary'>
          This is where the Concept Detail page will go. It will have a
          definition and perhaps a short video
        </Typography>
      </Sheet>
    </Modal>
  );
};

export default ConceptModal;
