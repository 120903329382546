import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const CircularBarPlot = ({ data }) => {
  const ref = useRef();

  useEffect(() => {
    const svg = d3.select(ref.current).attr('viewBox', [-300, -300, 600, 600]);

    // Count the occurrences of each concept
    const conceptCounts = {};
    data.forEach((d) => {
      d.concepts.forEach((concept) => {
        conceptCounts[concept] = (conceptCounts[concept] || 0) + 1;
      });
    });

    // Sort keys by count, highest first
    const keys = Object.keys(conceptCounts).sort(
      (a, b) => conceptCounts[b] - conceptCounts[a]
    );

    // Scales
    const x = d3
      .scaleBand()
      .domain(keys)
      .range([0, 2 * Math.PI]);

    const y = d3
      .scaleLinear()
      .domain([0, Math.max(...Object.values(conceptCounts))])
      .range([0, 200]);

    // Create the arcs
    const arc = d3
      .arc()
      .innerRadius(50)
      .outerRadius((d) => 50 + y(conceptCounts[d]))
      .startAngle((d) => x(d) - Math.PI / 2) // Offset by -Math.PI / 2 to start at the top
      .endAngle((d) => x(d) + x.bandwidth() - Math.PI / 2)
      .padAngle(0.01)
      .padRadius(50);

    // Draw the arcs
    svg
      .selectAll('path')
      .data(keys)
      .enter()
      .append('path')
      .attr('fill', '#69b3a2')
      .attr('d', arc);

    // Add the labels
    svg
      .selectAll('text')
      .data(keys)
      .enter()
      .append('text')
      .attr('text-anchor', 'middle')
      .attr(
        'transform',
        (d) => `
          rotate(${((x(d) + x.bandwidth() / 2) * 180) / Math.PI - 90})
          translate(${50 + y(conceptCounts[d]) + 10},0)
        `
      )
      .text((d) => `${d} (${conceptCounts[d]})`);
  }, [data]);

  return <svg ref={ref}></svg>;
};

export default function CircularBarPlotApp() {
  const data = [
    {
      word: 'the',
      concepts: ['A', 'A', 'C', 'C', 'R', 'R'],
    },
    {
      word: 'lamb',
      concepts: ['C', 'R', 'Z', 'A', 'A'],
    },
    {
      word: 'jumped',
      concepts: ['A', 'C', 'C', 'R', 'A', 'J', 'K'],
    },
    {
      word: 'quickly',
      concepts: ['L', 'A', 'C', 'R', 'P'],
    },
    {
      word: 'over',
      concepts: ['R', 'R', 'S', 'A'],
    },
    {
      word: 'fence',
      concepts: ['U', 'A', 'W', 'R'],
    },
    {
      word: 'dog',
      concepts: ['A', 'F', 'R', 'H', 'C', 'J', 'A', 'R'],
    },
    {
      word: 'river',
      concepts: ['A', 'C', 'R', 'Z', 'A', 'A', 'R'],
    },
    {
      word: 'tree',
      concepts: ['R', 'R', 'A', 'N', 'O', 'C', 'A', 'R'],
    },
    {
      word: 'hill',
      concepts: ['S', 'T', 'A', 'C', 'R', 'R', 'Y', 'A', 'R'],
    },
  ];

  return (
    <div className='App'>
      <CircularBarPlot data={data} />
    </div>
  );
}
