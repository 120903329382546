import { useEffect, useState } from 'react';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import {
  onCreateGenerateDocument,
  onUpdateGenerateDocument,
} from '../custom-graphql/customSubscriptions';

export const useGenerateDocumentSubscription = (
  setDownloadManyUrl,
  setDocumentId
) => {
  const [documentId, setLocalDocumentId] = useState(null);

  useEffect(() => {
    let createSubscription, updateSubscription; // Declare these at the higher scope

   

    Auth.currentAuthenticatedUser()
      .then((user) => {
        const username = user.username;
       

        createSubscription = API.graphql(
          graphqlOperation(onCreateGenerateDocument, { owner: username })
        ).subscribe({
          next: ({ provider, value }) => {
            console.log('Subscription data received:', value); // Log received subscription data

            const downloadDocumentLink =
              value.data.onCreateGenerateDocument.downloadDocumentLink;

            // Setting documentId
            const newDocumentId = value.data.onCreateGenerateDocument.id;
            if (newDocumentId) {
              setDocumentId(newDocumentId);
              setLocalDocumentId(newDocumentId);
            }

            if (downloadDocumentLink) {
           
              setDownloadManyUrl(downloadDocumentLink);
            }
          },
          error: (error) => {
            console.warn(
              'Subscription error occurred:',
              JSON.stringify(error, null, 2)
            );
          },
        });

        updateSubscription = API.graphql(
          graphqlOperation(onUpdateGenerateDocument, {
            filter: { id: { eq: documentId } },
          })
        ).subscribe({
          next: ({ value }) => {
            const updatedDownloadDocumentLink =
              value.data.onUpdateGenerateDocument.downloadDocumentLink;
            if (updatedDownloadDocumentLink) {
              setDownloadManyUrl(updatedDownloadDocumentLink);
            }
          },
          error: (error) => {
            console.warn(
              'Update subscription error occurred:',
              JSON.stringify(error, null, 2)
            );
          },
        });
      })
      .catch((err) => {
        console.error(
          'Error fetching authenticated user:',
          JSON.stringify(err, null, 2)
        );
      });

    // Moved cleanup function here to properly clean up subscriptions
    return () => {
      
      createSubscription && createSubscription.unsubscribe();
      updateSubscription && updateSubscription.unsubscribe();
    };
  }, [setDownloadManyUrl, setDocumentId, documentId]);
};
