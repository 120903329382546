import * as React from 'react';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';

const DisplayWordListModal = ({ open, onClose, words }) => {
  return (
    <React.Fragment>
      <Modal
        aria-labelledby='modal-title'
        aria-describedby='modal-desc'
        open={open}
        onClose={onClose}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Sheet
          variant='outlined'
          sx={{
            maxWidth: 500,
            borderRadius: 'md',
            p: 5,
            boxShadow: 'lg',
          }}
        >
          <ModalClose variant='outlined' />
          {/* <Typography variant='h6'>Word List</Typography> */}
          <table>
            <thead>
              <tr>
                <th> {/* <Typography level='h1'>Words</Typography> */}</th>
              </tr>
            </thead>
            <tbody>
              {words &&
                words.map((wordObj, index) => (
                  <tr key={index}>
                    <td>
                      <Typography level='title-lg'>
                        {wordObj.word.word}
                      </Typography>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Sheet>
      </Modal>
    </React.Fragment>
  );
};

export default DisplayWordListModal;
