import { API } from 'aws-amplify';
import { useConceptStore, useListStore } from '../zustand/store';
import { WordsFromConcept } from '../custom-graphql/customQueries';

const useFetchWordsFromConcept = () => {
  const { setWordsFromConcept, setMetrics } = useConceptStore();
  const { knownConcepts } = useListStore();

  const fetchWordsFromConcept = async (targetConceptId) => {
    try {
      const result = await API.graphql({
        query: WordsFromConcept,
        variables: { targetConceptId, limit: 400 },
      });

      const wordsItems = result.data.listConcepts.items[0].Words.items;
      const wordsList = wordsItems.map((item) => ({
        id: item.word.id,
        word: item.word.word,
        conceptId: item.word.concepts.items[0].conceptId,
        additionalConcepts: item.word.concepts.items
          .filter((concept) => concept.conceptId !== targetConceptId)
          .map((concept) => concept.conceptId),
      }));



      const filteredWords = wordsList.filter((word) => {
        const containsOnlyNewOrReview =
          word.conceptId === targetConceptId ||
          (word.conceptId !== targetConceptId &&
            word.additionalConcepts.every((conceptId) =>
              knownConcepts.some(
                (knownConcept) => knownConcept.conceptID === conceptId
              )
            ));
        return containsOnlyNewOrReview;
      });

      const percentageInScopeCriteria =
        (filteredWords.length / wordsList.length) * 100;

      // Set the percentageInScopeCriteria in Zustand
      setMetrics(
        percentageInScopeCriteria,
        filteredWords.length,
        wordsList.length
      );

      // Display the metric to the user
     

      // Log the filtered words and wordsItems
     

      if (filteredWords.length > 0) {
        setWordsFromConcept(filteredWords);
        return filteredWords;
      } else {
        return [];
      }
    } catch (error) {
      console.error('Error fetching words by concept:', error);
      return [];
    }
  };

  return fetchWordsFromConcept;
};

export default useFetchWordsFromConcept;
