import * as React from 'react';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import CreateStudentForm from '../forms/CreateStudent';

const AddStudentModal = ({ open, onClose }) => {
  return (
    <React.Fragment>
      <Modal
        aria-labelledby='modal-title'
        aria-describedby='modal-desc'
        open={open}
        onClose={onClose}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Sheet
          variant='outlined'
          sx={{
            maxWidth: 500,
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
          }}
        >
          <ModalClose variant='outlined' />
          <Typography
            component='h2'
            id='modal-title'
            level='h4'
            textColor='inherit'
            fontWeight='lg'
            mb={1}
          >
            Create a Student
          </Typography>
          {/* Pass the onClose function to CreateStudentForm */}
          <CreateStudentForm onClose={onClose} />
        </Sheet>
      </Modal>
    </React.Fragment>
  );
};

export default AddStudentModal;
