import React, { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { listLists } from '../custom-graphql/customQueries';
import {
  onCreateList,
  onUpdateList,
  onDeleteList,
} from '../custom-graphql/customSubscriptions'; // Update the paths as necessary
import PropTypes from 'prop-types';
import Box from '@mui/joy/Box';
import Table from '@mui/joy/Table';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import Checkbox from '@mui/joy/Checkbox';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import IconButton from '@mui/joy/IconButton';
import Link from '@mui/joy/Link';
import Tooltip from '@mui/joy/Tooltip';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { visuallyHidden } from '@mui/utils';
import usePullLists from '../hooks/ListsHook';
import { DebugStateTable, useListStore } from '../zustand/store';
import { useConceptStore } from '../zustand/store'; // Adjust path as needed
import { useStudentStore } from '../zustand/store';
import CancelIcon from '@mui/icons-material/Cancel';
import DisplayWordListModal from './modals/DisplayWordListModal';
import DeleteWordListModal from './modals/DeleteWordListModal';
import EditListModal from './modals/EditListModal';

import {
  CleaningServicesOutlined,
  Clear,
  Download,
  Edit,
  EditAttributesOutlined,
  PollRounded,
  ThumbUpAltSharp,
  Visibility,
} from '@mui/icons-material';
import { CircularProgress, Stack } from '@mui/joy';

function labelDisplayedRows({ from, to, count }) {
  return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
}

function descendingComparator(a, b, orderBy) {
  // console.log('a:', a, 'b:', b, 'orderBy:', orderBy); // Debugging line

  if (!a || !b) {
    console.error('Either "a" or "b" is undefined');
    return 0; // or handle it differently
  }

  if (b?.[orderBy] < a?.[orderBy]) {
    return -1;
  }
  if (b?.[orderBy] > a?.[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const filteredArray = array.filter(
    (item) => item !== undefined && item !== null
  ); // Filtering out undefined or null

  const stabilizedThis = filteredArray.map((el, index) => [el, index]);
 
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'List Name',
  },
  {
    id: 'words',
    numeric: true,
    disablePadding: false,
    label: 'Words',
  },

  {
    id: 'concept',
    numeric: false,
    disablePadding: false,
    label: 'Focus',
  },
  {
    id: 'score',
    numeric: true,
    disablePadding: false,
    label: 'Score',
  },
  {
    label: 'Actions',
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <thead>
      <tr>
        <th>{''}</th>
        {headCells.map((headCell) => {
          const active = orderBy === headCell.id;
          return (
            <th
              key={headCell.id}
              aria-sort={
                active
                  ? { asc: 'ascending', desc: 'descending' }[order]
                  : undefined
              }
            >
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link
                underline='none'
                color='neutral'
                textColor={active ? 'primary.plainColor' : undefined}
                component='button'
                onClick={createSortHandler(headCell.id)}
                fontWeight='lg'
                startDecorator={
                  headCell.numeric ? (
                    <ArrowDownwardIcon sx={{ opacity: active ? 1 : 0 }} />
                  ) : null
                }
                endDecorator={
                  !headCell.numeric ? (
                    <ArrowDownwardIcon sx={{ opacity: active ? 1 : 0 }} />
                  ) : null
                }
                sx={{
                  '& svg': {
                    transition: '0.2s',
                    transform:
                      active && order === 'desc'
                        ? 'rotate(0deg)'
                        : 'rotate(180deg)',
                  },
                  '&:hover': { '& svg': { opacity: 1 } },
                }}
              >
                {headCell.label}
                {active ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </Link>
            </th>
          );
        })}
      </tr>
    </thead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected, clearSelected } = props; // Add clearSelected prop
  const { selectedStudent } = useStudentStore();

  const handleClearSelected = () => {
    // Invoke function passed as prop to clear selected items
    clearSelected();
  };
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        py: 1,
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: 'background.level1',
        }),
        borderTopLeftRadius: 'var(--unstable_actionRadius)',
        borderTopRightRadius: 'var(--unstable_actionRadius)',
      }}
    >
      {numSelected > 0 ? (
        <Typography sx={{ flex: '1 1 100%' }} component='div'>
          {numSelected >= 10
            ? '10 selected (maximum)'
            : `${numSelected} selected`}
        </Typography>
      ) : (
        <Typography
          level='h4'
          sx={{ flex: '1 1 100%' }}
          id='tableTitle'
          component='div'
        >
          {selectedStudent
            ? `${selectedStudent.firstName} ${selectedStudent.lastName}'s Word Lists`
            : 'No Student Selected'}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title='Clear selections'>
          <IconButton
            size='sm'
            color='danger'
            variant='solid'
            onClick={handleClearSelected}
          >
            <Clear />
          </IconButton>
        </Tooltip>
      ) : (
        <></>
      )}
    </Box>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function ListTable(props) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('words');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setLoading] = React.useState(false);

  //modals
  const [WordListModalOpen, setWordListModalOpen] = React.useState(false);
  const [DeleteListModalOpen, setDeleteListModalOpen] = React.useState(false);
  const [EditListModalOpen, setEditListModalOpen] = React.useState(false);

  const [simpleModalOpen, setSimpleModalOpen] = React.useState(false);

  const [ModalList, setModaLList] = React.useState({
    words: { items: [] },
    id: '',
  });
  const [EditModalList, setEditModalList] = React.useState({
    words: { items: [] },
    id: '',
  });

  const {
    lists,
    nextToken,
    setLists,
    setNextToken,
    addList,
    updateList,
    deleteList,
    selectedLists,
    setSelectedLists,
  } = useListStore();

  const { concepts } = useConceptStore();
  const { selectedStudent } = useStudentStore();
  const setKnownWords = useListStore((state) => state.setKnownWords);
  const setKnownConcepts = useListStore((state) => state.setKnownConcepts);

  const getConceptNameById = (id) => {
    const conceptObject = concepts.find((concept) => concept.id === id);
    return conceptObject ? conceptObject.concept : 'N/A';
  };

  const openSimpleModal = () => {
    setSimpleModalOpen(true);
  };

  const closeSimpleModal = () => {
    setSimpleModalOpen(false);
  };

  useEffect(() => {
    const fetchLists = async () => {
      try {
        setLoading(true); // Set loading to true before fetching data
        const filter = selectedStudent
          ? { studentID: { eq: selectedStudent.id } }
          : {};
        const listData = await API.graphql(
          graphqlOperation(listLists, {
            filter, // Add this filter to the query
            limit: 1000,
            nextToken,
          })
        );

        // Sort the items by createdAt in descending order
        const sortedLists = listData.data.listLists.items.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });

       

        setLists(sortedLists); // Replace with sorted list
        setKnownWords(); // New line to populate knownWords based on the new lists
        setKnownConcepts();
        setNextToken(listData.data.listLists.nextToken);
        setLoading(false); // Set loading to false after fetching data
      } catch (error) {
        setLoading(false); // Also set loading to false if an error occurs
        console.error('Error fetching lists:', error);
      }
    };

    fetchLists();

    // Subscribe to onCreateList events
    /* const onCreateSubscription = API.graphql(
      graphqlOperation(onCreateList)
    ).subscribe({
      next: async (eventData) => {
        const newListId = eventData.value.data.onCreateList.id;
        const fetchedNewList = await API.graphql(
          graphqlOperation(listLists, {
            filter: { id: { eq: newListId } },
          })
        );
        const newList = fetchedNewList.data.listLists.items[0];

        // Assume existingLists is the current array of lists.
        const existingLists = [...useListStore.getState().lists];

        // Add the new list and sort the array
        const updatedLists = [...existingLists, newList].sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });

        // Update the list in your state or store
        setLists(updatedLists);
        console.log("updatedList", updatedLists)
      },
    }); */

    // Subscribe to onUpdateList events
    /* const onUpdateSubscription = API.graphql(
      graphqlOperation(onUpdateList)
    ).subscribe({
      next: async (eventData) => {
        const updatedListId = eventData.value.data.onUpdateList.id;
        const fetchedUpdatedList = await API.graphql(
          graphqlOperation(listLists, {
            filter: { id: { eq: updatedListId } },
          })
        );
        const updatedList = fetchedUpdatedList.data.listLists.items[0];
        updateList(updatedList);
      },
    }); */

    // Subscribe to onDeleteList events
    /*  const onDeleteSubscription = API.graphql(
      graphqlOperation(onDeleteList)
    ).subscribe({
      next: (eventData) => {
        const deletedList = eventData.value.data.onDeleteList;
        deleteList(deletedList.id);
      },
    }); */

    // Cleanup subscriptions when the component is unmounted
    /* return () => {
      onCreateSubscription.unsubscribe();
      onUpdateSubscription.unsubscribe();
      onDeleteSubscription.unsubscribe();
    }; */
  }, [selectedStudent]); // Empty array ensures this runs only when component mounts

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = lists;
      setSelectedLists(newSelected);
      return;
    }
    setSelectedLists([]);
  };

  const openListWordModal = (event, list) => {
    setDeleteListModalOpen(false);
    setModaLList(list);
    setWordListModalOpen(true);
    console.log(list.words.items);
  };

  const openDeleteListModal = (event, list) => {
    setWordListModalOpen(false);
    setModaLList(list);
    setDeleteListModalOpen(true);
  };

  const openEditListModal = (event, list) => {
    setEditListModalOpen(false);
    setEditModalList(list);
    setEditListModalOpen(true);
  };

  const handleClick = (event, clickedList) => {
    const selectedIndex = selectedLists.findIndex(
      (list) => list.id === clickedList.id
    );
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...selectedLists, clickedList];
    } else if (selectedIndex >= 0) {
      newSelected = [
        ...selectedLists.slice(0, selectedIndex),
        ...selectedLists.slice(selectedIndex + 1),
      ];
    }

    if (newSelected.length <= 10) {
      setSelectedLists(newSelected);
      return;
    } else {
      console.warn("You can't select more than 6 lists.");
    }
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event, newValue) => {
    setRowsPerPage(parseInt(newValue.toString(), 10));
    setPage(0);
  };

  const getLabelDisplayedRowsTo = () => {
    if (lists.length === -1) {
      return (page + 1) * rowsPerPage;
    }
    return rowsPerPage === -1
      ? lists.length
      : Math.min(lists.length, (page + 1) * rowsPerPage);
  };

  const isSelected = (list) => {
    return selectedLists.some((selectedList) => selectedList.id === list.id);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - lists.length) : 0;

  useEffect(() => {
    // Your logic here that depends on 'lists'

  }, [lists]);

  return (
    <Sheet
      variant='outlined'
      sx={{ width: '100%', boxShadow: 'sm', borderRadius: 'sm' }}
    >
      <EnhancedTableToolbar
        numSelected={selectedLists.length}
        clearSelected={() => setSelectedLists([])} // Pass the function to clear the state
      />
      <Table
        aria-labelledby='tableTitle'
        hoverRow
        stripe={'odd'}
        sx={{
          '--TableCell-headBackground': 'transparent',
          '--TableCell-selectedBackground': props.themePassed,
          '& thead th:nth-child(1)': {
            width: '40px', // Checkbox column
          },
          '& thead th:nth-child(2)': {
            width: '20%', // List Name column
          },
          '& thead th:nth-child(3)': {
            width: '15%', // Words column
          },
          '& thead th:nth-child(4)': {
            width: '25%', // Concept column
          },
          '& thead th:nth-child(5)': {
            width: '15%', // Score column
          },
          '& thead th:nth-child(6)': {
            width: '25%', // Actions column
          },
          '& tr > *:nth-child(n+3)': { textAlign: 'left' },
          '& thead th': {
            textAlign: 'center',
          },
        }}
      >
        <EnhancedTableHead
          numSelected={selectedLists.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={lists.length}
        />
        <tbody>
          {loading ? (
            <tr>
              <td colSpan='6' style={{ textAlign: 'center', padding: '20px' }}>
                <CircularProgress
                  size='lg'
                  variant='outlined'
                  color='success'
                />
              </td>
            </tr>
          ) : (
            stableSort(lists, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((list, index) => {
                const isItemSelected = isSelected(list);
                const labelId = `enhanced-table-checkbox-${index}`;

                // Safely calculate score here, inside the .map function
                let score = 0; // Initialize as 0 or another default value
                if (list.listData) {
                  const parsedListData = JSON.parse(list.listData);
                  score = parsedListData.scores
                    ? parsedListData.scores.filter((score) => score.correct)
                        .length
                    : 0;
                }

                return (
                  <tr
                    onClick={(event) => handleClick(event, list)}
                    role='checkbox'
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={list.id}
                    selected={isItemSelected}
                    // ... other props
                  >
                    <th scope='row'>
                      <Checkbox
                        checked={isItemSelected}
                        // ... other props
                      />
                    </th>
                    <th id={labelId} scope='row'>
                      {list.name}
                    </th>
                    <td style={{ textAlign: 'center' }}>
                      <Typography>{list?.words?.items?.length ?? 0}</Typography>
                    </td>
                    <td>{getConceptNameById(list.conceptID)}</td>
                    <td style={{ textAlign: 'center' }}>
                      {' '}
                      {/* Center-aligned*/}
                      {list?.words?.items?.length
                        ? `${Math.round(
                            (score / list.words.items.length) * 100
                          )}%`
                        : '0%'}
                    </td>

                    <td>
                      <Stack direction='row'>
                        <IconButton
                          size='sm'
                          variant='plain'
                          onClick={(event) => openEditListModal(event, list)}
                        >
                          <PollRounded color='primary' />
                        </IconButton>
                        <IconButton
                          size='sm'
                          variant='plain'
                          onClick={(event) => openListWordModal(event, list)}
                        >
                          <Visibility />
                        </IconButton>
                        <IconButton
                          size='sm'
                          variant='plain'
                          onClick={(event) => openDeleteListModal(event, list)}
                        >
                          <CancelIcon color='danger' />
                        </IconButton>
                      </Stack>
                    </td>
                  </tr>
                );
              })
          )}
        </tbody>

        <tfoot>
          <tr>
            <td colSpan={6}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  justifyContent: 'flex-end',
                }}
              >
                <FormControl orientation='horizontal' size='sm'>
                  <FormLabel>Rows per page:</FormLabel>
                  <Select
                    onChange={handleChangeRowsPerPage}
                    value={rowsPerPage}
                  >
                    <Option value={5}>5</Option>
                    <Option value={10}>10</Option>
                    <Option value={25}>25</Option>
                  </Select>
                </FormControl>
                <Typography textAlign='center' sx={{ minWidth: 80 }}>
                  {labelDisplayedRows({
                    from: lists.length === 0 ? 0 : page * rowsPerPage + 1,
                    to: getLabelDisplayedRowsTo(),
                    count: lists.length === -1 ? -1 : lists.length,
                  })}
                </Typography>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <IconButton
                    size='sm'
                    color='neutral'
                    variant='outlined'
                    disabled={page === 0}
                    onClick={() => handleChangePage(page - 1)}
                    sx={{ bgcolor: 'background.surface' }}
                  >
                    <KeyboardArrowLeftIcon />
                  </IconButton>
                  <IconButton
                    size='sm'
                    color='neutral'
                    variant='outlined'
                    disabled={
                      lists.length !== -1
                        ? page >= Math.ceil(lists.length / rowsPerPage) - 1
                        : false
                    }
                    onClick={() => handleChangePage(page + 1)}
                    sx={{ bgcolor: 'background.surface' }}
                  >
                    <KeyboardArrowRightIcon />
                  </IconButton>
                </Box>
              </Box>
            </td>
          </tr>
        </tfoot>
      </Table>
      {/* <DebugStateTable /> */}

      <DisplayWordListModal
        open={WordListModalOpen}
        words={ModalList.words.items}
        onClose={() => setWordListModalOpen(false)}
      />
      <DeleteWordListModal
        open={DeleteListModalOpen}
        myid={ModalList.id}
        onClose={() => setDeleteListModalOpen(false)}
      />
      <EditListModal
        open={EditListModalOpen}
        onClose={() => setEditListModalOpen(false)}
        myid={ModalList.id}
        list={EditModalList}
      />
    </Sheet>
  );
}
