import * as React from 'react';
import { useState, useEffect } from 'react';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import Input from '@mui/joy/Input';
import { API } from 'aws-amplify';
import {
  updateStudent,
  deleteStudent,
} from '../../custom-graphql/customMutations';
import DatePicker from 'react-datepicker';
import { getStudent } from '../../custom-graphql/customQueries';
import EditOrDeleteStudentForm from '../forms/EditOrDeleteStudent';

export default function EditStudentsModal({
  open,
  onClose,
  student: propStudent,
}) {
  // Pass in student data
  const [studentID, setStudentID] = useState(
    propStudent ? propStudent.id : null
  );

  const [lastName, setLastName] = useState(
    propStudent ? propStudent.lastName : ''
  );
  const [firstName, setFirstName] = useState(
    propStudent ? propStudent.firstName : ''
  );
  const [dob, setDob] = useState(
    propStudent ? new Date(propStudent.dob) : new Date()
  );
  const [comments, setComments] = useState(
    propStudent ? propStudent.comments : ''
  );
  const [customID, setCustomID] = useState(
    propStudent ? propStudent.customID : ''
  );

  const [version, setVersion] = useState(
    propStudent ? propStudent._version : ''
  ); // [1
  const [error, setError] = useState('');
  const [student, setStudent] = useState(propStudent ? propStudent : '');

  // When student state changes, update individual fields too
  useEffect(() => {
    setStudentID(propStudent ? propStudent.id : null);
    setLastName(propStudent ? propStudent.lastName : '');
    setFirstName(propStudent ? propStudent.firstName : '');
    setDob(propStudent ? new Date(propStudent.dob) : new Date());
    setComments(propStudent ? propStudent.comments : '');
    setCustomID(propStudent ? propStudent.customID : '');
    setVersion(propStudent ? propStudent._version : 0); // Update version here
  }, [propStudent]);

  const handleEdit = async () => {
    if (!studentID) {
      setError('Student ID is missing. Unable to edit.');
      return;
    }
    const studentInput = {
      id: studentID, // Assuming each student has a unique id
      lastName,
      firstName,
      dob: dob.toISOString().split('T')[0],
      comments,
      customID,
      _version: version,
    };

    try {
      await API.graphql({
        query: updateStudent,
        variables: { input: studentInput },
      });
      onClose(); // Close modal after editing
    } catch (error) {
      console.error('Error while updating:', error);
      setError('Error updating student. Please try again.');
    }
  };

  // Fetch a student's data based on its ID
  const fetchStudentData = async () => {
    try {
      const response = await API.graphql({
        query: getStudent,
        variables: { id: studentID },
      });
      return response.data.getStudent;
    } catch (error) {
      console.error('Error fetching student data:', error);
      setError('Error fetching latest student data.');
      return null;
    }
  };

  const handleDelete = async () => {
    if (!studentID) {
      setError('Student ID is missing. Unable to delete.');
      return;
    }
    try {
      await API.graphql({
        query: deleteStudent,
        variables: { input: { id: studentID, _version: version } },
      });
      onClose();
    } catch (error) {
      console.error('Error while deleting:', error);

      if (error.errorType === 'ConflictUnhandled') {
        setError('Data conflict detected. Refetching latest student data...');

        const updatedStudent = await fetchStudentData(studentID);

        if (updatedStudent) {
          setVersion(updatedStudent._version); // Update the version state.
          setStudent(updatedStudent);
          setError(
            `Data updated. Review the changes and try again. New version: ${updatedStudent._version}`
          );
        }
      } else {
        setError('Error deleting student. Please try again.');
      }
    }
  };

  return (
    <React.Fragment>
      <Modal
        aria-labelledby='modal-title'
        aria-describedby='modal-desc'
        open={open}
        onClose={onClose}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Sheet
          variant='outlined'
          sx={{
            maxWidth: 500,
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
          }}
        >
          <ModalClose
            variant='outlined'
            sx={{
              top: 'calc(-1/4 * var(--IconButton-size))',
              right: 'calc(-1/4 * var(--IconButton-size))',
              boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
              borderRadius: '50%',
              bgcolor: 'background.surface',
            }}
          />
          <Typography
            component='h2'
            id='modal-title'
            level='h4'
            textColor='inherit'
            fontWeight='lg'
            mb={1}
          >
            Update {firstName}'s Info
          </Typography>

          <EditOrDeleteStudentForm
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            dob={dob}
            setDob={setDob}
            comments={comments}
            setComments={setComments}
            customID={customID}
            setCustomID={setCustomID}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            error={error}
          />
        </Sheet>
      </Modal>
    </React.Fragment>
  );
}
