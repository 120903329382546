import { listStudents } from '../custom-graphql/customQueries';
import { API, Auth } from 'aws-amplify'; // <-- Import Auth for user identification
import { useStudentStore } from '../zustand/store';
import {
  onDeleteStudent,
  onCreateStudent,
  onUpdateStudent,
} from '../custom-graphql/customSubscriptions';
import { useEffect } from 'react';

const usePullStudents = () => {
  const { setStudents } = useStudentStore();
  const currentStudents = useStudentStore((state) => state.students);

  useEffect(() => {
    const deleteSubscription = API.graphql({
      query: onDeleteStudent,
    }).subscribe({
      next: (deleteData) => {
        const deletedStudentId = deleteData?.value?.data?.onDeleteStudent?.id;
        const updatedStudents = currentStudents.filter(
          (student) => student.id !== deletedStudentId
        );
        setStudents(updatedStudents);
      },
      error: (error) => {
        console.error('Subscription error:', error);
      },
    });

    return () => {
      deleteSubscription.unsubscribe();
    };
  }, [currentStudents, setStudents]);

  useEffect(() => {
    const createSubscription = API.graphql({
      query: onCreateStudent,
    }).subscribe({
      next: (createData) => {
        const newStudent = createData?.value?.data?.onCreateStudent;
        if (newStudent) {
          const updatedStudents = [...currentStudents, newStudent];
          setStudents(updatedStudents);
        }
      },
      error: (error) => {
        console.error('OnCreate subscription error:', error);
      },
    });

    return () => {
      createSubscription.unsubscribe();
    };
  }, [setStudents, currentStudents]);

  useEffect(() => {
    const updateSubscription = API.graphql({
      query: onUpdateStudent,
    }).subscribe({
      next: (updateData) => {
        const updatedStudent = updateData?.value?.data?.onUpdateStudent;
        if (updatedStudent) {
          const updatedStudents = currentStudents.map((student) =>
            student.id === updatedStudent.id ? updatedStudent : student
          );
          setStudents(updatedStudents);
        }
      },
      error: (error) => {
        console.error('OnUpdate subscription error:', error);
      },
    });

    return () => {
      updateSubscription.unsubscribe();
    };
  }, [setStudents, currentStudents]);

  // Updated pullStudents function to filter by unique user ID instead of username
  const pullStudents = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      // console.log('User:', user);
      // console.log('User sub:', user.username); // UUID
      // console.log('User email:', user.attributes.email);
      const result = await API.graphql({
        query: listStudents,
      });
      const students = result.data.listStudents.items;
      setStudents(students);
      return students;
    } catch (error) {
      console.error('Error pulling students:', error);
      return [];
    }
  };

  return pullStudents;
};

export default usePullStudents;
