import React, { useState, useRef, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { CssVarsProvider, useColorScheme } from '@mui/joy/styles';
import {
  CssBaseline,
  Autocomplete,
  Box,
  Typography,
  IconButton,
  Button,
  List,
  Divider,
  Slider,
  ButtonGroup,
  Stack,
  Grid,
  Checkbox,
  Input,
  Modal,
  ModalClose,
  Tooltip,
  Switch,
} from '@mui/joy';
import { sliderClasses } from '@mui/joy/Slider';
import {
  SearchRounded as SearchRoundedIcon,
  DarkModeRounded as DarkModeRoundedIcon,
  LightModeRounded as LightModeRoundedIcon,
  GridViewRounded as GridViewRoundedIcon,
  KeyboardArrowUpRounded as KeyboardArrowUpRoundedIcon,
  Menu as MenuIcon,
  BookRounded as BookRoundedIcon,
  FileDownloadRounded as FileDownloadRoundedIcon,
  ArrowForward as ArrowForwardIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  HorizontalRuleRounded as HorizontalRuleRoundedIcon,
  AutoStoriesRounded as AutoStoriesRoundedIcon,
  FormatAlignJustifyRounded as FormatAlignJustifyRoundedIcon,
  ManageSearch as ManageSearchIcon,
  QuestionAnswerRounded as FaqIcon,
} from '@mui/icons-material';
import teamTheme from './theme';
import theme from './theme';
import ReadyOGLogo from './logo.png';
import TableSortAndSelection from './components/WordTable';
import Menu from './components/Menu';
import Layout from './components/Layout';
import EmailNav from './components/EmailNav';
import TableCollapsibleRow from './components/CollapsibleRow';
import ListTable from './components/ListTable';
import ListItem from '@mui/joy/ListItem';
import SentenceTable from './components/SentenceTable';
import Sheet from '@mui/joy/Sheet';
import Card from '@mui/joy/Card';
import BasicCard from './components/PassageCard';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import 'react-datepicker/dist/react-datepicker.css';
import PDFDownloadButton from './components/PDFDownloadButton';
import WordListModal from './components/modals/WordListModal';
import SentencesModal from './components/modals/SentenceModal';
import PassagesModal from './components/modals/PassagesModal';
import PassageTable from './components/PassageTable';
// AWS/amplify imports
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Amplify, Auth, graphqlOperation } from 'aws-amplify';
import awsconfig from './aws-exports';
import '@aws-amplify/ui-react/styles.css';
//zustand
import { useConceptStore } from './zustand/store';
import usePullConcepts from './hooks/ConceptsHook';
import useFetchWordsFromConcept from './hooks/WordsFromConceptHook';
import { useStudentStore } from './zustand/store';
import { createList } from './custom-graphql/customMutations';
import CommentIcon from '@mui/icons-material/Comment';
import { createGenerateDocument } from './custom-graphql/customMutations';
import { useListStore } from './zustand/store';
import { API } from 'aws-amplify';
import CircularProgress from '@mui/material/CircularProgress';
import { useGenerateDocumentSubscription } from './hooks/GenerateDocumentSubHook';
import { getGenerateDocument } from './custom-graphql/customQueries';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ManageAccountModal from './components/modals/ManageAccountModal';
import ScopeAndSequenceModal from './components/modals/ScopeAndSequenceModal';

//amplify specific
Amplify.configure(awsconfig);

// amplify functions
async function signOut() {
  try {
    await Auth.signOut();
  } catch (error) {
    console.log('error signing out: ', error);
  }
}

const handleSignOut = () => signOut();

// color theme
function ColorSchemeToggle() {
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null); // State to keep track of the selected item

  // Function to handle selecting the item
  const handleSelectItem = (item) => {
    setSelectedItem(item);
  };

  React.useEffect(() => {
    setMounted(true);
  }, []);
  if (!mounted) {
    return <IconButton size='sm' variant='outlined' color='primary' />;
  }
  return (
    <Tooltip title='Toggle light/dark mode'>
      <IconButton
        id='toggle-mode'
        size='sm'
        variant='outlined'
        color='primary'
        onClick={() => {
          if (mode === 'light') {
            setMode('dark');
          } else {
            setMode('light');
          }
        }}
      >
        {mode === 'light' ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
      </IconButton>
    </Tooltip>
  );
}

function valueText(value) {
  return `${value} words`;
}

export function ReadyOG({ signOut, user }) {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  // Inside the TeamExample function
  const [selectedItem, setSelectedItem] = useState(null);
  const [showSidePane, setShowSidePane] = useState(false); // State to control the visibility of the SidePane
  const [isFindWordsClicked, setIsFindWordsClicked] = useState(false); // State to track if "Find Words" is clicked
  // New state to keep track of the selected value in the Autocomplete
  // Function to handle "Find Words" button click
  const [lessonName, setLessonName] = useState('');
  const [lessonSection1, setLessonSection1] = useState('');
  const [lessonSection2, setLessonSection2] = useState('');
  const [lessonSection3, setLessonSection3] = useState('');
  const [lessonSection4, setLessonSection4] = useState('');
  const [lessonSection5, setLessonSection5] = useState('');
  const [lessonSection6, setLessonSection6] = useState('');
  const [lessonSection7, setLessonSection7] = useState('');
  const [lessonSection8, setLessonSection8] = useState('');
  const [lessonSection9, setLessonSection9] = useState('');
  const [lessonSection10, setLessonSection10] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isWordListModalOpen, setIsWordListModalOpen] = useState(false);
  const [isSentencesModalOpen, setIsSentencesModalOpen] = useState(false);
  const [isPassagesModalOpen, setIsPassagesModalOpen] = useState(false);
  const [value, setValue] = useState([5, 8]);
  const [fetchedWords, setFetchedWords] = useState([]);
  const selectedStudent = useStudentStore((state) => state.selectedStudent);
  const [loading, setLoading] = useState(false);
  const [modalTwoOpen, setModalTwoOpen] = useState(false);
  const [lists, setLists] = useState([]);
  const [centerAlign, setCenterAlign] = useState(false);
  const [borderOutline, setBorderOutline] = useState(false);
  const [showHeaders, setShowHeaders] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isImageLoaded, setImageLoaded] = useState(false);
  const [isIlluminated, setIlluminated] = useState(false);
  // const setConceptState = useConceptStore((state) => state.resetState);
  const setListState = useListStore((state) => state.setSelectedLists); // Import the function to set selectedLists
  const [isModalOpen, setModalOpen] = useState(false);
  const [isScopeAndSequenceModalOpen, setScopeAndSequenceModalOpen] =
    useState(false);

  const openScopeAndSequenceModal = () => setScopeAndSequenceModalOpen(true);
  const closeScopeAndSequenceModal = () => setScopeAndSequenceModalOpen(false);

  const { setShowCreateAnother, showCreateAnother } = useConceptStore(
    (state) => ({
      setShowCreateAnother: state.setShowCreateAnother,
      showCreateAnother: state.showCreateAnother,
    })
  );
  const resetWordList = () => {
    setIsFindWordsClicked(false);
    setShowCreateAnother(false);
  }
  // CONCEPTS
  const pullConcepts = usePullConcepts();
  const concepts = useConceptStore((state) => state.concepts);
  const selectedConcept = useConceptStore((state) => state.selectedConcept); // Read selectedConcept from the store
  const [filterType, setFilterType] = useState('All'); // Could be 'All', 'New', or 'Review'

  const handleFilterClick = (type) => {
    setFilterType(type);
  };
  const selectKnownConcepts = (state) => state.knownConcepts;
  const knownConcepts = useListStore(selectKnownConcepts);

  const filteredConcepts = concepts.filter((concept) => {
    if (filterType === 'All') return true;
    if (filterType === 'New') {
      return !knownConcepts.some((known) => known.conceptID === concept.id);
    }
    if (filterType === 'Review') {
      return knownConcepts.some((known) => known.conceptID === concept.id);
    }
    return true;
  });

  // const [downloadManyUrl, setDownloadManyUrl] = useState(null);
  const [documentId, setDocumentId] = useState(null);

  const { downloadManyUrl, setDownloadManyUrl } = useListStore((state) => ({
    downloadManyUrl: state.downloadManyUrl,
    setDownloadManyUrl: state.setDownloadManyUrl,
  }));

  const setSelectedConcept = useConceptStore(
    (state) => state.setSelectedConcept
  ); // Access setSelectedConcept action
  const fetchWordsFromConcept = useFetchWordsFromConcept(); // Initialize the hook

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Use the custom hook for the GraphQL subscription
  useGenerateDocumentSubscription(setDownloadManyUrl, setDocumentId);

  useEffect(() => {

    if (selectedConcept) {
     
      setIlluminated(true);
      setTimeout(() => {
       
        setIlluminated(false);
      }, 1000); // Reset after 1 second
    }
  }, [selectedConcept]);

  useEffect(() => {
    pullConcepts(); // Fetch concepts when the component mounts
  }, []);

  useEffect(() => {
   
  }, [downloadManyUrl]);

  useEffect(() => {
    const unsubscribe = useStudentStore.subscribe(
      (selectedStudent, prevSelectedStudent) => {
        if (selectedStudent !== prevSelectedStudent) {
          useListStore.getState().resetState();
          useConceptStore.getState().resetState();
        }
      },
      (state) => state.selectedStudent
    );

    return () => {
      // Cleanup
      unsubscribe();
    };
  }, []);

  const handleCreateAnotherClick = () => {
    // Clear Zustand states except for selectedConcept
    useConceptStore.setState((state) => ({
      // wordsFromConcept: [],
      listName: '',
      selectedWords: [],
      showCreateAnother: false, // Reset to default value
    }));

    // Reset other local states and Zustand states outside of useConceptStore
    setIsFindWordsClicked(true);
    setListState([]); // Clear selectedLists
  };

  // Modal Section
  // // Word Lists Modal
  const handleModalWordListOpen = () => {
    setIsWordListModalOpen(true);
  };

  const handleModalWordListClose = () => {
    setIsWordListModalOpen(false);
  };

  // // Sentences Modal
  const handleModalSentencesOpen = () => {
    setIsSentencesModalOpen(true);
  };

  const handleModalSentencesClose = () => {
    setIsSentencesModalOpen(false);
  };

  // // Passages Modal
  const handleModalPassagesOpen = () => {
    setIsPassagesModalOpen(true);
  };

  const handleModalPassagesClose = () => {
    setIsPassagesModalOpen(false);
  };
  const handleModal2Close = () => {
    setModalTwoOpen(false);
  };

  const selectedLists = useListStore.getState().selectedLists;

  const handleAndFetchDownload = async () => {
    setLoading(true); // Start loading

    const maxRetries = 5; // Number of times to retry fetching the document
    let retries = 0;

    try {
      if (documentId) {
        let downloadLink = null;

        // Keep trying until downloadLink is available or maxRetries is reached
        while (!downloadLink && retries < maxRetries) {
          const result = await API.graphql(
            graphqlOperation(getGenerateDocument, { id: documentId })
          );
          downloadLink = result.data.getGenerateDocument.downloadDocumentLink;

          if (!downloadLink) {
            console.log('Download link is not yet available. Retrying...');
            retries++;
            // Wait for 2 seconds before retrying
            await new Promise((resolve) => setTimeout(resolve, 2000));
          } else {
            setDownloadManyUrl(downloadLink);
          }
        }

        // If after maxRetries the link is still not available, handle accordingly
        if (!downloadLink) {
          console.log(
            'Download link could not be fetched after maximum retries.'
          );
        }
      }
    } catch (error) {
      console.error('An error occurred:', error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Define the function that handles multiple list downloads
  const handleDownloadManyClick = async () => {
    // Set loading to true at the start
    setIsLoading(true);

    // Prepare the variables to be used in the GraphQL API call
    const genDocVariables = {
      input: {
        listObject: JSON.stringify({ Lists: { items: selectedLists } }),
        docParameters: JSON.stringify({
          DocParameters: {
            items: {
              centerAlign: centerAlign,
              borderOutline: borderOutline,
              showHeaders: showHeaders,
            },
          },
        }),
      },
    };

    try {
      // Make the GraphQL API call using the Amplify API utility
      const response = await API.graphql({
        query: createGenerateDocument,
        variables: genDocVariables,
      });

      // Log successful response
      console.log('Response:', response);
      console.log('Full Response:', JSON.stringify(response, null, 2));

      // Update the downloadManyUrl from the response.
      // Replace 'someUrlField' with the actual field in your GraphQL response that contains the download URL.
      const urlFromResponse = response.data.someUrlField;

      // Update the downloadManyUrl in the global state
      useListStore.getState().setDownloadManyUrl(urlFromResponse);
    } catch (error) {
      // Log any errors that occur during the API call
      console.log('ERROR:', error);
      // You may set an error state here to notify the user.
    } finally {
      // Stop the loading spinner and open the modal, no matter if the API call was successful or not
      setIsLoading(false);
      setModalTwoOpen(true);
    }
  };

  const handleFindWordsClick = async () => {
    try {
      const fetchedWordsResponse = await fetchWordsFromConcept(
        selectedConcept.id
      );

      setIsFindWordsClicked(true);
      // console.log('fetchedWordsResponse', fetchedWordsResponse);

      // Access the fetched words from the response
      const conceptWithWords = fetchedWordsResponse;
      if (conceptWithWords) {
        const words = conceptWithWords.map((item) => item.word);
        setFetchedWords(words); // Update local state
        // setWordsFromConcept(words); // Update Zustand state
      } else {
        setFetchedWords([]);
        // setWordsFromConcept([]);
      }
    } catch (error) {
      // Handle the error appropriately
      console.error('Error fetching words:', error);
    }

    setIsFindWordsClicked(true);
  };

  const componentRef = useRef();

  // Function to handle "Clear filters" button click
  const handleClearFiltersClick = () => {
    setIsFindWordsClicked(false);
    setSelectedConcept('');

    // Clear Zustand states
    useConceptStore.setState({
      wordsFromConcept: [],
      listName: '',
      selectedConcept: '',
      selectedWords: [],
    });
    // Clear Zustand state in the useListStore
    setListState([]); // Clear selectedLists
  };

  // Function to handle selecting a concept in the Autocomplete
  const handleConceptSelect = (event, value) => {
    setSelectedConcept(value);
  };

  // New state to keep track of whether the button should be disabled
  const isSearchButtonDisabled =
    !selectedConcept || selectedConcept === 'Select a Concept';

  // Function to handle selecting the item from EmailNav
  const handleSelectItem = (item) => {
    setSelectedItem(item);
    if (
      item === 'Word Lists' ||
      item === 'Sentences' ||
      item === 'Passages' ||
      item === 'Lessons'
    ) {
      setShowSidePane(true);
    } else {
      setShowSidePane(false);
    }
    return item;
  };

  // Function to format the date as "YYYY-MM-DD"
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const { setListName, listName, createList } = useConceptStore((state) => ({
    setListName: state.setListName,
    listName: state.listName,
    createList: state.createList,
  }));

  // const fetchDownloadLink = async (documentId, setDownloadManyUrl) => {
  //   try {
  //     setIsLoading(true); // Start loading
  //     console.log(`Fetching download link for document ID: ${documentId}`);

  //     const result = await API.graphql(
  //       graphqlOperation(getGenerateDocument, { id: documentId })
  //     );

  //     const downloadLink = result.data.getGenerateDocument.downloadDocumentLink;

  //     if (downloadLink) {
  //       console.log('Successfully fetched download link:', downloadLink);
  //       setDownloadManyUrl(downloadLink); // Update the state with the new link
  //     } else {
  //       console.log('Download link is not yet available.');
  //       // Here, you might want to implement some retry mechanism or set a timeout
  //     }
  //   } catch (error) {
  //     console.error('Error fetching download link:', error);
  //   } finally {
  //     setIsLoading(false); // Stop loading regardless of the outcome
  //   }
  // };

  return (
    <CssVarsProvider disableTransitionOnChange theme={teamTheme}>
      <CssBaseline />
      {drawerOpen && (
        <Layout.SideDrawer onClose={() => setDrawerOpen(false)}>
          <EmailNav
            selectedItem={selectedItem}
            onSelectItem={setSelectedItem}
          />
        </Layout.SideDrawer>
      )}
      <Layout.Root
        sx={{
          ...(drawerOpen && {
            height: '100vh',
            overflow: 'hidden',
          }),
        }}
      >
        <Layout.Header>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 1.5,
            }}
          >
            <IconButton
              variant='outlined'
              size='sm'
              onClick={() => setDrawerOpen(true)}
              sx={{ display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <IconButton
              size='sm'
              variant='plain'
              sx={{ display: { xs: 'none', sm: 'inline-flex' } }}
            >
              <img src={ReadyOGLogo} alt='ReadyOG! Logo' />
            </IconButton>
            <Typography component='h1' fontWeight='xl'>
              ReadyOG!
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1.5 }}>
            {/* <IconButton
              size='sm'
              variant='outlined'
              color='primary'
              sx={{ display: { xs: 'inline-flex', sm: 'none' } }}
            >
              <SearchRoundedIcon />
            </IconButton> */}
            <Tooltip title='Tell us what you think'>
              <IconButton
                size='sm'
                variant='outlined'
                color='primary'
                component='a'
                href='https://forms.gle/cKgKgsoqDUKoiT5EA'
                target='_blank'
                rel='noopener noreferrer'
              >
                <CommentIcon />
              </IconButton>
            </Tooltip>
            <ColorSchemeToggle />
            <Menu
              id='app-selector'
              control={
                <Tooltip title='App Management'>
                  <IconButton
                    size='sm'
                    variant='outlined'
                    color='primary'
                    aria-label='Apps'
                  >
                    <GridViewRoundedIcon />
                  </IconButton>
                </Tooltip>
              }
              menus={[
                {
                  label: 'About Us',
                  onClick: () =>
                    window.open(
                      'https://www.structuredlitsolutions.com',
                      '_blank'
                    ),
                },
                {
                  label: 'My Account',
                  active: true,
                  onClick: () => setModalOpen(true),
                },
                {
                  label: 'Sign Out',
                  onClick: handleSignOut, // Call handleSignOut function when clicked
                },
              ]}
            />
            <ManageAccountModal
              open={isModalOpen}
              onClose={() => setModalOpen(false)}
            />
          </Box>
        </Layout.Header>
        <Layout.SideNav>
          <EmailNav
            selectedItem={selectedItem}
            onSelectItem={handleSelectItem}
          />
        </Layout.SideNav>
        <Layout.SidePane>
          {showSidePane && selectedItem === 'Lessons' ? (
            <>
              <Card variant='outlined'>
                <Typography level='h4'>Create Lesson</Typography>
                <Typography level='g6' fontSize='xl' sx={{ mb: 0.5 }}>
                  <Input
                    placeholder='Give your lesson a name'
                    size='sm'
                    onChange={(e) => setLessonName(e.target.value)}
                  />
                  <Stack direction='row' spacing={1}>
                    <Divider orientation='vertical' />

                    <Typography level='body2'>Date</Typography>

                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      placeholderText='Select a date'
                      // You can customize the date format using dateFormat prop
                      dateFormat='yyyy-MM-dd'
                      // You can set the minimum and maximum selectable dates
                      // minDate={new Date()}
                      // maxDate={new Date('2023-12-31')}
                    />
                    <Divider orientation='vertical' />
                    <Typography level='body2'>Instructor</Typography>
                  </Stack>
                </Typography>
                <Box sx={{ p: 2 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Stack>
                      <Typography level='h6' sx={{ mb: 0.5 }}>
                        Target Concepts
                      </Typography>
                      <Divider />
                      <Typography level='title-sm' textColor='text.primary'>
                        Focus Concept
                      </Typography>
                    </Stack>
                  </Box>
                  <div>
                    <h1>Concepts</h1>
                    {concepts.length > 0 && (
                      <Autocomplete
                        placeholder='Select a Concept'
                        size='sm'
                        options={concepts}
                        groupBy={(option) =>
                          `${option.category} | ${option.subcategory}`
                        }
                        getOptionLabel={(option) =>
                          option ? option.concept : ''
                        }
                        value={selectedConcept} // Set to null if selectedConcept is undefined
                        onChange={handleConceptSelect}
                      />
                    )}
                  </div>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography level='title-sm' textColor='text.primary'>
                      Review Concepts
                    </Typography>
                  </Box>
                  <Autocomplete
                    size='sm'
                    multiple
                    placeholder='Select a Review Concept'
                    options={concepts}
                    groupBy={(option) =>
                      `${option.category} | ${option.subcategory}`
                    }
                    getOptionLabel={(option) =>
                      option ? option.concept : 'select'
                    }
                  />
                </Box>
                <Typography level='h4' fontSize='xl' sx={{ mb: 0.5 }}>
                  1. Phonogram Drill
                </Typography>
                <Typography level='body2'>
                  <Input
                    placeholder='lessonSection1'
                    size='sm'
                    onChange={(e) => setLessonSection1(e.target.value)}
                  />
                </Typography>
                <Typography level='h4' fontSize='xl' sx={{ mb: 0.5 }}>
                  2. PA and/or Blending Drill{' '}
                </Typography>
                <Typography level='body2'>
                  <Input
                    placeholder='lessonSection2'
                    size='sm'
                    onChange={(e) => setLessonSection2(e.target.value)}
                  />
                </Typography>
                <Typography level='h4' fontSize='xl' sx={{ mb: 0.5 }}>
                  3. Review Words
                </Typography>
                <Typography level='body2'>
                  <Stack
                    direction='row'
                    divider={<Divider orientation='vertical' />}
                    spacing={2}
                    // justifyContent='center'
                  >
                    <Button size='s' onClick={handleModalWordListOpen}>
                      <ListItemDecorator sx={{ color: 'inherit' }}>
                        <HorizontalRuleRoundedIcon
                          fontSize='small'
                          sx={{ color: 'neutral.500' }}
                        />
                      </ListItemDecorator>
                    </Button>
                    <Button size='s' onClick={handleModalWordListOpen}>
                      <ListItemDecorator sx={{ color: 'inherit' }}>
                        <HorizontalRuleRoundedIcon
                          fontSize='small'
                          sx={{ color: 'neutral.500' }}
                        />
                      </ListItemDecorator>
                    </Button>
                    <Button size='s' onClick={handleModalWordListOpen}>
                      <ListItemDecorator sx={{ color: 'inherit' }}>
                        <HorizontalRuleRoundedIcon
                          fontSize='small'
                          sx={{ color: 'neutral.500' }}
                        />
                      </ListItemDecorator>
                    </Button>
                    <Typography level='title-sm' sx={{ mb: 0.5 }}>
                      Attach Review Word Lists
                    </Typography>
                  </Stack>
                </Typography>
                <Typography level='h4' fontSize='xl' sx={{ mb: 0.5 }}>
                  4. Guided Discovery
                </Typography>
                <Typography level='body2'>
                  <Stack
                    direction='row'
                    divider={<Divider orientation='vertical' />}
                    spacing={2}
                    // justifyContent='center'
                  >
                    <Button size='s' onClick={handleModalWordListOpen}>
                      <ListItemDecorator sx={{ color: 'inherit' }}>
                        <HorizontalRuleRoundedIcon
                          fontSize='small'
                          sx={{ color: 'neutral.500' }}
                        />
                      </ListItemDecorator>
                    </Button>
                    <Typography level='title-sm' sx={{ mb: 0.5 }}>
                      Attach New Word List
                    </Typography>
                  </Stack>
                </Typography>

                <Typography level='h4' fontSize='xl' sx={{ mb: 0.5 }}>
                  5. What Says?
                </Typography>
                <Typography level='body2'>
                  <Input
                    placeholder='lessonSection5'
                    size='sm'
                    onChange={(e) => setLessonSection5(e.target.value)}
                  />
                </Typography>
                <Typography level='h4' fontSize='xl' sx={{ mb: 0.5 }}>
                  6. Simultaneous Oral Spelling
                </Typography>
                <Input
                  placeholder='lessonSection6'
                  size='sm'
                  onChange={(e) => setLessonSection6(e.target.value)}
                />
                <Typography level='h4' fontSize='xl' sx={{ mb: 0.5 }}>
                  7. Dictation
                </Typography>
                <Typography level='body2'>
                  <Stack
                    direction='row'
                    divider={<Divider orientation='vertical' />}
                    spacing={2}
                    // justifyContent='center'
                  >
                    <Button size='s' onClick={handleModalSentencesOpen}>
                      <ListItemDecorator sx={{ color: 'inherit' }}>
                        <FormatAlignJustifyRoundedIcon
                          fontSize='small'
                          sx={{ color: 'neutral.500' }}
                        />
                      </ListItemDecorator>
                    </Button>
                    <Button size='s' onClick={handleModalSentencesOpen}>
                      <ListItemDecorator sx={{ color: 'inherit' }}>
                        <FormatAlignJustifyRoundedIcon
                          fontSize='small'
                          sx={{ color: 'neutral.500' }}
                        />
                      </ListItemDecorator>
                    </Button>
                    <Button size='s' onClick={handleModalSentencesOpen}>
                      <ListItemDecorator sx={{ color: 'inherit' }}>
                        <FormatAlignJustifyRoundedIcon
                          fontSize='small'
                          sx={{ color: 'neutral.500' }}
                        />
                      </ListItemDecorator>
                    </Button>
                    <Button size='s' onClick={handleModalSentencesOpen}>
                      <ListItemDecorator sx={{ color: 'inherit' }}>
                        <FormatAlignJustifyRoundedIcon
                          fontSize='small'
                          sx={{ color: 'neutral.500' }}
                        />
                      </ListItemDecorator>
                    </Button>
                    <Typography level='title-sm' sx={{ mb: 0.5 }}>
                      Attach Sentences
                    </Typography>
                  </Stack>
                </Typography>

                <Typography level='h4' fontSize='xl' sx={{ mb: 0.5 }}>
                  8. Oral Reading
                </Typography>
                <Input
                  placeholder='lessonSection8'
                  size='sm'
                  onChange={(e) => setLessonSection8(e.target.value)}
                />
                <Typography level='body2'>
                  <Stack
                    direction='row'
                    divider={<Divider orientation='vertical' />}
                    spacing={2}
                    // justifyContent='center'
                  >
                    <Button size='s' onClick={handleModalPassagesOpen}>
                      <ListItemDecorator sx={{ color: 'inherit' }}>
                        <AutoStoriesRoundedIcon
                          fontSize='small'
                          sx={{ color: 'neutral.500' }}
                        />
                      </ListItemDecorator>
                    </Button>
                    <Button size='s' onClick={handleModalPassagesOpen}>
                      <ListItemDecorator sx={{ color: 'inherit' }}>
                        <AutoStoriesRoundedIcon
                          fontSize='small'
                          sx={{ color: 'neutral.500' }}
                        />
                      </ListItemDecorator>
                    </Button>
                    <Typography level='title-sm' sx={{ mb: 0.5 }}>
                      Attach Passage(s)
                    </Typography>
                  </Stack>
                </Typography>
                <Typography level='h4' fontSize='xl' sx={{ mb: 0.5 }}>
                  9. “What did you learn and practice today?”{' '}
                </Typography>
                <Input
                  placeholder='lessonSection9'
                  size='sm'
                  onChange={(e) => setLessonSection9(e.target.value)}
                />
                <Typography level='h4' fontSize='xl' sx={{ mb: 0.5 }}>
                  10. Subjective:
                </Typography>
                <Input
                  placeholder='lessonSection10'
                  size='sm'
                  onChange={(e) => setLessonSection10(e.target.value)}
                />
                <WordListModal
                  isWordListModalOpen={isWordListModalOpen}
                  onClose={handleModalWordListClose}
                  themePassed={(theme) => theme.vars.palette.info.softBg}
                />

                <SentencesModal
                  isSentencesModalOpen={isSentencesModalOpen}
                  onClose={handleModalSentencesClose}
                  themePassed={(theme) => theme.vars.palette.info.softBg}
                />

                <PassagesModal
                  isPassagesModalOpen={isPassagesModalOpen}
                  onClose={handleModalPassagesClose}
                  themePassed={(theme) => theme.vars.palette.info.softBg}
                />
              </Card>
            </>
          ) : selectedItem ? (
            // Content for when selectedItem is not null
            <>
              <Box
                sx={{
                  // p: 2,
                  pb: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: 3,
                }}
              >
                <Typography
                  fontSize='h1'
                  textColor='text.tertiary'
                  textTransform='uppercase'
                  letterSpacing='md'
                  fontWeight='lg'
                >
                  {selectedItem}
                </Typography>
                <Button
                  size='sm'
                  variant='outlined'
                  color='neutral'
                  sx={{ fontSize: 'xs', px: 1 }}
                  onClick={handleClearFiltersClick} // Add the click event handler to clear filters
                >
                  Clear all
                </Button>
                <Button
                  size='sm'
                  variant='soft'
                  sx={{ fontSize: 'xs', px: 1 }}
                  onClick={handleFindWordsClick} // Add the click event handler
                  disabled={isSearchButtonDisabled} // Disable the button when necessary
                >
                  Search {selectedItem}
                  <ArrowForwardIcon fontSize='small' color='primary' />
                </Button>
              </Box>
              <Box sx={{ p: 2 }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography level='h6' textColor='text.primary'>
                    Focus Concept
                  </Typography>
                  <IconButton
                    size='sm'
                    variant='plain'
                    color='primary'
                    sx={{ '--IconButton-size': '24px' }}
                  ></IconButton>
                </Box>
                <Autocomplete
                  style={{
                    backgroundColor: isIlluminated ? 'green' : 'transparent',
                  }}
                  placeholder='Select a Concept'
                  options={concepts}
                  groupBy={(option) =>
                    `${option.category} | ${option.subcategory}`
                  }
                  getOptionLabel={(option) => (option ? option.concept : '')}
                  value={selectedConcept} // Set the value prop to control the selected value
                  onChange={handleConceptSelect} // Add the onChange event handler
                />
                {/* <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography level='h6' textColor='text.primary'>
                    Exclude Concepts
                  </Typography>
                  <IconButton
                    size='sm'
                    variant='plain'
                    color='primary'
                    sx={{ '--IconButton-size': '24px' }}
                  ></IconButton>
                </Box>
                <Autocomplete
                  multiple
                  placeholder='Exclude these Concepts'
                  options={concepts}
                  groupBy={(option) =>
                    `${option.category} | ${option.subcategory}`
                  }
                  getOptionLabel={(option) => (option ? option.concept : '')}
                /> */}
              </Box>

              {selectedItem === 'Sentences' && (
                <Box
                  sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                  }}
                >
                  <Box sx={{ width: 300 }} padding={1}>
                    <Stack direction='row' spacing={2}>
                      <Typography id='track-false-slider' gutterBottom>
                        Words per Sentence
                      </Typography>
                      <Slider
                        padding={3}
                        // track={false}
                        defaultValue={[3, 13]} // Setting minimum to 3 and maximum to 13
                        getAriaLabel={() => 'Word Count'}
                        min={3}
                        max={13}
                        getAriaValueText={valueText}
                        marks={[
                          {
                            value: 3,
                            label: 'min',
                          },
                          {
                            value: 13,
                            label: 'max',
                          },
                        ]}
                        valueLabelDisplay='on' // Display value label
                        sx={{
                          [`& [style*="left:0%"], & [style*="left: 0%"]`]: {
                            [`&.${sliderClasses.markLabel}`]: {
                              transform: 'none',
                            },
                            [`& .${sliderClasses.valueLabel}`]: {
                              left: 'calc(var(--Slider-thumbSize) / 2)',
                              borderBottomLeftRadius: 0,
                              '&::before': {
                                left: 0,
                                transform: 'translateY(100%)',
                                borderLeftColor: 'currentColor',
                              },
                            },
                          },
                          [`& [style*="left:100%"], & [style*="left: 100%"]`]: {
                            [`&.${sliderClasses.markLabel}`]: {
                              transform: 'translateX(-100%)',
                            },
                            [`& .${sliderClasses.valueLabel}`]: {
                              right: 'calc(var(--Slider-thumbSize) / 2)',
                              borderBottomRightRadius: 0,
                              '&::before': {
                                left: 'initial',
                                right: 0,
                                transform: 'translateY(100%)',
                                borderRightColor: 'currentColor',
                              },
                            },
                          },
                        }}
                      />
                    </Stack>
                  </Box>
                </Box>
              )}

              {selectedItem === 'Passages' && (
                <Box
                  sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                  }}
                >
                  <Box sx={{ width: 300 }} padding={1}>
                    <Stack direction='row' spacing={2}>
                      <Typography id='track-false-slider' gutterBottom>
                        Sentences per Passage
                      </Typography>
                      <Slider
                        padding={3}
                        // track={false}
                        defaultValue={[4, 6]} // Setting minimum to 3 and maximum to 13
                        getAriaLabel={() => 'Word Count'}
                        min={3}
                        max={9}
                        getAriaValueText={valueText}
                        marks={[
                          {
                            value: 3,
                            label: 'min',
                          },
                          {
                            value: 9,
                            label: 'max',
                          },
                        ]}
                        valueLabelDisplay='on' // Display value label
                        sx={{
                          [`& [style*="left:0%"], & [style*="left: 0%"]`]: {
                            [`&.${sliderClasses.markLabel}`]: {
                              transform: 'none',
                            },
                            [`& .${sliderClasses.valueLabel}`]: {
                              left: 'calc(var(--Slider-thumbSize) / 2)',
                              borderBottomLeftRadius: 0,
                              '&::before': {
                                left: 0,
                                transform: 'translateY(100%)',
                                borderLeftColor: 'currentColor',
                              },
                            },
                          },
                          [`& [style*="left:100%"], & [style*="left: 100%"]`]: {
                            [`&.${sliderClasses.markLabel}`]: {
                              transform: 'translateX(-100%)',
                            },
                            [`& .${sliderClasses.valueLabel}`]: {
                              right: 'calc(var(--Slider-thumbSize) / 2)',
                              borderBottomRightRadius: 0,
                              '&::before': {
                                left: 'initial',
                                right: 0,
                                transform: 'translateY(100%)',
                                borderRightColor: 'currentColor',
                              },
                            },
                          },
                        }}
                      />
                    </Stack>
                  </Box>
                </Box>
              )}

              <Divider sx={{ p: 1 }} />
              <Box padding={2}></Box>
              <Grid container spacing={1} padding={2} sx={{ flexGrow: 1 }}>
                <Grid xs={7}>
                  {selectedStudent ? (
                    <Typography level='h3' textColor='text.primary' padding={2}>
                      {`${selectedStudent.lastName}`}
                      {', '}
                      {`${selectedStudent.firstName}`}
                    </Typography>
                  ) : (
                    <Typography level='h4' textColor='text.primary'>
                      Loading or no student selected
                    </Typography>
                  )}
                </Grid>
                <Grid xs={1}>
                  <Typography level='h6' textColor='text.primary'></Typography>
                </Grid>
                <Grid xs={4}>
                  <Typography level='h6' textColor='text.primary'>
                    OG Concepts
                  </Typography>
                  <Box>
                    <Box display='flex' justifyContent='right'>
                      <ButtonGroup
                        aria-label='radius button group'
                        sx={{ '--ButtonGroup-radius': '40px' }}
                        size='sm'
                      >
                        <Button
                          size='sm'
                          variant={filterType === 'All' ? 'soft' : 'outlined'}
                          onClick={() => handleFilterClick('All')}
                        >
                          All
                        </Button>
                        <Button
                          size='sm'
                          variant={filterType === 'New' ? 'soft' : 'outlined'}
                          onClick={() => handleFilterClick('New')}
                        >
                          New
                        </Button>
                        <Button
                          size='sm'
                          variant={
                            filterType === 'Review' ? 'soft' : 'outlined'
                          }
                          onClick={() => handleFilterClick('Review')}
                        >
                          Review
                        </Button>
                        <IconButton onClick={openScopeAndSequenceModal}>
                          <ManageSearchIcon />
                        </IconButton>
                      </ButtonGroup>

                      {/* Include the ScopeAndSequenceModal and pass the state and close function as props */}
                      <ScopeAndSequenceModal
                        isOpen={isScopeAndSequenceModalOpen}
                        onClose={closeScopeAndSequenceModal}
                        concepts={concepts}
                      />
                      {/* ...other components and JSX */}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Box>
                <TableCollapsibleRow concepts={filteredConcepts} resetWordList={resetWordList} />
              </Box>
            </>
          ) : (
            // Content for when selectedItem is null
            <p></p>
          )}
        </Layout.SidePane>

        <Layout.Main>
          {selectedItem === 'Word Lists' && ( // Render only when "Word Lists" is selected
            <Grid container spacing={1} sx={{ flexGrow: 1 }}>
              <Grid item xs={isSmallScreen ? 12 : 4} p={1}>
                {isFindWordsClicked && // Render only when "Find Words" is clicked
                  (showCreateAnother ? (
                    <Box
                      sx={{
                        mt: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        p: 2,
                      }}
                    >
                      <Typography level='body2'>
                        Your list has been created!
                      </Typography>
                      <Button
                        variant='outlined'
                        color='primary'
                        onClick={() => {
                          setShowCreateAnother(false);
                          handleCreateAnotherClick();
                        }}
                      >
                        Create Another Word List?
                      </Button>
                    </Box>
                  ) : (
                    <>
                      {/* <Input
                        placeholder='Give your List a name...'
                        value={listName}
                        padding={1}
                        onChange={(e) => setListName(e.target.value)}
                      /> */}
                      <TableSortAndSelection themePassed={theme} />
                    </>
                  ))}
              </Grid>

              <Grid item xs={isSmallScreen ? 12 : 7}>
                <ListTable />{' '}
                {/* ListTable is always displayed when "Word Lists" is the selected item */}
                <Box>
                  <Stack direction='row' p={1} spacing={1}>
                    <Tooltip
                      title={(() => {
                        switch (true) {
                          case selectedLists === null ||
                            selectedLists.length === 0:
                            return 'You must select 1 or more lists to download';
                          case selectedLists.length === 1:
                            return 'Download 1 List';
                          default:
                            return `Download ${selectedLists.length} Lists`;
                        }
                      })()}
                      disabled={selectedLists && selectedLists.length > 0}
                    >
                      <span>
                        <Button
                          size='sm'
                          variant='soft'
                          sx={{ fontSize: 'xs', px: 1 }}
                          onClick={handleDownloadManyClick}
                          disabled={
                            !selectedLists || selectedLists.length === 0
                          }
                        >
                          Download Lists
                          <FileDownloadRoundedIcon
                            fontSize='small'
                            color='primary'
                          />
                        </Button>
                      </span>
                    </Tooltip>

                    <Modal
                      aria-labelledby='modal-title'
                      aria-describedby='modal-desc'
                      open={modalTwoOpen}
                      onClose={handleModal2Close}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Sheet
                        variant='outlined'
                        sx={{
                          maxWidth: 500,
                          borderRadius: 'md',
                          p: 3,
                          boxShadow: 'lg',
                        }}
                      >
                        <ModalClose
                          variant='outlined'
                          sx={{
                            top: 'calc(-1/4 * var(--IconButton-size))',
                            right: 'calc(-1/4 * var(--IconButton-size))',
                            boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                            borderRadius: '50%',
                            bgcolor: 'background.surface',
                          }}
                        />
                        <Box>
                          <img
                            src='/logo_transparent.png'
                            alt='Logo'
                            onLoad={() => setImageLoaded(true)} // Set state to true when image loads
                            style={{ width: '100%' }}
                          />

                          {isImageLoaded && ( // Conditionally render based on image loaded state
                            <>
                              {downloadManyUrl ? (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    mt: 2,
                                  }}
                                >
                                  <Stack>
                                    <h1>Have a great lesson!</h1>
                                    <Button
                                      variant='solid'
                                      color='success'
                                      onClick={() => {
                                        if (downloadManyUrl) {
                                          window.open(
                                            downloadManyUrl,
                                            '_blank'
                                          );
                                        }
                                      }}
                                    >
                                      Download Document
                                    </Button>
                                  </Stack>
                                </Box>
                              ) : (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    mt: 2,
                                  }}
                                >
                                  {loading ? (
                                    <CircularProgress />
                                  ) : (
                                    <Button
                                      onClick={() =>
                                        handleAndFetchDownload(documentId)
                                      }
                                    >
                                      Start Download
                                    </Button>
                                  )}
                                </Box>
                              )}
                            </>
                          )}
                        </Box>
                      </Sheet>
                    </Modal>
                  </Stack>
                  {/* <Box>
                    <Typography
                      id='sandwich-group'
                      level='body2'
                      fontWeight='lg'
                      mb={1}
                    >
                      Document Download Settings
                    </Typography>
                    <Box role='group' aria-labelledby='sandwich-group'>
                      <List size='sm'>
                        <ListItem>
                          <Checkbox label='Center Align' defaultChecked />
                        </ListItem>
                        <ListItem>
                          <Checkbox label='Borders' />
                        </ListItem>
                        <ListItem>
                          <Checkbox label='Hortizontal Orientation' />
                        </ListItem>
                      </List>
                    </Box>
                  </Box> */}
                </Box>
              </Grid>
            </Grid>
          )}
          {selectedItem === 'Sentences' &&
            isFindWordsClicked && ( // Render only when "Word Lists" is selected and "Find Words" is clicked
              <Grid container spacing={2} padding={1} sx={{ flexGrow: 1 }}>
                <Sheet
                  variant='outlined'
                  sx={{ width: '100%', boxShadow: 'sm', borderRadius: 'sm' }}
                >
                  <SentenceTable />
                  <Box>
                    <Stack direction='row' p={1} spacing={1}>
                      <Button
                        size='sm'
                        variant='soft'
                        sx={{ fontSize: 'xs', px: 1 }}
                        onClick={handleFindWordsClick} // Add the click event handler
                      >
                        Download Sentences
                        <FileDownloadRoundedIcon
                          fontSize='small'
                          color='primary'
                        />
                      </Button>
                    </Stack>
                    <Box>
                      <Typography
                        id='sandwich-group'
                        level='body2'
                        fontWeight='lg'
                        mb={1}
                      >
                        Document Download Settings
                      </Typography>
                      <Box role='group' aria-labelledby='sandwich-group'>
                        <List size='sm'>
                          <ListItem>
                            <Checkbox label='Center Align' defaultChecked />
                          </ListItem>
                          <ListItem>
                            <Checkbox label='Borders' />
                          </ListItem>
                          <ListItem>
                            <Checkbox label='Hortizontal Orientation' />
                          </ListItem>
                        </List>
                      </Box>
                    </Box>
                  </Box>
                </Sheet>
              </Grid>
            )}
          {selectedItem === 'Passages' &&
            isFindWordsClicked && ( // Render only when "Word Lists" is selected and "Find Words" is clicked
              <Grid container spacing={2} padding={1} sx={{ flexGrow: 1 }}>
                <Sheet
                  variant='outlined'
                  sx={{ width: '100%', boxShadow: 'sm', borderRadius: 'sm' }}
                >
                  <Box>
                    <Grid
                      container
                      spacing={1}
                      padding={1}
                      sx={{ flexGrow: 1 }}
                    >
                      <Grid xs={12} padding={0}>
                        <PassageTable />
                      </Grid>
                      {/* <Grid xs={4} padding={1}>
                        <BasicCard />
                      </Grid> */}
                    </Grid>
                  </Box>
                </Sheet>
              </Grid>
            )}
          {selectedItem === 'Lessons' && ( // Render only when "Word Lists" is selected and "Find Words" is clicked
            <div ref={componentRef}>
              <Grid container spacing={2} padding={2} sx={{ flexGrow: 1 }}>
                <Sheet
                  variant='outlined'
                  sx={{ width: '100%', boxShadow: 'sm', borderRadius: 'sm' }}
                >
                  <Card variant='outlined'>
                    <Typography level='h1'>
                      {lessonName ? lessonName : 'My Lesson Template'}
                    </Typography>
                    <Sheet>
                      <PDFDownloadButton componentRef={componentRef} />

                      <Stack direction='row' spacing={10}>
                        <Typography level='h6' sx={{ mb: 0.5 }}>
                          Date of Lesson: {formatDate(selectedDate)}{' '}
                          {/* Format as YYYY-MM-DD */}
                        </Typography>
                        <Typography level='h6' sx={{ mb: 0.5 }}>
                          Instructor: Andrea
                        </Typography>
                      </Stack>
                    </Sheet>
                    <Box sx={{ p: 2 }}>
                      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                        <Grid xs={4}>
                          <Typography level='h6' textColor='text.primary'>
                            Focus Concept
                          </Typography>
                          <Typography>{selectedConcept.Concept}</Typography>
                        </Grid>
                        <Grid xs={4}>
                          <Typography level='h6' textColor='text.primary'>
                            Review Concepts
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                    <Tooltip
                      title='Do phonogram drills'
                      placement='right-start'
                    >
                      <Typography level='h4' fontSize='xl' sx={{ mb: 0.5 }}>
                        1. placeholder{' '}
                        {lessonSection1 ? (
                          <Tooltip
                            title='Added successfully'
                            placement='right-start'
                          >
                            <CheckCircleOutlineIcon color='success' size='s' />
                          </Tooltip>
                        ) : null}
                      </Typography>
                    </Tooltip>

                    <Typography level='body2'>placeholder</Typography>
                    <Typography level='body2'>{lessonSection1}</Typography>

                    <Typography level='h4' fontSize='xl' sx={{ mb: 0.5 }}>
                      2. placeholder{' '}
                      {lessonSection2 ? (
                        <Tooltip
                          title='Added successfully'
                          placement='right-start'
                        >
                          <CheckCircleOutlineIcon color='success' size='s' />
                        </Tooltip>
                      ) : null}
                    </Typography>
                    <Typography level='body2'>
                      here are some instructions on what to do in this section
                    </Typography>
                    <Typography level='body2'>{lessonSection2}</Typography>

                    <Typography level='h4' fontSize='xl' sx={{ mb: 0.5 }}>
                      3. placeholder{' '}
                      {lessonSection3 ? (
                        <Tooltip
                          title='Added successfully'
                          placement='right-start'
                        >
                          <CheckCircleOutlineIcon color='success' size='s' />
                        </Tooltip>
                      ) : null}
                    </Typography>
                    <Typography level='body2'>placeholder</Typography>
                    <Typography level='body2'>{lessonSection3}</Typography>

                    <Typography level='h4' fontSize='xl' sx={{ mb: 0.5 }}>
                      4. placeholder{' '}
                      {lessonSection4 ? (
                        <Tooltip
                          title='Added successfully'
                          placement='right-start'
                        >
                          <CheckCircleOutlineIcon color='success' size='s' />
                        </Tooltip>
                      ) : null}
                    </Typography>
                    <Typography level='body2'>{lessonSection4}</Typography>

                    <Typography level='h4' fontSize='xl' sx={{ mb: 0.5 }}>
                      5. placeholder
                      {lessonSection5 ? (
                        <Tooltip
                          title='Added successfully'
                          placement='right-start'
                        >
                          <CheckCircleOutlineIcon color='success' size='s' />
                        </Tooltip>
                      ) : null}
                    </Typography>
                    <Typography level='body2'>{lessonSection5}</Typography>

                    <Typography level='h4' fontSize='xl' sx={{ mb: 0.5 }}>
                      6. placeholder
                      {lessonSection6 ? (
                        <Tooltip
                          title='Added successfully'
                          placement='right-start'
                        >
                          <CheckCircleOutlineIcon color='success' size='s' />
                        </Tooltip>
                      ) : null}
                    </Typography>
                    <Typography level='body2'>Do the activity</Typography>
                    <Typography level='body2'>{lessonSection6}</Typography>

                    <Typography level='h4' fontSize='xl' sx={{ mb: 0.5 }}>
                      7. placeholder{' '}
                      {lessonSection7 ? (
                        <Tooltip
                          title='Added successfully'
                          placement='right-start'
                        >
                          <CheckCircleOutlineIcon color='success' size='s' />
                        </Tooltip>
                      ) : null}
                    </Typography>
                    <Typography level='body2'>placeholder</Typography>
                    <Typography level='body2'>{lessonSection7}</Typography>

                    <Typography level='h4' fontSize='xl' sx={{ mb: 0.5 }}>
                      8. placeholder
                      {lessonSection8 ? (
                        <Tooltip
                          title='Added successfully'
                          placement='right-start'
                        >
                          <CheckCircleOutlineIcon color='success' size='s' />
                        </Tooltip>
                      ) : null}
                    </Typography>
                    <Typography level='body2'>placeholder </Typography>
                    <Typography level='body2'>{lessonSection8}</Typography>

                    <Typography level='h4' fontSize='xl' sx={{ mb: 0.5 }}>
                      9. placeholder{' '}
                      {lessonSection9 ? (
                        <Tooltip
                          title='Added successfully'
                          placement='right-start'
                        >
                          <CheckCircleOutlineIcon color='success' size='s' />
                        </Tooltip>
                      ) : null}
                    </Typography>
                    <Typography level='body2'>{lessonSection9}</Typography>

                    <Typography level='h4' fontSize='xl' sx={{ mb: 0.5 }}>
                      10. placeholder
                      {lessonSection10 ? (
                        <Tooltip
                          title='Added successfully'
                          placement='right-start'
                        >
                          <CheckCircleOutlineIcon color='success' size='s' />
                        </Tooltip>
                      ) : null}
                    </Typography>
                    <Typography level='body2'>{lessonSection10}</Typography>
                  </Card>
                </Sheet>
              </Grid>
            </div>
          )}
        </Layout.Main>
      </Layout.Root>
    </CssVarsProvider>
  );
}

export default withAuthenticator(ReadyOG);
