import React from 'react';
import html2pdf from 'html2pdf.js';
import { Button } from '@mui/joy';

const PDFDownloadButton = ({ componentRef }) => {
  const downloadPDF = () => {
    const element = componentRef.current;

    if (!element) {
      console.error('Component reference not found!');
      return;
    }

    const buttonRef = element.querySelector('.download-button');

    if (!buttonRef) {
      console.error('Button reference not found!');
      return;
    }

    const opt = {
      margin: 10,
      filename: 'my_component.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      exclude: [buttonRef], // Exclude the button element
    };

    html2pdf().from(element).set(opt).save();
  };

  return (
    <Button size='s' className='download-button' onClick={downloadPDF}>
      Download PDF
    </Button>
  );
};

export default PDFDownloadButton;
