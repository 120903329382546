import PropTypes from 'prop-types';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import Tooltip from '@mui/joy/Tooltip';
import CasinoRoundedIcon from '@mui/icons-material/CasinoRounded';
import { Divider, Grid, Input, Stack } from '@mui/joy';
import { ClearRounded } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import React from 'react';
import { useState } from 'react';
import { useConceptStore, useListStore } from '../../zustand/store';

function TableToolbar(props) {
  const setSelectedWords = useConceptStore((state) => state.setSelectedWords);
  const wordsFromConcept = useConceptStore((state) => state.wordsFromConcept);
  const selectedWords = useConceptStore((state) => state.selectedWords);

  const { createList, setShowCreateAnother, showCreateAnother, setListName } =
    useConceptStore((state) => ({
      createList: state.createList,
      setShowCreateAnother: state.setShowCreateAnother,
      showCreateAnother: state.showCreateAnother,
      setListName: state.setListName,
    }));
  const selectedConcept = useConceptStore((state) => state.selectedConcept);

  // Define state and other missing variables
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [localListName, setLocalListName] = useState('');
  const { knownWords, lists, setLists } = useListStore(); // Get knownWords from the store

  const { percentageInScopeCriteria, filteredWordsLength, wordsListLength } =
    useConceptStore();
  // Define missing functions
  // Function to handle "Save" button click
  async function handleSave() {
    setListName(localListName);
    const newList = await createList(); // assuming createList returns a Promise
    setShowCreateAnother(true);
    const newLists = [newList, ...lists]
    setLists(newLists)
  }

  // Function to handle "Roll Dice (10 Random Words)" button click
  function getRandomWords(type) {
   

    // Separate the list into NEW and REVIEW words
    const newWords = wordsFromConcept.filter(
      (word) => !knownWords.some((known) => known.id === word.id)
    );
    const reviewWords = wordsFromConcept.filter((word) =>
      knownWords.some((known) => known.id === word.id)
    );

    let listToUse;

    if (type === 'new') {
      listToUse = newWords;
    } else if (type === 'review') {
      listToUse = reviewWords;
    }

 

    if (listToUse.length === 0) {
     
      return;
    }

    const listLength = listToUse.length;
    

    const wordsSelected = [];

    while (wordsSelected.length < 10 && wordsSelected.length < listLength) {
      const newWord = Math.floor(Math.random() * listLength);

      

      if (!wordsSelected.includes(newWord)) {
        wordsSelected.push(newWord);
      }

     
    }

    const newSelected = [];
    for (const index of wordsSelected) {
     

      newSelected.push(listToUse[index]);
    }

   

    setSelectedWords(newSelected);

   
  }

  // Function to clear selected words
  const clearSelectedWords = () => {
    useConceptStore.setState({ selectedWords: [] });
  };

  const { numSelected } = props;

  const tooltipContent = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 320,
        justifyContent: 'center',
        p: 1,
      }}
    >
      <Typography level='h3'>Selected Words</Typography>
      <ol>
        {selectedWords.map((item, index) => (
          <li key={index}>
            <Typography level='h4'>{item.word}</Typography>
          </li>
        ))}
      </ol>
    </Box>
  );

  const handleTooltipToggle = () => {
    setTooltipOpen(!tooltipOpen);
  };
  const handleChange = (e) => {
    setLocalListName(e.target.value);
    // setListName(e.target.value);
  };

  return (
    <Grid container spacing={1} sx={{ flexGrow: 1 }}>
      <Grid xs={12}>
        <Stack>
          <Grid container spacing={1}>
            {/* First Grid Item */}
            <Grid item xs={6}>
              <Typography level='body2' padding={1} sx={{ mb: 0.5 }}>
                <b>Focus Concept: </b>
                <br />
                <Typography level='body2'>
                  {selectedConcept && selectedConcept.concept
                    ? selectedConcept.concept
                    : 'Please select a concept'}
                </Typography>
              </Typography>
            </Grid>

            {/* Second Grid Item */}
            <Grid item xs={6}>
              <Typography level='body2' padding={1}>
                <b>Words In-Scope:</b> <br />
                {percentageInScopeCriteria.toFixed(0)}% (
                {wordsFromConcept.length} of {wordsListLength})
              </Typography>
            </Grid>

            {/* Third Grid Item */}
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={12}>
                <Input
                  placeholder='Name your List...'
                  defaultValue={localListName}
                  padding={2}
                  onChange={handleChange}
                  sx={{
                    '--Input-focusedInset': 'var(--any, )',
                    '--Input-focusedThickness': '0.25rem',
                    '--Input-focusedHighlight': 'rgba(13,110,253,.25)',
                    '&::before': {
                      transition: 'box-shadow .15s ease-in-out',
                    },
                    '&:focus-within': {
                      borderColor: '#86b7fe',
                    },
                  }}
                />
              </Grid>

              <Grid
                container
                item
                xs={12}
                spacing={2}
                justifyContent='center'
                alignItems='center'
              >
                <Grid item>
                  <Tooltip title='Number of words selected'>
                    <Typography
                      level='body3'
                      padding={1}
                      onClick={handleTooltipToggle} // Toggle tooltip on click
                      // style={{ cursor: 'pointer' }} // Make it look clickable
                    >
                      {numSelected}
                    </Typography>
                  </Tooltip>
                </Grid>

                <Grid item>
                  <Tooltip title='Roll Review (10 Random Review Words)'>
                    <IconButton
                      size='md'
                      variant='outlined'
                      color='neutral'
                      onClick={() => getRandomWords('review')}
                    >
                      <CasinoRoundedIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>

                <Grid item>
                  <Tooltip title='Roll New (10 Random New Words)'>
                    <IconButton
                      size='md'
                      variant='outlined'
                      color='success'
                      onClick={() => getRandomWords('new')}
                    >
                      <CasinoRoundedIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>

                <Grid item>
                  <Tooltip title='Clear'>
                    <IconButton
                      size='md'
                      color='danger'
                      variant='solid'
                      onClick={clearSelectedWords}
                    >
                      <ClearRounded />
                    </IconButton>
                  </Tooltip>
                </Grid>

                <Grid item>
                  <Tooltip
                    open={tooltipOpen}
                    onClose={() => setTooltipOpen(false)}
                    placement='right'
                    variant='outlined'
                    arrow
                    title={(() => {
                      return tooltipContent;
                    })()}
                    disabled={
                      !localListName ||
                      localListName.trim() === '' ||
                      numSelected === 0
                    }
                  >
                    <span>
                      {/* Wrapping IconButton in span allows Tooltip to work even when button is disabled */}
                      <IconButton
                        size='md'
                        color='primary'
                        variant='solid'
                        onClick={handleSave}
                        disabled={
                          !localListName ||
                          localListName.trim() === '' ||
                          numSelected === 0
                        } // Additional condition added
                      >
                        <SaveIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Stack>
      </Grid>
    </Grid>
  );
}
TableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  // Add any other required prop-types here
};

export default TableToolbar;
