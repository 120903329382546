import PropTypes from 'prop-types';
import Box from '@mui/joy/Box';
import Table from '@mui/joy/Table';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import Checkbox from '@mui/joy/Checkbox';
import IconButton from '@mui/joy/IconButton';
import Link from '@mui/joy/Link';
import Tooltip from '@mui/joy/Tooltip';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useConceptStore } from '../zustand/store';
import { Card, CardContent, Chip, Stack } from '@mui/joy';
import React from 'react';
import { useListStore } from '../zustand/store';
import TableHeader from './word-table/TableHead';
import TableToolbar from './word-table/TableToolbar';

function labelDisplayedRows({ from, to, count }) {
  return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function WordTable(props) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('review');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(25);
  const { getConceptById } = useConceptStore();

  const setSelectedWords = useConceptStore((state) => state.setSelectedWords);
  const wordsFromConcept = useConceptStore((state) => state.wordsFromConcept);
  const selectedWords = useConceptStore((state) => state.selectedWords);
  const selectedConcept = useConceptStore((state) => state.selectedConcept);
  const { createList, setShowCreateAnother, showCreateAnother, setListName } =
    useConceptStore((state) => ({
      createList: state.createList,
      setShowCreateAnother: state.setShowCreateAnother,
      showCreateAnother: state.showCreateAnother,
      setListName: state.setListName,
    }));

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = wordsFromConcept.map((n) => n.word);
      useConceptStore.setState({ selectedWords: newSelected });
      return;
    }
    useConceptStore.setState({ selectedWords: [] });
  };

  const handleClick = (event, word) => {
    const currentSelectedWords = useConceptStore.getState().selectedWords;
    const selectedIndex = currentSelectedWords.findIndex(
      (item) => item.id === word.id
    );

    let newSelected = [...currentSelectedWords];

    if (selectedIndex === -1) {
      newSelected.push(word);
    } else {
      newSelected.splice(selectedIndex, 1);
    }

    setSelectedWords(newSelected); // Using Zustand's state updater function
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const getLabelDisplayedRowsTo = () => {
    if (wordsFromConcept.length === -1) {
      return (page + 1) * rowsPerPage;
    }
    return rowsPerPage === -1
      ? wordsFromConcept.length
      : Math.min(wordsFromConcept.length, (page + 1) * rowsPerPage);
  };

  const isSelected = (word) => {
    const match = selectedWords.some((selected) => selected.id === word.id);
    return match;
  };

  const knownWords = useListStore((state) => state.knownWords);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - wordsFromConcept.length)
      : 0;

  function TooltipContent(word, otherConcepts) {
    const conceptsList =
      otherConcepts.length === 0 ? (
        <Typography>This word has no other tags</Typography>
      ) : (
        <ul>
          {otherConcepts.map((conceptId, index) => (
            <li key={index}>{getConceptById(conceptId)}</li>
          ))}
        </ul>
      );
    const styles = {
      card: {
        width: 250,
        border: '1px solid #000', // Add a border for the card
        padding: '16px', // Add padding to the card content
      },
    };

    return (
      <Card sx={styles.card}>
        <CardContent>
          <Stack>
            <div style={styles.header}>
              <Typography style={styles.header} level='h3'>
                <b>{word}</b>
              </Typography>
              <Typography level='body-xs'>
                {selectedConcept && selectedConcept.concept
                  ? selectedConcept.concept
                  : 'Please select a concept'}
              </Typography>
            </div>
            <div style={styles.body}>
              <Typography style={styles.body} level='body-xs'>
                {otherConcepts.length === 0 ? null : 'Other Concept Tags:'}
                {conceptsList}
              </Typography>
              <Typography level='body-xs'>
                <Link>Edit Concept Tags</Link>
              </Typography>
            </div>
          </Stack>
        </CardContent>
      </Card>
    );
  }

  return (
    <Sheet
      variant='outlined'
      sx={{ width: '100%', boxShadow: 'sm', borderRadius: 'sm' }}
    >
      <TableToolbar
        numSelected={selectedWords.length}
        // listName={listName}
        // setListName={setListName}
      />
      <Table
        aria-labelledby='tableTitle'
        hoverRow
        sx={{
          '--TableCell-headBackground': 'transparent',
          '--TableCell-selectedBackground': props.theme,
          '& thead th:nth-child(1)': {
            width: '40px',
          },
          '& thead th:nth-child(2)': {
            width: '85px',
          },
          '& tr > *:nth-child(n+3)': {
            width: '100%',
          },
        }}
      >
        <TableHeader
          numSelected={selectedWords.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={wordsFromConcept.length}
        />
        <tbody>
          {stableSort(wordsFromConcept, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              const isItemSelected = isSelected(row);
              const labelId = `enhanced-table-checkbox-${index}`;
              // Determine if this word is already known
              const isWordKnown = knownWords.some(
                (knownWord) => knownWord.word === row.word
              );

              return (
                <Tooltip
                  placement='left'
                  variant='outlined'
                  arrow
                  title={TooltipContent(row.word, row.additionalConcepts)}
                >
                  <tr
                    onClick={(event) => handleClick(event, row)}
                    role='checkbox'
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    style={
                      isItemSelected
                        ? {
                            '--TableCell-dataBackground':
                              'var(--TableCell-selectedBackground)',
                            '--TableCell-headBackground':
                              'var(--TableCell-selectedBackground)',
                          }
                        : {}
                    }
                  >
                    <th scope='row'>
                      <Checkbox
                        checked={isItemSelected}
                        slotProps={{
                          input: {
                            'aria-labelledby': labelId,
                          },
                        }}
                        sx={{ verticalAlign: 'top' }}
                      />
                    </th>
                    <th id={labelId} scope='row'>
                      {row.word}
                    </th>
                    <td>
                      <Chip
                        size='s'
                        variant={
                          knownWords.some((word) => word.id === row.id)
                            ? 'outlined'
                            : 'solid'
                        }
                        color={
                          knownWords.some((word) => word.id === row.id)
                            ? 'neutral'
                            : 'success'
                        }
                      >
                        {
                          // Determine the label of the chip based on knownWords
                          (() => {
                            const foundWord = knownWords.find(
                              (word) => word.id === row.id
                            );

                            if (foundWord) {
                              return `Review (${foundWord.wordCount})`; // If found, show "Review" with the count
                            } else {
                              return '  New  '; // If not found, show "New"
                            }
                          })()
                        }
                      </Chip>
                    </td>
                  </tr>
                </Tooltip>
              );
            })}

          {emptyRows > 0 && (
            <tr
              style={{
                height: `calc(${emptyRows} * 40px)`,
                '--TableRow-hoverBackground': 'transparent',
              }}
            >
              <td colSpan={6} />
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={6}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  justifyContent: 'flex-end',
                }}
              >
                <Typography textAlign='center' sx={{ minWidth: 80 }}>
                  {labelDisplayedRows({
                    from:
                      wordsFromConcept.length === 0
                        ? 0
                        : page * rowsPerPage + 1,
                    to: getLabelDisplayedRowsTo(),
                    count:
                      wordsFromConcept.length === -1
                        ? -1
                        : wordsFromConcept.length,
                  })}
                </Typography>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <IconButton
                    size='sm'
                    color='neutral'
                    variant='outlined'
                    disabled={page === 0}
                    onClick={() => handleChangePage(page - 1)}
                    sx={{ bgcolor: 'background.surface' }}
                  >
                    <KeyboardArrowLeftIcon />
                  </IconButton>
                  <IconButton
                    size='sm'
                    color='neutral'
                    variant='outlined'
                    disabled={
                      wordsFromConcept.length !== -1
                        ? page >=
                          Math.ceil(wordsFromConcept.length / rowsPerPage) - 1
                        : false
                    }
                    onClick={() => handleChangePage(page + 1)}
                    sx={{ bgcolor: 'background.surface' }}
                  >
                    <KeyboardArrowRightIcon />
                  </IconButton>
                </Box>
              </Box>
            </td>
          </tr>
        </tfoot>
      </Table>
    </Sheet>
  );
}
