import { listConcepts } from '../custom-graphql/customQueries';
import { API } from 'aws-amplify';
import { useConceptStore } from '../zustand/store';

const usePullConcepts = () => {
  const { setConcepts } = useConceptStore();

  const pullConcepts = async () => {
    try {
      const result = await API.graphql({
        query: listConcepts,
        variables: { limit: 400 },
      });
      const concepts = result.data.listConcepts.items;
      setConcepts(concepts);
     

      return concepts;
    } catch (error) {
      console.error('Error pulling concepts:', error);
      return [];
    }
  };

  return pullConcepts;
};

export default usePullConcepts;
