import React, { useEffect, useState, memo, useCallback } from 'react';
import Box from '@mui/joy/Box';
import Table from '@mui/joy/Table';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Option,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Tooltip,
} from '@mui/joy';
import { useStudentStore } from '../../zustand/store';
import { FormControlLabel, TextField } from '@mui/material';
import {
  EditLocation,
  Numbers,
  PollRounded,
  Visibility,
} from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';

// Memoized Checkbox
const MemoizedCheckbox = memo(({ checked, onChange }) => {
  return <Checkbox checked={checked} onChange={onChange} />;
});

const ScopeAndSequenceModal = ({ isOpen, onClose, concepts }) => {
  const { selectedStudent } = useStudentStore();
  const [selectedRows, setSelectedRows] = useState(new Set());
  const [sortedConcepts, setSortedConcepts] = useState([]);

  useEffect(() => {
    const sorted = [...concepts].sort((a, b) => {
      return (
        b.category.localeCompare(a.category) ||
        a.subcategory.localeCompare(b.subcategory) ||
        a.concept.localeCompare(b.concept)
      );
    });
    setSortedConcepts(sorted);
  }, [concepts]);

  // Using useCallback for handleRowSelect
  const handleRowSelect = useCallback((row) => {
    setSelectedRows((prevSelectedRows) => {
      const newSelectedRows = new Set(prevSelectedRows);
      if (newSelectedRows.has(row.id)) {
        newSelectedRows.delete(row.id);
      } else {
        newSelectedRows.add(row.id);
      }
      return newSelectedRows;
    });
  }, []);

  const handleSelectAll = () => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.size === concepts.length) {
        return new Set();
      } else {
        return new Set(concepts.map((row) => row.id));
      }
    });
  };

  return (
    <Modal
      aria-labelledby='modal-title'
      aria-describedby='modal-desc'
      open={isOpen}
      onClose={onClose}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Sheet
        variant='outlined'
        sx={{
          width: '100%',
          height: '700px',
          maxWidth: '80%',
          borderRadius: 'md',
          p: 5,
          boxShadow: 'lg',
          position: 'relative',
          overflow: 'auto',
        }}
      >
        <ModalClose variant='outlined' />
        <Box>
          <Typography
            variant='h1'
            sx={{ fontWeight: 'bold', textAlign: 'center', marginBottom: 2 }}
          >
            {selectedStudent.firstName +
              ' ' +
              selectedStudent.lastName +
              "'s Scope, Status, and Sequence"}
          </Typography>

          <Table
            aria-label='striped table'
            stripe={'odd'}
            borderAxis={'bothBetween'}
            stickyHeader
            stickyFooter
          >
            <thead>
              <tr>
                <th>Concept</th>

                <th>Subcategory</th>
                <th>Category</th>
                <th style={{ width: '70px', alignContent: 'center' }}>Level</th>

                <th style={{ width: '80px', alignContent: 'center' }}>Scope</th>
                <th>Status</th>

                <th style={{ width: '80px', alignContent: 'center' }}>
                  Sequence
                </th>
                <th style={{ width: '80px', alignContent: 'center' }}>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedConcepts.map((row, index) => (
                <tr key={index}>
                  <td>{row.concept}</td>
                  <td>{row.subcategory}</td>
                  <td>{row.category}</td>
                  <td>{row.level}</td>
                  <td>
                    <MemoizedCheckbox
                      checked={selectedRows.has(row.id)}
                      onChange={() => handleRowSelect(row)}
                      // checked={true}
                    />
                  </td>
                  <td>
                    <form>
                      <Stack spacing={2} alignItems='flex-start'>
                        <Select
                          placeholder='Unknown'
                          required
                          sx={{ minWidth: 70 }}
                          defaultValue='Unknown' // Setting the default value to 'Unknown'
                        >
                          <Option value='Mastered'>Mastered</Option>
                          <Option value='Proficient'>Proficient</Option>
                          <Option value='Progressing'>Progressing</Option>
                          <Option value='Struggling'>Struggling</Option>
                          <Option value='Unknown'>Unknown</Option>
                        </Select>
                      </Stack>
                    </form>
                  </td>
                  <td>
                    <Tooltip
                      title='Sequence is the estimated order of concept introduction'
                      arrow
                      placement='left'
                    >
                      <Input startDecorator={<Numbers />} size='small' />
                    </Tooltip>
                  </td>
                  <td>
                    <Stack direction='row'>
                      <IconButton
                        size='sm'
                        variant='plain'
                        // onClick={(event) => openEditListModal(event, list)}
                      >
                        <EditIcon color='primary' />
                      </IconButton>
                      <IconButton
                        size='sm'
                        variant='plain'
                        // onClick={(event) => openDeleteListModal(event, list)}
                      >
                        <CancelIcon color='danger' />
                      </IconButton>
                    </Stack>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Box>
      </Sheet>
    </Modal>
  );
};

export default ScopeAndSequenceModal;
